import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpEventType, HttpResponse, HttpHeaderResponse, } from '@angular/common/http';
import { EMPTY, Observable, catchError, finalize, mergeMap, of, switchMap, take, tap, throwError, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../services/token/token.service';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert/alert.service';
import { v4 as uuidv4 } from 'uuid'; // Necesitas instalar uuid: npm install uuid
import { ProgressService } from '../services/progress/progress.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private alert: AlertService,
    private progressService: ProgressService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {

    // const id = uuidv4();
    // request = request.clone({ reportProgress: true });
    this.progressService.start();


    
    if (request.url.includes(environment.base_url) || request.url.includes(environment.ws_url)) {
      return this.getToken().pipe(
        switchMap(token => {

          if (!token) {
            return throwError("token.not.found");
          }

          const authRequest = request.clone({
              setHeaders: {
                  Authorization: token ? `Bearer ${token}` : '',
                  "Content-type": "application/json"
              },
          });
          return next.handle(authRequest).pipe(
            tap(event => {
              this.setProgress(event);
            }),
            finalize(() => {
              this.progressService.complete();
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401) {
                this.router.navigate(['/auth/sign-in']);
                environment.storage.clear();
                this.alert.showInfo("La sesión ha expirado.");
              }
              return throwError(error);
            })
          );
        })
      );
    }

    if (request.url.includes(environment.gapis_url.calendar)) {
      const token = this.tokenService.getAccessToken();
      const authRequest = request.clone({
        setHeaders: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });
      return next.handle(authRequest).pipe(
        tap(event => {
          this.setProgress(event);
        }),
        finalize(() => {
          this.progressService.complete();
        })
      );
    }

    if (request.url.includes(environment.gapis_url.drive) || request.url.includes(environment.gapis_url.drive_upload)) {
      return this.tokenService.getDriveToken().pipe(
        mergeMap(token => {
          const authRequest = request.clone({
            setHeaders: {
              Authorization: token ? `Bearer ${token}` : '',
            },
          });
          return next.handle(authRequest).pipe(
            tap(event => {
              this.setProgress(event);
            }),
            finalize(() => {
              this.progressService.complete();
            })
          );
        })
      )
    
    }    

    // 
    return next.handle(request).pipe(
      tap(event => {
        this.setProgress(event);
      }),
      finalize(() => {
        this.progressService.complete();
      })
    );
  }


  getToken(): Observable<string> {
    return of(this.tokenService.getIdToken());
  }

  setProgress(event: HttpEvent<any>) {
    if (event.type === HttpEventType.UploadProgress) {
      const progress = Math.round((100 * event.loaded) / event.total);
      this.progressService.setProgress(progress);
    }
  }


}
