import { AfterViewChecked, AfterViewInit, Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute, Navigation, NavigationEnd, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModuleStateService } from './shared/services/module-state/module-state.service';
import { UserService } from './shared/services/user/user.service';
import { CLIENT_RENEG_LIMIT } from 'tls';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { OAuthService } from 'angular-oauth2-oidc';
import { TimeTrackingService } from './main/time-tracking/services/time-tracking/time-tracking.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {
  constructor(private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private module: ModuleStateService,
    private user: UserService,
    private oauth: OAuthService,
    private service: TimeTrackingService
  ) {
    Settings.defaultLocale = "es-ES";
    
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      ( name: string, namespace: string ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl( `assets/img/icons/material-design-icons/two-tone/${name}.svg` );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl( `assets/img/icons/logos/${name}.svg` );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl( `assets/img/icons/flags/${name}.svg` );
        }
      }
    );


    this.configService.updateConfig({
      id: VexConfigName.apollo,
      name: "Crowe SGI",
      sidenav: {
        title: 'Crowe SGI',
        imageUrl: 'assets/img/common/crowe-logo.png',
        showCollapsePin: true,
        user: { visible: true },
        search: { visible: true },
        state: 'expanded',
      },
      toolbar: { fixed: true, user: { visible: true } },
      footer: { visible: true },
      style: {
        colorScheme: ColorSchemeName.light,
        colors: { primary: colorVariables.amber },
        borderRadius: { value: 5, unit: "px" },
        button: { borderRadius: { value: 5, unit: "px" } }
      },
      boxed: true,
      navbar: { position: "below-toolbar" }
    });

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */

    //  this.setRoutes();
    this.module.onRoutechange();
    this.user.user$.subscribe(data => {
      if (!data) return;

      const menu: any = data.menu;
      this.navigationService.setItems(menu);
    })

    this.user.user$.subscribe(user => {
      if (!user) return;

      // this.user = user;
      
      // this.subscribeToChanges();

      // const table$ = this.service.showTable$.subscribe(value => {
      //   this.showTable = value;
      // })

      // const data$ = this.service.data$.subscribe(data => {
      //   this.exportData = data;
      // });

      // const activity$ = this.service.findActivities(user.id, new Date().toISOString()).subscribe();
      // const incidence$ = this.service.findIncidences(user.id, new Date().toISOString()).subscribe();
      // const spent$ = this.service.findSpents(user.id, new Date().toISOString()).subscribe();
      // const reservation$ = this.service.findReservations(user.id, new Date().toISOString()).subscribe();

      // this.subscriptions.push(table$);
      // this.subscriptions.push(data$);
      // this.subscriptions.push(activity$);
      // this.subscriptions.push(incidence$);
      // this.subscriptions.push(spent$);
      // this.subscriptions.push(reservation$);

    })

    // this.subscriptions.push(data$);
  }
  ngAfterViewChecked(): void {
    if (this.layoutService.isCollapsed()) {
      const tabs = document.querySelectorAll(".mat-horizontal-stepper-header-container");
      tabs.forEach(tab => {
        if (tab.classList.contains("stepper-header-expanded")) return;
        tab.classList.add("stepper-header-expanded");
      })
    }
  }

  

  // private setRoutes() {
  //   this.navigationService.items = [
  //     {
  //       type: 'subheading',
  //       label: 'General',
  //       children: [
  //         { type: 'link', label: 'Dashboard', route: '/', icon: 'mat:home', routerLinkActiveOptions: { exact: true } },
  //         { type: 'link', label: 'Configuración', route: '/configuracion', icon: 'mat:settings', },
  //       ]
  //     },
  //     {
  //       type: 'subheading',
  //       label: 'Personal',
  //       children: [
  //         { type: 'link', label: 'Control de tiempos', route: '/personal/control-tiempos', icon: 'mat:schedule', },
  //         { type: 'link', label: 'Capacitación', route: '/personal/capacitacion', icon: 'mat:school', },
  //         {
  //           type: 'dropdown', label: 'Reportes', icon: 'mat:library_books',
  //           children: [
  //             { type: 'link', label: 'Mi personal', route: '/personal/reportes/horas-personal', },
  //             { type: 'link', label: 'Mis proyectos', route: '/personal/reportes/horas-proyectos', },
  //             { type: 'link', label: 'Matriz Horas', route: '/personal/reportes/matriz-horas', },
  //             { type: 'link', label: 'Reportes BI', route: '/personal/reportes/reportes-bi', }
  //           ]
  //         },
  //         { type: 'link', label: 'Reservaciones', route: '/personal/reservaciones', icon: 'mat:domain', }
  //       ]
  //     },
  //     {
  //       type: 'subheading',
  //       label: 'Capital Humano',
  //       children: [
  //         { type: 'link', label: 'Personal', route: '/capital-humano/personal', icon: 'mat:people', },
  //         { type: 'link', label: 'Vacantes', route: '/capital-humano/vacantes', icon: 'mat:account_box', },
  //         { type: 'link', label: 'Gastos', route: '/capital-humano/gastos', icon: 'mat:monetization_on', },
  //         {
  //           type: 'dropdown', label: 'Marketing', icon: 'mat:shop_two',
  //           children: [
  //             { type: 'link', label: 'Links', route: '/capital-humano/mkt/link', },
  //           ]
  //         },
  //         {
  //           type: 'dropdown', label: 'Enlace Crowe', icon: 'mat:language',
  //           children: [
  //             { type: 'link', label: 'Enlaces', route: '/capital-humano/enlace/enlaces', },
  //             { type: 'link', label: 'Categorias', route: '/capital-humano/enlace/categorias', },
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       type: 'subheading',
  //       label: 'CRM',
  //       children: [
  //         { type: 'link', label: 'Clientes', route: '/crm/clientes', icon: 'mat:contacts', },
  //         { type: 'link', label: 'Proyectos', route: '/crm/proyectos', icon: 'mat:work', },
  //         { type: 'link', label: 'Planeación Estratégica', route: '/crm/planeacion-estrategica', icon: 'mat:today', },
  //         { type: 'link', label: 'Prospectos', route: '/crm/prospectos', icon: 'mat:group_add', },
  //         { type: 'link', label: 'Oportunidades', route: '/crm/oportunidades', icon: 'mat:add_to_queue', },
  //       ]
  //     }
  //   ];
  // }

}
