import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { Activity, ActivityItem, CalendarItem, Incidence, IncidenceItem, Item, ListCatalogs, Reservation, ReservationItem, Spent, SpentItem } from '../../interfaces/time-tracking.interface';
import { environment } from 'src/environments/environment';
import { HttpResponse } from 'src/app/shared/interfaces/http-response.interface';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { Edifice, Place, Room } from '../../interfaces/reservation.interface';
import { SpentElement } from 'src/app/main/bills/interfaces/bills.interface';
import { formatDate } from 'src/app/shared/util/helpers';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {

  private allSubject: BehaviorSubject<Item[]> = new BehaviorSubject([]);
  all$: Observable<Item[]> = this.allSubject.asObservable();

  private activitiesSubject: BehaviorSubject<ActivityItem[]> = new BehaviorSubject([]);
  activities$: Observable<ActivityItem[]> = this.activitiesSubject.asObservable();

  private incidencesSubject: BehaviorSubject<IncidenceItem[]> = new BehaviorSubject([]);
  incidences$: Observable<IncidenceItem[]> = this.incidencesSubject.asObservable();

  private spentsSubject: BehaviorSubject<SpentItem[]> = new BehaviorSubject([]);
  spents$: Observable<SpentItem[]> = this.spentsSubject.asObservable();

  private reservationsSubject: BehaviorSubject<ReservationItem[]> = new BehaviorSubject([]);
  reservations$: Observable<ReservationItem[]> = this.reservationsSubject.asObservable();

  private showTableSubject: BehaviorSubject<0 | 1 | 2 | 3 | 10> = new BehaviorSubject(10);
  showTable$: Observable<0 | 1 | 2 | 3 | 10> = this.showTableSubject.asObservable();

  private changeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  change$: Observable<boolean> = this.changeSubject.asObservable();

  private activeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  active$: Observable<boolean> = this.activeSubject.asObservable();

  private dataSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  data$: Observable<any> = this.dataSubject.asObservable();

  private dateSubject: BehaviorSubject<string> = new BehaviorSubject(new Date().toISOString());
  date$: Observable<string> = this.dateSubject.asObservable();

  private disableIncidenceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  disableIncidence$: Observable<boolean> = this.disableIncidenceSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  setDate(value: string) {
    this.dateSubject.next(value);
  }

  findCatalogs(personalId: number): Observable<HttpResponse<ListCatalogs>> {
    return this.http.get<HttpResponse<ListCatalogs>>(`${environment.base_url}/projects/activity/all-catalogs/${personalId}`);
  }

  
  saveActivity(data: Activity): Observable<HttpResponse<Activity>> {
    return this.http.post<HttpResponse<Activity>>(`${environment.base_url}/projects/activity/save`, JSON.stringify(data)).pipe(
      switchMap(response => {
        this.changeSubject.next(true);
        return of(response);
      })
    );
  }

  findActivity(id: number): Observable<HttpResponse<Activity>> {
    return this.http.get<HttpResponse<Activity>>(`${environment.base_url}/projects/activity/${id}`);
  }

  removeActivity(id: number): Observable<HttpResponse<Activity>> {
    return this.http.delete<HttpResponse<Activity>>(`${environment.base_url}/projects/activity/${id}`).pipe(
      switchMap(response => {
        this.changeSubject.next(true);
        this.deleteActivity(id);
        return of(response);
      })
    );
  }

  deleteActivity(id: number) {
    this.activitiesSubject.next(this.activitiesSubject.value.filter(item => item.id !== id));
  }

  startActivity(id: number): Observable<HttpResponse<ActivityItem>> {
    return this.http.post<HttpResponse<Activity>>(`${environment.base_url}/projects/activity/${id}/init-counter`, {}).pipe(
      switchMap(response => {
        const activity = this.activitiesSubject.value.find(a => a.id === response.data.id)
        activity.counterActive = true;
        activity.initDate = response.data.date;

        const item = this.allSubject.value.find(a => a.type === 0 && a.id === response.data.id);
        item.counterActive = true;
        item.initDate = response.data.date;


        this.setActivity(activity);
        return of({
          ...response,
          data: activity
        });
      })
    );
  }

  stopActivity(id: number): Observable<HttpResponse<ActivityItem>> {
    return this.http.post<HttpResponse<Activity>>(`${environment.base_url}/projects/activity/${id}/stop-counter`, {}).pipe(
      switchMap(response => {
        const activity = this.activitiesSubject?.value?.find(a => a.id === response.data.id)
        if (activity) {
          activity.counterActive = false;
          activity.time = response.data.time
          this.setActivity(activity)
        }

        const item = this.allSubject.value.find(a => a.type === 0 && a.id === response.data.id);
        if (item) {
          item.counterActive = false;
          item.value = response.data.time;
        }

        return of({
          ...response,
          data: activity
        });
      })
    );
  }

  saveIncidence(data: Incidence): Observable<HttpResponse<Incidence>> {
    return this.http.post<HttpResponse<Incidence>>(`${environment.base_url}/projects/incidence/save`, JSON.stringify(data)).pipe(
      switchMap(response => {
        this.changeSubject.next(true);
        return of(response);
      })
    );
  }

  findIncidence(id: number): Observable<HttpResponse<Incidence>> {
    return this.http.get<HttpResponse<Incidence>>(`${environment.base_url}/projects/incidence/${id}`);
  }

  removeIncidence(id: number): Observable<HttpResponse<Incidence>> {
    return this.http.delete<HttpResponse<Incidence>>(`${environment.base_url}/projects/incidence/${id}`).pipe(
      switchMap(response => {
        this.changeSubject.next(true);
        this.deleteIncidence(id);
        return of(response);
      })
    );
  }

  deleteIncidence(id: number) {
    this.incidencesSubject.next(this.incidencesSubject.value.filter(item => item.id !== id));
    this.setDisableIncidence(this.incidencesSubject.value.length > 0);
  }

  saveSpent(data: Spent): Observable<HttpResponse<Spent>> {
    return this.http.post<HttpResponse<Spent>>(`${environment.base_url}/projects/spent/save`, JSON.stringify(data)).pipe(
      switchMap(response => {
        this.changeSubject.next(true);
        return of(response);
      })
    );
  }

  findSpent(id: number): Observable<HttpResponse<Spent>> {
    return this.http.get<HttpResponse<Spent>>(`${environment.base_url}/projects/spent/${id}`);
  }

  removeSpent(id: number): Observable<HttpResponse<Spent>> {
    return this.http.delete<HttpResponse<Spent>>(`${environment.base_url}/projects/spent/${id}`).pipe(
      switchMap(response => {
        this.changeSubject.next(true);
        this.deleteSpent(id);
        return of(response);
      })
    );
  }

  deleteSpent(id: number) {
    this.spentsSubject.next(this.spentsSubject.value.filter(item => item.id !== id));
  }

  buildCalendar(personalId: number, date: string): Observable<HttpResponse<CalendarItem[]>> {
    date = formatDate(date, "yyyy-MM-dd", false);
    return this.http.get<HttpResponse<CalendarItem[]>>(`${environment.base_url}/projects/calendar/${personalId}/${date}`);
  }



  findAll(personalId: number, date: string): Observable<HttpResponse<Item[]>> {
    date = formatDate(date, "yyyy-MM-dd", false);
    return this.http.get<HttpResponse<Item[]>>(`${environment.base_url}/projects/all/${personalId}/${date}`).pipe(
      switchMap( response => {
        response.data.map(item => {
          if (item.type === 0) {
            item.value = parseInt(item.value as string);
          }
        })
        this.allSubject.next(response.data);
        if (this.showTableSubject.value === 10) {
          this.dataSubject.next(this.allSubject.value)
        }
        return of(response);
      })
    );
  }

  findActivities(personalId: number, date: string): Observable<HttpResponse<ActivityItem[]>> {
    date = formatDate(date, "yyyy-MM-dd", false);
    return this.http.get<HttpResponse<ActivityItem[]>>(`${environment.base_url}/projects/activity/${personalId}/${date}`).pipe(
      switchMap( response => {
        this.activitiesSubject.next(response.data);
        if ((this.showTableSubject.value === 0)) {
          this.dataSubject.next(this.activitiesSubject.value)
        }
        return of(response);
      })
    );
  }

  findIncidences(personalId: number, date: string): Observable<HttpResponse<IncidenceItem[]>> {
    date = formatDate(date, "yyyy-MM-dd", false);
    return this.http.get<HttpResponse<IncidenceItem[]>>(`${environment.base_url}/projects/incidence/${personalId}/${date}`).pipe(
      switchMap( response => {
        this.incidencesSubject.next(response.data);
        this.setDisableIncidence(response.data.length > 0);
        if ((this.showTableSubject.value === 1)) {
          this.dataSubject.next(this.incidencesSubject.value);
        }
        return of(response);
      })
    );
  }

  findSpents(personalId: number, date: string): Observable<HttpResponse<SpentItem[]>> {
    date = formatDate(date, "yyyy-MM-dd", false);
    return this.http.get<HttpResponse<SpentItem[]>>(`${environment.base_url}/projects/spent/${personalId}/${date}`).pipe(
      switchMap( response => {
        this.spentsSubject.next(response.data);
        if ((this.showTableSubject.value === 2)) {
          this.dataSubject.next(this.spentsSubject.value);
        }
        return of(response);
      })
    );
  }

  setActive(element: any) {
    this.activeSubject.next(element);
  }
  
  setActivity(data: ActivityItem): void {
    const index = this.activitiesSubject.value.findIndex(i => i.id === data.id);

    if (index !== -1) {
      this.activitiesSubject.value[index] = data;
    } else {
      this.activitiesSubject.value.push(data)
    }
    
    this.activitiesSubject.next(this.activitiesSubject.value);
  }

  setIncidences(data: IncidenceItem[]): void {
    this.incidencesSubject.next(data);
  }

  setIncidence(data: IncidenceItem): void {
    const index = this.incidencesSubject.value.findIndex(i => i.id === data.id);

    if (index !== -1) {
      this.incidencesSubject.value[index] = data;
    } else {
      this.incidencesSubject.value.push(data)
    }
    
    this.incidencesSubject.next(this.incidencesSubject.value);
    this.setDisableIncidence(true);
  }

  setSpents(data: SpentItem[]): void {
    this.spentsSubject.next(data);
  }

  setSpent(data: SpentItem): void {
    const index = this.spentsSubject.value.findIndex(i => i.id === data.id);

    if (index !== -1) {
      this.spentsSubject.value[index] = data;
    } else {
      this.spentsSubject.value.push(data)
    }
    
    this.spentsSubject.next(this.spentsSubject.value);
  }
  
  setReservations(data: ReservationItem[]): void {
    this.reservationsSubject.next(data);
  }

  setReservation(data: ReservationItem): void {
    const index = this.reservationsSubject.value.findIndex(i => i.id === data.id);

    if (index !== -1) {
      this.reservationsSubject.value[index] = data;
    } else {
      this.reservationsSubject.value.push(data)
    }
    
    this.reservationsSubject.next(this.reservationsSubject.value);
  }

  deleteReservation(id: number) {
    this.reservationsSubject.next(this.reservationsSubject.value.filter(item => item.id !== id));
  }

  findReservations(personalId: number, date: string): Observable<HttpResponse<ReservationItem[]>> {
    date = formatDate(date, "yyyy-MM-dd", false);
    return this.http.get<HttpResponse<ReservationItem[]>>(`${environment.base_url}/projects/reservation/${personalId}/${date}`).pipe(
      switchMap( response => {
        this.reservationsSubject.next(response.data);
        if ((this.showTableSubject.value === 3)) {
          this.dataSubject.next(this.reservationsSubject.value)
        }
        return of(response);
      })
    );
  }
  
  findReservation(id: number): Observable<HttpResponse<Reservation>> {
    return this.http.get<HttpResponse<Reservation>>(`${environment.base_url}/projects/reservation/${id}`);
  }

  findEdifices(officeId: number): Observable<HttpResponse<Edifice[]>> {
    return this.http.get<HttpResponse<Edifice[]>>(`${environment.base_url}/projects/reservation/find/edifices/${officeId}`);
  }

  findRooms(edificeId: number): Observable<HttpResponse<Room[]>> {
    return this.http.get<HttpResponse<Room[]>>(`${environment.base_url}/projects/reservation/find/rooms/${edificeId}`);
  }

  findPlaces(roomId: number, personalId: number, initDate: string, endDate: string): Observable<HttpResponse<Place[]>> {
    const data = { roomId, personalId, initDate, endDate }
    return this.http.post<HttpResponse<Place[]>>(`${environment.base_url}/projects/reservation/find/places`, JSON.stringify(data));
  }

  saveReservation(reservation: Reservation): Observable<HttpResponse<Reservation>> {
    return this.http.post<HttpResponse<Reservation>>(`${environment.base_url}/projects/reservation/save`, JSON.stringify(reservation));
  }

  removeReservation(id: number): Observable<HttpResponse<Reservation>> {
    this.changeSubject.next(true);
    return this.http.delete<HttpResponse<Reservation>>(`${environment.base_url}/projects/reservation/${id}`);
  }


  showTable(option: 0 | 1 | 2 | 3 | 10): void {
    this.showTableSubject.next(option);
    if (option === 0) {
      this.dataSubject.next(this.activitiesSubject.value)
    } else if (option === 1) {
      this.dataSubject.next(this.incidencesSubject.value)
    } else if (option === 2) {
      this.dataSubject.next(this.spentsSubject.value)
    } else if (option === 3) {
      this.dataSubject.next(this.reservationsSubject.value)
    }
  }

  findBills(personalId: number, status: number): Observable<HttpResponse<SpentElement[]>> {
    return this.http.get<HttpResponse<SpentElement[]>>(`${environment.base_url}/projects/bills/${personalId}/${status}`);
  }

  saveBill(spent: SpentElement): Observable<HttpResponse<SpentElement>> {
    return this.http.post<HttpResponse<SpentElement>>(`${environment.base_url}/projects/spent/status/save`, JSON.stringify(spent));
  }

  searchSpent(id: number): SpentItem {
    return this.spentsSubject.value.find(item => item.id === id);
  }

  setItem(value: ActivityItem | IncidenceItem | SpentItem | ReservationItem, type: number): void {

    let data: Item = null;

    if (type === 0) {
      const activity: ActivityItem = (value as ActivityItem);
      data = {
        id: activity.id,
        type,
        activity: `Actividad: ${activity.activity}`,
        project: `${activity.company} (${activity.project})`,
        value: activity.time,
        initDate: activity.initDate,
        endDate: activity.endDate,
        notes: activity.notes,
        counterActive: activity.counterActive,
      }
    } else if (type === 1) {
      const incidence: IncidenceItem = (value as IncidenceItem);
      data = {
        id: incidence.id,
        type,
        activity: `Incidencia: ${incidence.activity}`,
        project: `${incidence.company} (${incidence.project})`,
        value: "N/A",
        initDate: incidence.date,
        endDate: incidence.date,
        notes: incidence.notes,
        counterActive: false,
      }
    } else if (type === 2) {
      const spent: SpentItem = (value as SpentItem);
      data = {
        id: spent.id,
        type,
        activity: `Gasto: ${spent.type}`,
        project: `${spent.company} (${spent.project})`,
        value: spent.amount,
        initDate: spent.date,
        endDate: spent.date,
        notes: spent.notes,
        counterActive: false,
      }
    } else if (type === 3) {
      const reservation: ReservationItem = (value as ReservationItem);
      data = {
        id: reservation.id,
        type,
        activity: `Reservación: ${reservation.place} (${reservation.type === 2 ? "Estacionamiento" : "Lugar"})`,
        project: 'GOS830601GE2 - GOSSLER S.C. (NAL000001CG00000001)',
        value: "N/A",
        initDate: reservation.initDate,
        endDate: reservation.endDate,
        notes: reservation.notes,
        counterActive: false,
      }
    }
    
    const index = this.allSubject.value.findIndex(i => i.id === data.id);

    if (index !== -1) {
      this.allSubject.value[index] = data;
    } else {
      this.allSubject.value.push(data)
    }

    this.allSubject.next(this.allSubject.value);
  }

  deleteItem(id: number, type: number): void {
    const item = this.allSubject.value.find(item => item.id === id && item.type === type);
    // this.reservationsSubject.next(this.reservationsSubject.value.filter(item => item.id !== id));
    this.allSubject.next(this.allSubject.value.filter(value => item !== value));
  }

  setDisableIncidence(value: boolean) {
    this.disableIncidenceSubject.next(value);
  }

}
