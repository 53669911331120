import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '../../services/validation/validation.service';
import { Subscription, map } from 'rxjs';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class InputTextComponent implements OnInit, OnDestroy, OnChanges {
  
  @Input() dropSpecialCharacters: boolean = true;
  @Input() field: string;
  @Input() label: string;
  @Input() placeholder: string = "Ingrese el campo";
  @Input() form: FormGroup;
  @Input() mask: string;
  @Input() disabled: boolean = false;
  @Input() upper: boolean = false;
  @Input() type: string = "text";
  @Input() showDisabled: boolean = false;
  @Input() showError: boolean = true;

  loading: boolean = false;
  status$: Subscription;
  upper$: Subscription;
  isEmail: boolean = false;

  constructor(private validator: ValidationService) { }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.isEmail) {
      if (changes?.form?.currentValue) {
        this.status$ = changes.form.currentValue.statusChanges.subscribe(status => {
          this.changeLoadingStatus(status);
        })
      }
    }
  }
  
  ngOnDestroy(): void {
    if (this.status$) {
      this.status$.unsubscribe();
      this.status$.remove(this.status$);
    }

    if (this.upper$) {
      this.upper$.unsubscribe();
      this.upper$.remove(this.upper$);
    }
  }

  ngOnInit(): void { 
    this.isEmail = this.field === 'email';

    if (this.isEmail) {
      this.status$ = this._control.statusChanges.subscribe(status => {
        this.changeLoadingStatus(status);
      })
    }

    if (this.upper) {
      this.upper$ = this._control.valueChanges.pipe(
        map((value: string) => value.toUpperCase())
      ).subscribe((value: string) => {
        this._control.setValue(value, { emitEvent: false });
      });
    }
  }

  isValidField(): boolean | null {
    return this.validator.isValidField(this._control);
  }

  getFieldError(): string | null {
    return this.validator.getFieldError(this._control, this.field);
  }

  get _control(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  get required(): boolean {
    return this.validator.isRequired(this._control);
  }

  get emailValid(): boolean {
    return this.isEmail && this.isValidField && this._control.touched && !this.loading
  }

  changeLoadingStatus(status: string) {
    this.loading = status === "PENDING";
    if (status === "INVALID") {
      this._control.markAllAsTouched();
    }
  }

}
