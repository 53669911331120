// auth-google.service.ts
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { InactivityService } from '../inactivity.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthGoogleService {
  
  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private storageService: StorageService,
    private inactivityService: InactivityService,
    private dialogRef: MatDialog,
    private ngZone: NgZone
  ) { 
    if (!environment.offline) {
      this.initLogin();
    }

    // Suscribirse al evento de inactividad
    this.inactivityService.inactivityDetected.subscribe(() => {
      this.signOut(); // Llama a signOut cuando se detecte inactividad
    });
  }

  initLogin() {
    const config: AuthConfig = {
      issuer: "https://accounts.google.com",
      strictDiscoveryDocumentValidation: false,
      redirectUri: window.location.origin,
      clientId: environment.client_id,
      scope: "openid profile email https://www.googleapis.com/auth/calendar",
      silentRefreshShowIFrame: true,
    };
    this.oauthService.configure(config);
    this.oauthService.setupAutomaticSilentRefresh({
      login_hint: this.email
    });
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      // Inicia el monitoreo de inactividad después de un inicio de sesión exitoso
      this.inactivityService.initInactivityListener();
    }).catch(() => {
      this.oauthService.refreshToken();
    });
  }

  signIn() {
    this.oauthService.initLoginFlow();
  }
  
  getProfile() {
    return this.oauthService.getIdentityClaims();
  }

  get email() {
    const item = JSON.parse(environment.storage.getItem("id_token_claims_obj"));
    if (!item) {
      return null;
    }
    return item.email;
  }

  signOut() {
    this.oauthService.logOut();
    this.dialogRef.closeAll();
    this.ngZone.run(() => {
      this.router.navigate(['/auth/sign-in']); // Asegura la redirección al inicio de sesión dentro de Angular zone
    });
  }
}
