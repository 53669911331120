import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormDocumentComponent } from '../form-document/form-document.component';
import { PersonalService } from '../../services/personal/personal.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { FamilyFormDialog } from '../../interfaces/family-dialog.interface';
import { Catalog } from 'src/app/shared/interfaces/catalogs.interface';
import { PersonalFamily } from '../../interfaces/personal.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vex-family-form',
  templateUrl: './family-form.component.html',
  styleUrls: ['./family-form.component.scss']
})
export class FamilyFormComponent implements OnInit, OnDestroy {

  public form!: FormGroup;
  public loading = false;
  public types: Catalog[] = [];
  public personalId = 0;
  public isEditing = false;
  private subscriptions$: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<FormDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FamilyFormDialog,
    private fb: FormBuilder,
    private alert: AlertService,
    private service: PersonalService,
    private socket: RxStompService
  ) { }
  
  ngOnDestroy(): void {
    this.subscriptions$.map(s => {
      s.unsubscribe();
      s.remove(s);
    })
  }

  ngOnInit(): void {

    this.types = this.data.types;
    this.personalId = this.data.personalId;
    this.isEditing = this.data.isEditing;
    const item = this.data?.item;

    this.form = this.fb.group({
      id: [item?.id ? item.id : null],
      name: [item?.name ? item.name : null, Validators.required],
      age: [item?.age ? item.age : null, Validators.required],
      type: [this.findItemCatalog(item?.typeId), Validators.required],
      personalId: [this.personalId],
      phone: [item?.phone]
    });

  }

  onSubmit() {
    if (this.form.invalid) return;

    const item: PersonalFamily = {
      id: this.form.value.id,
      name: this.form.value.name,
      age: this.form.value.age,
      typeId: this.form.value.type.id,
      typeName: this.form.value.type.value,
      personalId: this.form.value.personalId,
      phone: this.form.value.phone
    };

    this.data.item = item;
    
    if (!this.isEditing) {
      this.dialogRef.close(this.data);
      return;
    }

    // save data in BD
    this.save(item);
    
  }

  close() {
    this.dialogRef.close();
  }

  findItemCatalog(id: number) {
    if (!id) return null;
    return this.types.find(t => t.id === id);
  }

  save(item: PersonalFamily) {
    this.loading = true;
    const save$ = this.service.saveFamily(item).subscribe({
      next: response => {
        this.data.item = response.data;
        this.socket.send(`/send/save_family/${this.personalId}`, {});
        this.dialogRef.close(this.data);
        this.loading = false;
      },
      error: response => {
        
        this.alert.showError(response.error.message);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  
  
    this.subscriptions$.push(save$);
  }

}
