import { AfterViewInit, Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses, MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TimeTrackingService } from '../../services/time-tracking/time-tracking.service';
import { CalendarItem } from '../../interfaces/time-tracking.interface';
import { UserService } from 'src/app/shared/services/user/user.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms
  ],
})
export class CalendarComponent implements OnInit, AfterViewInit {

  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  @ViewChild(MatDatepicker) picker: MatDatepicker<Date>;

  days: CalendarItem[] = [];
  @Input() selected: Date | null = new Date();
  @Input() toggled: boolean = false;
  user: User;

  constructor(
    private service: TimeTrackingService,
    private userService: UserService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.userService.user$.subscribe(user => {
      if (!user) return;
      this.user = user;
      
      this.service.change$.subscribe(value => {
        this.buildCalendar();
      })

      this.service.date$.subscribe(date => {
        this.selected = new Date(date);
      })
    })
  }

  ngAfterViewInit() {
    const monthPrevBtn = document.querySelectorAll('.mat-calendar-previous-button');
    const monthNextBtn = document.querySelectorAll('.mat-calendar-next-button');

    if (monthPrevBtn) {
      Array.from(monthPrevBtn).forEach((button) => {
        this.renderer.listen(button, 'click', (event) => {
          this.buildCalendar(this.calendar.activeDate);
        });
      });
    }

    if (monthNextBtn) {
      Array.from(monthNextBtn).forEach((button) => {
        this.renderer.listen(button, 'click', (event) => {
          this.buildCalendar(this.calendar.activeDate);
        });
      });
    }

    this.buildCalendar();
  }

  dateClass() {
    return (cellDate: Date, view): MatCalendarCellCssClasses => {
      if (view === 'month') {
        
        if (cellDate.getDay() === 0 || cellDate.getDay() === 6) return "disabled";

        const date = this.days.find(d => cellDate >= new Date(d.initDate) && cellDate <= new Date(d.endDate));

        return date ? date.status : ""
      }
  
      return '';
    };
  }

  change(event: Date): void {
    this.service.setDate(!this.selected ? new Date().toISOString() : this.selected.toISOString());
    this.service.findAll(this.user.id, event.toISOString()).subscribe();
    this.service.findActivities(this.user.id, event.toISOString()).subscribe();
    this.service.findIncidences(this.user.id, event.toISOString()).subscribe();
    this.service.findSpents(this.user.id, event.toISOString()).subscribe();
    this.service.findReservations(this.user.id, event.toISOString()).subscribe();
    this.service.buildCalendar(this.user.id, this.selected.toISOString()).subscribe(response => {
      this.days = response.data;
      this.calendar?.updateTodaysDate();
    })
  }

  buildCalendar(event: Date = null): void {
    this.selected = event ? event : this.selected;
    this.days = [];
    this.calendar?.updateTodaysDate();
    this.service.buildCalendar(this.user.id, this.selected.toISOString()).subscribe(response => {
      this.days = response.data;
      this.calendar?.updateTodaysDate();
    })
  }

  disableBtn(): boolean {
    if (this.selected.getDay() === 0 || this.selected.getDay() === 6) return true;

    const day = this.days.find(d => this.selected >= new Date(d.initDate) && this.selected <= new Date(d.endDate))

    return day ? day.status.includes("incidence") : false;
  }

  toggle() {
    const calendar = document.getElementById("calendar");
    calendar.classList.toggle("popup-container--active")
  }

}
