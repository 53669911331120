import { Pipe, PipeTransform } from '@angular/core';
import { Observable, Subscription, interval, map } from 'rxjs';

@Pipe({
  name: 'cron'
})
export class CronPipe implements PipeTransform {

  private subscription: Subscription;

  transform(value: string, time: number): Observable<string> {
    // time = time * 100;
    const startTime = new Date(value).getTime();

    return new Observable<string>(observer => {
      // Emitimos el valor inicial inmediatamente después de la suscripción
      observer.next(this.getCurrentFormattedTime(startTime, time));

      this.subscription = interval(1000).subscribe(() => {
        observer.next(this.getCurrentFormattedTime(startTime, time));
      });
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  private getCurrentFormattedTime(startTime: number, time: number): string {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - startTime + (time * 1000);
    const hours = Math.floor(elapsedTime / 3600000);
    const minutes = Math.floor((elapsedTime % 3600000) / 60000);
    return `${this.padZero(hours)}:${this.padZero(minutes)}`;
  }

}
