import { Component, Input, OnInit } from '@angular/core';
import { PersonalService } from '../../services/personal/personal.service';
import { UserService } from 'src/app/shared/services/user/user.service';
// import { Position } from './test';

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements OnInit {

  @Input() id = 0;
  @Input() flag: boolean = false;

  nodes: any = [];

  constructor(
    private service: PersonalService,
    private user: UserService,
  ) { }

  ngOnInit(): void {
    // this.organigrama = ORGANIGRAMA;
    this.service.getTree(this.id).subscribe(response => {
      this.nodes = response.data;
    })
  }

}
