<div *ngIf="!editFlag && enabled">
     <ng-content></ng-content>
</div>
<div class="btns" *ngIf="editFlag && enabled">
    <!-- <button type="button" mat-mini-fab color="primary" (click)="enableForm()" *ngIf="!isEditing; else editing"> -->
    <button type="button" class="btn btn-edit" mat-mini-fab color="primary" [ngClass]="{ 'editing': isEditing }" (click)="enableForm()">
        <mat-icon>edit</mat-icon>
    </button>

    <button type="button" mat-mini-fab [ngClass]="{ 'editing': isEditing }" class="btn btn-save" (click)="onSubmit()">
        <mat-icon>save</mat-icon>
    </button>

    <button type="button" mat-mini-fab color="warn" [ngClass]="{ 'editing': isEditing }" class="btn btn-cancel" (click)="disableForm()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>