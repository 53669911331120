<div class="icon icon-not_dpc" [matTooltip]="dir" *ngIf="!dpc">
    <mat-icon> {{ _assistanceIcon.NOT_DPC }} </mat-icon>
</div>

<div class="icon icon-virtual" matTooltip="Virtual" *ngIf="value === _typeAssistance.VIRTUAL && dpc">
    <mat-icon> {{ _assistanceIcon.VIRTUAL }} </mat-icon>
</div>

<div class="icon icon-in_person" matTooltip="Presencial" *ngIf="value === _typeAssistance.IN_PERSON && dpc">
    <mat-icon> {{ _assistanceIcon.IN_PERSON }} </mat-icon>
</div>

<div class="icon icon-special" matTooltip="Especial" *ngIf="value === _typeAssistance.SPECIAL && dpc">
    <mat-icon> {{ _assistanceIcon.SPECIAL }} </mat-icon>
</div>

<div class="icon icon-registered" matTooltip="Registrado" *ngIf="value === _typeAssistance.REGISTERED && dpc">
    <mat-icon> {{ _assistanceIcon.REGISTERED }} </mat-icon>
</div>

<div class="icon icon-exponent" matTooltip="Expositor" *ngIf="value === _typeAssistance.EXPONENT && dpc">
    <mat-icon> {{ _assistanceIcon.EXPONENT }} </mat-icon>
</div>
