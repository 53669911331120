<div class="bg-app-bar px-6 h-16 border-b left-0 flex items-center table-header relative">

    <div class="bg-foreground rounded-full border px-4 max-w-[500px] flex-auto flex items-center border-gray-300">
        <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon> <!-- tourAnchor="tour.table.1" -->
        <input #inputRef [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
            placeholder="Buscar..." name="search" type="text">
        <mat-icon class="icon-sm text-secondary clear-btn" (click)="clear()" svgIcon="mat:close" matTooltip="Limpiar"
            *ngIf="!searchEmpty"></mat-icon>
    </div>

    <span class="flex-1"></span>

    <div class="options">

        <div class="content">
            <ng-content></ng-content>
        </div>

        <button class="ml-4 flex-none text-contrast-white bg-primary btn-sm" (click)="add()" matTooltip="Nuevo"
            type="button" *ngIf="showAdd && enabled"> <!-- tourAnchor="tour.table.2" -->
            <mat-icon svgIcon="mat:add" [style.width.px]="18"></mat-icon>
        </button>

        <button class="ml-4 flex-none text-contrast-white bg-green btn-sm" (click)="exportExcel()"
            matTooltip="Exportar a Excel" type="button" [disabled]="dataSource.filteredData.length <= 0"> <!-- tourAnchor="tour.table.3" -->
            <mat-icon svgIcon="mat:file_copy" [style.width.px]="18"></mat-icon>
        </button>

        <button [matMenuTriggerFor]="columnFilterMenu" class="ml-4 flex-none bg-gray-100 btn-sm" mat-icon-button
            matTooltip="Filtrar" type="button">
            <mat-icon svgIcon="mat:filter_list"></mat-icon> <!-- tourAnchor="tour.table.4" -->
        </button>
    </div>
</div>


<div class="table-container overflow-x-auto shadow-none" (scroll)="loadItems($event)" [hidden]="(loading | async)" [ngClass]="{'editing': isEditing, 'profile-route': profileRoute}">

    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="checkbox">
            <th *matHeaderCellDef mat-header-cell>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
                </mat-checkbox>
            </th>
            <td *matCellDef="let row" class="w-4" mat-cell>
                <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
                    [checked]="selection.isSelected(row)" color="primary">
                </mat-checkbox>
            </td>
            <td *matFooterCellDef mat-footer-cell>
                <span> </span>
            </td>
        </ng-container>

        <!-- Date column -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

            <ng-container *ngIf="column.type === 'boolean'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell
                    [matTooltip]="row[column.property] ? 'Sí' : 'No'"> <!--  -->
                    <mat-icon [color]="row[column.property] ? 'primary' : ''"> {{ row[column.property] ?
                        "radio_button_checked" : "radio_button_unchecked" }} </mat-icon>
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell
                    [matTooltip]="textTooltip(row[column.property], tdText)"> <!--  -->
                    {{ row[column.property] | dateFormat: "d 'de' MMMM 'de' yyyy" : false }}
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'datetime'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell
                    [matTooltip]="textTooltip(row[column.property], tdText)"> <!--  -->
                    {{ row[column.property] | dateFormat: "d 'de' MMMM 'de' yyyy" }}
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'format_hour'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell
                    [matTooltip]="textTooltip(row[column.property], tdText)"> <!--  -->
                    {{ row[column.property] | dateFormat: "" }}
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'res_type'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header > {{ column.label }}</th>
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell> 
                    <mat-icon [matTooltip]="row[column.property] === 2 ? 'Estacionamiento' : 'Lugar'"> {{ row[column.property] === 2 ? 'directions_car' : 'event_seat' }} </mat-icon> 
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'time'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                    <span *ngIf="!row?.counterActive"> {{ row[column.property] | time }} </span>
                    <span *ngIf="row?.counterActive" class="time-container"> <span>{{ row.initDate | cron: row.time |
                            async }}</span> <mat-icon class="animation">hourglass_empty</mat-icon> </span>
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> {{ calculateTotal(column.property) | time }} </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'currency'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] | currency }}
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> {{ calculateTotal(column.property) | currency }} </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'assistanceType'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>

                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell>
                    <div> <icon-assistance [value]="row[column.property]" [dpc]="row.dpc"></icon-assistance> </div>
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'event_score'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>

                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell>
                    {{ [1, 5].includes(row.assistanceTypeId) ? row[column.property] + "/10" : "N/A"  }}
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'event_survey'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>

                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell
                [matTooltip]="![1, 5].includes(row.assistanceTypeId) ? '' : row[column.property] ? 'Sí' : 'No'">
                    <mat-icon [color]="row[column.property] ? 'primary' : ''" *ngIf="[1, 5].includes(row.assistanceTypeId)"> {{ row[column.property] ? "radio_button_checked" : "radio_button_unchecked" }} </mat-icon>
                    <span *ngIf="![1, 5].includes(row.assistanceTypeId)">N/A</span>
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'round_status'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>

                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell>
                    <div class="round-status {{ row[column.property] }}"> </div>
                </td>

                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'event_invoice'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>

                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell>
                    {{ row.dpc ? row.invoice : "N/A" }}
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'fileType'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>

                <td *matCellDef="let row" mat-cell [ngClass]="'icon-'+row[column.property]">
                    <mat-icon [matTooltip]="row[column.property]"> {{ row[column.property] === "pdf" ? "picture_as_pdf" : "photo_library" }}
                    </mat-icon>
                    <mat-icon matTooltip="El archivo no se ha subido." class="not-uploaded" *ngIf="row?.notUploaded">
                        cloud_off </mat-icon>
                </td>
                <td *matFooterCellDef mat-footer-cell>
                    <span> </span>
                </td>
            </ng-container>

            <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>

                <td *matCellDef="let row" class="table-text" #tdText [ngClass]="column.cssClasses" mat-cell
                    [matTooltip]="textTooltip(row[column.property], tdText)">
                    {{ row[column.property] }}
                </td>
                <td *matFooterCellDef mat-footer-cell [ngClass]="column.cssClasses" class="td-footer">
                    <span *ngIf="column.showTotal"> </span>
                </td>
            </ng-container>

        </ng-container>


        <!-- Action Column -->
        <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ item: row }"
                    [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
                    <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                </button>
            </td>
            <td *matFooterCellDef mat-footer-cell>
                <span> </span>
            </td>
        </ng-container>

        <!-- message column -->
        <ng-container matColumnDef="message">
            <td mat-footer-cell *matFooterCellDef [colSpan]="columns.length">
                <div class="text-center" *ngIf="isEmpty">
                    <img class="img" src="assets/img/illustrations/empty.svg" alt="">
                    <p class="text-title">Aviso</p>
                    <p class="text-p"> No se encontraron datos </p>
                </div>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns;" @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer" (click)="click(row)" mat-row></tr>
        <tr mat-footer-row *matFooterRowDef="visibleColumns" [class]="hiddenFooter"></tr>
        <tr mat-footer-row *matFooterRowDef="['message']" [class]="hidden"></tr>

    </table>
</div>

<div class="load-container" *ngIf="loading | async">
    <app-loading [loading]="loading | async"></app-loading>
</div>

<mat-paginator (page)="savePageSize($event)" [pageSizeOptions]="pageSizeOptions" [pageIndex]="3" [pageSize]="pageSize" [showFirstLastButtons]="true" class="sticky left-0"></mat-paginator>


<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <div class="checkbox-item mat-menu-item" *ngFor="let column of columns; index as i;" (click)="$event.stopPropagation();">
        <mat-checkbox class="w-full" (click)="$event.stopPropagation();" (ngModelChange)="saveColumns(column, $event, i)"
            [(ngModel)]="column.visible" color="primary" [disabled]="disableCheck && column.visible">
            {{ column.label }}
        </mat-checkbox>
    </div>
</mat-menu>

<!--
(ngModelChange)="saveColumns(column, $event, i)"
-->