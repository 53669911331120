<div class="md:p-2 md:px-3 relative">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form">
        <div class="flex justify-between items-center mb-4">
            <app-btn-back (emitter)="close()"></app-btn-back>
        </div>

        <div class="mt-3 mb-7" mat-dialog-title>
            <div class="flex items-center gap-2">
                <div><h1 class="text-lg font-semibold">Registro de actividad - <span class="text-primary"> {{ today | dateFormat : "d 'de' MMMM 'de' yyyy" : false}} </span> </h1></div>
                <div *ngIf="form?.enabled" @fadeInRight>
                    <!-- <mat-icon class="btn-calendar" [matMenuTriggerFor]="calendar" color="primary">calendar_today</mat-icon> -->
                    <app-calendar [selected]="selected" [toggled]="true"></app-calendar>
                </div>
            </div>
            <p class="font-light text-gray-600">Por favor complete los siguientes campos para continuar con el proceso.</p>
        </div>

        <div mat-dialog-content>
            <div class="form-container">
                
                <app-input-select [form]="form" field="project" label="Proyecto" [items]="catalogs.project"></app-input-select>
                <app-input-select-group [form]="form" field="activity" label="Actvidad" [items]="activity"></app-input-select-group>
                <app-input-text [form]="form" field="hours" label="Horas" placeholder="00:00" [mask]="maskHour" [showDisabled]="true"></app-input-text>
                <app-input-text-area [form]="form" field="notes" label="Notas" placeholder="Ingrese las notas" [rows]="4"></app-input-text-area>
                
            </div>
        </div>
        <div mat-dialog-actions align="end" class="px-3 mt-3">
            <app-form-btns [form]="form" [editFlag]="isEditing" (submit)="onSubmit()">
                <button mat-raised-button color="primary"> {{ counterEnabled ? 'Iniciar contador' : 'Aceptar' }} </button>
            </app-form-btns>
        </div>
    </form>
    <div [ngClass]="{'loading-float': loading}">
        <app-loading [loading]="loading"></app-loading>
    </div>
</div>