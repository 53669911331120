import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'cui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  settings: any
  date = new Date().getFullYear()
  env = '(Servidor - Desarrollo)';
  version = environment.version;

  constructor(private store: Store<any>) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.settings = state
    })

    this.env = environment.urlServe === 'https://apis.crowe.mx/sgi/' ? '(Servidor - Producción)' : environment.urlServe === 'https://apis.crowe.mx/sgi-pruebas/' ? '(Servidor - Pruebas)' : '(Servidor - Desarrollo)';
  }

}
