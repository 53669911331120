import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-input-boolean',
  templateUrl: './input-boolean.component.html',
  styleUrls: ['./input-boolean.component.scss']
})
export class InputBooleanComponent implements OnInit {

  @Input() field: string;
  @Input() label: string = "Seleccione una opción";
  @Input() form: FormGroup;
  @Input() disabled: boolean = false;
  @Input() showDisabled: boolean = false;
  @Input() trueText: string = "Sí";
  @Input() falseText: string = "No";

  constructor(private validator: ValidationService) { }

  ngOnInit(): void {  

  }

  isValidField(): boolean | null {
    return this.validator.isValidField(this._control);
  }

  getFieldError(): string | null {
    return this.validator.getFieldError(this._control, this.field);
  }

  get _control(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  get required(): boolean {
    return this.validator.isRequired(this._control);
  }

}
