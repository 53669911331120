import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {

  @Input() field: string;
  @Input() form: FormGroup;
  @Input() disabled: boolean = false;
  @Input() type: "pdf" | "img" | "all" | "csv" = "pdf";
  @Input() multiple = false;
  @Input() label: string = "Archivo";
  @Input() id: string = "";
  @Output() file: EventEmitter<any> = new EventEmitter<any>();

  fileName: string = "";
  accept: ".pdf" | ".png, .jpg, .jpeg" | ".png, .jpg, .jpeg, .pdf" | ".csv" =  ".pdf";
  files: any[] = [];

  constructor(private validator: ValidationService) { }

  ngOnInit(): void {  
    if (this.type === "img") {
      this.accept = ".png, .jpg, .jpeg";
    } else if (this.type === "pdf") {
      this.accept = ".pdf";
    } else if (this.type === "csv") {
      this.accept = ".csv";
    } else if (this.type === "all") {
      this.accept = ".png, .jpg, .jpeg, .pdf";
    }

    if (this.id === "") {
      this.id = this.field;
    }
    
    this._control.valueChanges.subscribe(value => {
      if (!value) {
        this.fileName = "";
      }
    })
  }

  setFile(event: any): void {
    if (!this.multiple) {
      const value = event.target.files;
      this.file.emit(value[0]);
      this.fileName = value[0].name;
    } else {
      const value = event.target.files;
      
      for(let i = 0; i < value.length; i++) {
        this.files.push(value[i]);
      }
      
      this.file.emit(this.files);;
    }
  }

  deleteFile(file: any) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.file.emit(this.files);
  }

  isValidField(): boolean | null {
    if (this._control.untouched) return null;
    return this.validator.isValidField(this._control);
  }

  getFieldError(): string | null {

    if (this._control.untouched) return null;

    return this.validator.getFieldError(this._control, this.field);
  }

  get _control(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  get required(): boolean {
    return this.validator.isRequired(this._control);
  }

}
