import { Component, OnInit } from '@angular/core';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';

@Component({
  selector: 'vex-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class NotFoundComponent implements OnInit {

  date = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

}
