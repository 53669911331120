import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { FileItem } from 'src/app/shared/interfaces/file.interface';
import { FormDocumentComponent } from '../form-document/form-document.component';
import { Catalog } from 'src/app/shared/interfaces/catalogs.interface';
import { ViewFileComponent } from 'src/app/shared/components/view-file/view-file.component';
import { ActivatedRoute} from '@angular/router';
import { PersonalService } from '../../services/personal/personal.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { DriveService } from 'src/app/shared/services/gapis/drive/drive.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { HttpResponse } from 'src/app/shared/interfaces/http-response.interface';
import { decrypt } from 'src/app/shared/util/helpers';

@Component({
  selector: 'app-personal-documents',
  templateUrl: './personal-documents.component.html',
  styleUrls: ['./personal-documents.component.scss']
})
export class PersonalDocumentsComponent implements OnInit, OnDestroy {

  categories: Catalog[] = []
  types: Catalog[] = []
  documents: FileItem[] = []
  id = -1;
  isEditing = false;
  loading = false;

  @Output() sendDocuments: EventEmitter<FileItem[]> = new EventEmitter();

  columns: TableColumn<FileItem>[] = [{ label: 'Nombre', property: 'name', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Categoria', property: 'category', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Tipo', property: 'fileType', type: 'fileType', visible: true },
    { label: 'Acciones', property: 'actions', type: 'button', visible: true, cssClasses: ["text-light"] },
  ];

  private subscriptions$: Subscription[] = []

  private _subject: BehaviorSubject<FileItem[]> = new BehaviorSubject<FileItem[]>([]);
  public data: Observable<FileItem[]> = this._subject.asObservable();

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private service: PersonalService,
    private socket: RxStompService,
    private drive: DriveService,
    private alert: AlertService
  ) { }
  
  ngOnDestroy(): void {
    this.subscriptions$.map(s => {
      s.unsubscribe();
      s.remove(s);
    })
  }

  ngOnInit(): void {
    this._subject.next(this.documents)

    this.loadData();
    
  }

  private loadData() {
    this.id = parseInt(decrypt(this.activatedRoute.snapshot.paramMap.get('id')));

    const types$ = this.service.getTypes().subscribe(
      resp => {
        this.types = resp.data;
      }
    )

    this.subscriptions$.push(types$);

    const categories$ = this.service.getCategories().subscribe(
      resp => {
        this.categories = resp.data;
      }
    );

    this.subscriptions$.push(categories$);

    

    if (isNaN(this.id) || this.id < 1) {
      this.id = 0;
      return;
    }

    this.subscribeToChanges(this.id);

    this.isEditing = true;
    

    const files$ = this.service.getFiles(this.id).subscribe(
      resp => {
        this.documents = resp.data;
        this._subject.next(resp.data);
      }
    );

    this.subscriptions$.push(files$);

  }

  show(event: FileItem) {
    this.dialog.open(ViewFileComponent, { data: { file: event }, disableClose: true } );
  }

  edit(event: FileItem) {
    const dialogRef = this.dialog.open(FormDocumentComponent ,
      { 
        data: { categories: this.categories, types: this.types, file: event, isEditing: this.isEditing, personalId: this.id }, 
        disableClose: true 
      } 
    );
    
    const edit$ = dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      
      const index = this.documents.findIndex(doc => doc.id === result.file.id);
      
      this.documents[index] = result.file;
      this._subject.next(this.documents);

      if (!this.isEditing) {
        this.sendDocuments.emit(this.documents);
      }

    });

    this.subscriptions$.push(edit$);
  }

  delete(event: FileItem) {
    
    if (event.uploadFile) {
      const index = this.documents.indexOf(event);
      this.documents = this.documents.filter(doc => doc !== this.documents[index]);
      this.sendDocuments.emit(this.documents);
      this._subject.next(this.documents);
      return;
    }

    this.alert.showWarning("El archivo se eliminará y no podrá ser recuperado.").then(
      (res) => {
        if (res.isConfirmed) {
          this.loading = true;
          const delete$ = this.drive.deleteFile(event.driveId).subscribe({
            next: data => {
              const delete$$ = this.service.deleteFile(event.id).subscribe({
                next: data => {
                  
                  this.alert.showSuccess("Archivo eliminado correctamente.");

                  this.socket.send(`/send/save_documents/${this.id}`, {});
                  this.loading = false;
      
                },
                error: error => {
                  this.loading = false;
                }
              });
      
              this.subscriptions$.push(delete$$);    
            },
            error: error => {
              this.loading = false;
            }
          })
      
          this.subscriptions$.push(delete$);
        }
      }
    )
  }

  add() {
    
    const dialogRef = this.dialog.open(FormDocumentComponent,
      { 
        data: {  categories: this.categories, types: this.types, isEditing: this.isEditing, personalId: this.id }, 
        disableClose: true 
      } 
    );
    
    const add$ = dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      
      if (!result.file?.id || result.file?.id <= 0) {
        result.file.id = this.documents.length + 1; 
        this.documents.push(result.file);
        this._subject.next(this.documents);
      }

      if (!this.isEditing) {
        this.sendDocuments.emit(this.documents);
      }

    });

    this.subscriptions$.push(add$)

  }

  private subscribeToChanges(id: number): void {
    const subscription$ = this.socket.listen<HttpResponse<FileItem[]>>(`/listen/personal_documents/${id}`).subscribe(
      response => {
        if (!response.ok) {
          return;
        }

        this.documents = response.data;
        this._subject.next(response.data);
      }
    )

    this.subscriptions$.push(subscription$);
  }

}
