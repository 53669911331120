import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-btn-back',
  templateUrl: './btn-back.component.html',
  styleUrls: ['./btn-back.component.scss']
})
export class BtnBackComponent implements OnInit {

  @Output() emitter: EventEmitter<boolean> = new EventEmitter();
  @Input() top: number = 0;
  @Input() right: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.emitter.emit(true);
  }

}
