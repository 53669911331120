import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { rrulestr } from 'rrule';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Event } from '../../../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private _eventsSubject: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>([]);
  events$: Observable<Event[]> = this._eventsSubject.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  
  getCalendars(): void {
    if (environment.offline) return;
    const params = new HttpParams().set("timeMin", new Date().toISOString());
    this.http.get(`${environment.gapis_url.calendar}/calendars/primary/events`, { params }).subscribe(
    (events: any) => {
      
      const arr: Event[] = [];

      events.items.map((event: any) => {
        if(event.status === 'confirmed') {
          this.getRecurrentDates(event, arr);
        }
      })

      this._eventsSubject.next(arr);
  });
}

getRecurrentDates(event: any, arr: Event[]): void {
  if(!event?.recurrence) {
    arr.push({
      isConference: event?.conferenceData ? true : false,
      conferenceLink: event?.hangoutLink,
      title: event.summary,
      description: event?.description,
      organizer: {
        email: event?.organizer?.email,
        name: event?.organizer?.displayName
      },
      start: event?.start?.dateTime ? event?.start?.dateTime : event?.start?.date,

    })
    return;
  }

  const rules = event?.recurrence[0];

  if (rules === "RRULE:FREQ=YEARLY") {
    arr.push({
      isConference: event?.conferenceData ? true : false,
      conferenceLink: event?.hangoutLink,
      title: event.summary,
      description: event?.description,
      organizer: {
        email: event?.organizer?.email,
        name: event?.organizer?.displayName
      },
      start: event?.start?.dateTime ? event?.start?.dateTime : event?.start?.date,

    })
    return 
  }

  const start = new Date(event?.start?.dateTime ? event?.start?.dateTime : event?.start?.date)

  const rule = rrulestr(rules);
  const date = rule.options.dtstart;
  date.setHours(start.getHours());
  date.setMinutes(start.getMinutes());
  date.setSeconds(start.getSeconds());
  rule.options.dtstart = date;
  rule.options.byhour = [start.getUTCHours()];
  rule.options.byminute = [start.getUTCMinutes()];
  rule.options.bysecond = [start.getUTCSeconds()];

  const nextDates = rule.all((date, i) => i < 10);
  
  nextDates.map(date => {

     arr.push(
      {
        isConference: event?.conferenceData ? true : false,
        conferenceLink: event?.hangoutLink,
        title: event.summary,
        description: event?.description,
        organizer: {
          email: event?.organizer?.email,
          name: event?.organizer?.displayName
        },
        start: date.toISOString(),
      }
     )

  })
}
}
