import { HttpClient } from '@angular/common/http';
import { Injectable, Version } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from 'src/app/shared/interfaces/http-response.interface';
import { environment } from 'src/environments/environment';
import { DiplomaItem, FormPersonal, Personal, PersonalFamily } from '../../interfaces/personal.interface';
import { FormBuilder } from '@angular/forms';
import { Catalog } from 'src/app/shared/interfaces/catalogs.interface';
import { FileItem } from 'src/app/shared/interfaces/file.interface';
import { PersonalIncidence, ProjectItem } from 'src/app/main/projects/interfaces/projects.interface';
import { Permission } from 'src/app/main/configuration/interfaces/permissions.interface';
import { Dashboard } from 'src/app/main/home/interfaces/home.interface';
import { PersonalHistory } from '../../interfaces/form.interface';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {

  private _baseUrl: string = environment.base_url;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder
  ) { }

  getPersonalActive(offices: string): Observable<HttpResponse<Personal[]>> {
    return this.http.get<HttpResponse<Personal[]>>(`${this._baseUrl}/personal/active`, { headers: { offices } });
  }

  getPersonalInactive(offices: string): Observable<HttpResponse<Personal[]>> {
    return this.http.get<HttpResponse<Personal[]>>(`${this._baseUrl}/personal/inactive`, { headers: { offices } });
  }

  getPersonalById(id: number): Observable<HttpResponse<FormPersonal>> {
    return this.http.get<HttpResponse<FormPersonal>>(`${this._baseUrl}/personal/detail/${id}`);
  }

  getCategories(): Observable<HttpResponse<Catalog[]>> {
    return this.http.get<HttpResponse<Catalog[]>>(`${this._baseUrl}/personal/file/categories`);
  }

  getTypes(): Observable<HttpResponse<Catalog[]>> {
    return this.http.get<HttpResponse<Catalog[]>>(`${this._baseUrl}/personal/file/types`);
  }

  saveFile(file: FileItem): Observable<HttpResponse<FileItem>> {
    return this.http.post<HttpResponse<FileItem>>(`${this._baseUrl}/personal/file/save`, JSON.stringify(file));    
  }

  deleteFile(id: number): Observable<HttpResponse<FileItem>> {
    return this.http.delete<HttpResponse<FileItem>>(`${this._baseUrl}/personal/file/${id}/delete`);    
  }

  getFiles(id: number): Observable<HttpResponse<FileItem[]>> {
    return this.http.get<HttpResponse<FileItem[]>>(`${this._baseUrl}/personal/files/${id}`);
  }

  // isValidEmail(email: string): Observable<HttpResponse<boolean>> {
  //   return this.http.post<HttpResponse<boolean>>(`${this._baseUrl}/personal/validate/${email}`, {});
  // }

  saveFamily(family: PersonalFamily): Observable<HttpResponse<PersonalFamily>>{
    return this.http.post<HttpResponse<PersonalFamily>>(`${this._baseUrl}/personal/family/save`, JSON.stringify(family));
  }

  deleteFamily(family: PersonalFamily): Observable<HttpResponse<PersonalFamily>>{
    return this.http.delete<HttpResponse<PersonalFamily>>(`${this._baseUrl}/personal/family/delete/${family.id}`);
  }

  getFamily(id: number): Observable<HttpResponse<PersonalFamily[]>>{
    return this.http.get<HttpResponse<PersonalFamily[]>>(`${this._baseUrl}/personal/family/find/${id}`);
  }

  getTypeFamily(): Observable<HttpResponse<Catalog[]>> {
    return this.http.get<HttpResponse<Catalog[]>>(`${this._baseUrl}/personal/family/types`);
  }

  getProjects(id: number): Observable<HttpResponse<ProjectItem[]>> {
    return this.http.get<HttpResponse<ProjectItem[]>>(`${this._baseUrl}/personal/projects/${id}`);
  }

  getIncidences(id: number): Observable<HttpResponse<PersonalIncidence[]>> {
    return this.http.get<HttpResponse<PersonalIncidence[]>>(`${this._baseUrl}/projects/incidence/personal/${id}`);
  }

  getDiplomas(id: number): Observable<HttpResponse<DiplomaItem[]>> {
    return this.http.get<HttpResponse<DiplomaItem[]>>(`${this._baseUrl}/training/assistant/diplomas/${id}`);
  }

  generateDiploma(personalId: number, eventId: number, isExponent: boolean): Observable<HttpResponse<string>> {
    return this.http.get<HttpResponse<string>>(`${this._baseUrl}/training/assistant/diploma/${personalId}/${eventId}/${isExponent}`);
  }

  findPermissions(personalId: number): Observable<HttpResponse<Permission[]>> {
    return this.http.get<HttpResponse<Permission[]>>(`${this._baseUrl}/personal/permissions/find/${personalId}`);
  }

  savePermissions(data: Permission[]): Observable<HttpResponse<Permission[]>> {
    return this.http.post<HttpResponse<Permission[]>>(`${this._baseUrl}/personal/permissions/save`, JSON.stringify(data));
  }

  getDashboard(personalId: number, date: string): Observable<HttpResponse<Dashboard>> {
    return this.http.get<HttpResponse<Dashboard>>(`${this._baseUrl}/personal/dashboard/${personalId}/${date}`);
  }

  getVCard(personalId: number): Observable<HttpResponse<string>> {
    return this.http.get<HttpResponse<string>>(`${this._baseUrl}/personal/vcard/${personalId}`);
  }

  getTree(id: number): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this._baseUrl}/personal/tree/${id}`);
  }
  getOfficeTree(id: number): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this._baseUrl}/personal/office-tree/${id}`);
  }

  getEmail(id: number): Observable<HttpResponse<string>> {
    return this.http.get<HttpResponse<string>>(`${this._baseUrl}/personal/find/email/${id}`);
  }

  setStatus(data: PersonalHistory):Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this._baseUrl}/personal/set-status`, JSON.stringify(data));
  }

}
