import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpStatusService {

  private subject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public loading: Observable<boolean> = this.subject.asObservable();

  constructor() { }

  setLoading(): void {
    this.subject.next(true);
  }

  setReady(): void {
    this.subject.next(false);
  }

}
