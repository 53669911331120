import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { PersonalRoutingModule } from './personal-routing.module';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { FormPageComponent } from './pages/form-page/form-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { PersonalDocumentsComponent } from './components/personal-documents/personal-documents.component';
import { FormDocumentComponent } from './components/form-document/form-document.component';
import { PersonalSummaryComponent } from './components/personal-summary/personal-summary.component';
import { FamilyFormComponent } from './components/family-form/family-form.component';
import { MyProjectsComponent } from './components/my-projects/my-projects.component';
import { DiplomasComponent } from './components/diplomas/diplomas.component';
import { IncidencesComponent } from './components/incidences/incidences.component';
import { ViewDiplomaComponent } from './components/view-diploma/view-diploma.component';
import { OrgChartComponent } from './components/org-chart/org-chart.component';
import { RoleComponent } from './components/role/role.component';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { OrgChartOfficeComponent } from './components/org-chart-office/org-chart-office.component';
import { ActiveFormComponent } from './components/active-form/active-form.component';
import { InactiveFormComponent } from './components/inactive-form/inactive-form.component';


@NgModule({
  declarations: [
    MainPageComponent,
    FormPageComponent,
    PersonalDocumentsComponent,
    FormDocumentComponent,
    FamilyFormComponent,
    PersonalSummaryComponent,
    MyProjectsComponent,
    DiplomasComponent,
    IncidencesComponent,
    ViewDiplomaComponent,
    OrgChartComponent,
    RoleComponent,
    OrgChartOfficeComponent,
    ActiveFormComponent,
    InactiveFormComponent,
  ],
  imports: [
    CommonModule,
    PersonalRoutingModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule, 
    NgxOrgChartModule
  ],
  exports: [
    PersonalDocumentsComponent,
    FormDocumentComponent,
    FamilyFormComponent,
    PersonalSummaryComponent,
    MyProjectsComponent,
    DiplomasComponent,
    IncidencesComponent,
    ViewDiplomaComponent,
    OrgChartComponent,
  ],
  providers: [ DatePipe ]
})
export class PersonalModule { }
