import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivityForm, initActivityForm } from '../../interfaces/form.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddActivityDialog } from '../../interfaces/dialog-data.interface';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { buildCatalog, buildItem, buildSeconds, formatTime, groupById } from 'src/app/shared/util/helpers';
import { TimeTrackingService } from '../../services/time-tracking/time-tracking.service';
import { Activity, ActivityItem, CatActivity, CatProject, ListCatalogs, initCatalogs } from '../../interfaces/time-tracking.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { MASK_HOUR } from 'src/app/shared/util/constants';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { filter, Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'vex-add-hour',
  templateUrl: './add-hour.component.html',
  styleUrls: ['./add-hour.component.scss'],
  animations: [fadeInRight400ms]
})
export class AddHourComponent implements OnInit, AfterViewInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public form: FormGroup<ActivityForm>;
  public loading: boolean = false;
  public today: string = '';
  public user: User;
  public catalogs: ListCatalogs = initCatalogs
  public activity: CatActivity[] = [];
  public isEditing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddHourComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddActivityDialog,
    private fb: FormBuilder,
    private alert: AlertService,
    private socket: RxStompService,
    private service: TimeTrackingService,
    private userService: UserService
  ) { }
  
  ngAfterViewInit(): void {
    
    // (this.trigger as any).closed = (this.trigger as any).close = this.configureMenuClose((this.trigger as any).close);
    // this.trigger.menuClosed.subscribe(res => {
    
    //   // this.trigger.openMenu();
    // })

    // this.trigger.

  }

  ngOnInit(): void {
    this.userService.user$.subscribe(user => {
      if (!user) return;
      
      this.user = user;
      this.loadForm();
      
      if (this.data?.id) {
        this.loading = true;
        this.isEditing = true;
        this.service.findActivity(this.data.id).subscribe({
          next: response => {
            this.loadForm(response.data);
            this.form.disable();
            this.today = response.data.date;
            this.loading = false;
          },
          error: response => {
            
            this.loading = false;
          }
        })
      }

      this.loadDate();
      
      this.loadCatalogs();
    })
  }

  private loadForm(data: Activity = initActivityForm): void {
    this.today = this.data.date;
    this.form = this.fb.group({
      id: [data.id],
      project: [buildCatalog(data.projectId) as CatProject, [Validators.required]],
      activity: [buildCatalog(data.activityId) as CatActivity, [Validators.required]],
      hours: [data.time ? `${formatTime(data.time)}` : null, [Validators.required]],
      notes: [data.notes, [Validators.required]],
    });

    if (this.dateIsToday) {
      this.form.controls.hours.clearValidators();
      this.form.controls.hours.updateValueAndValidity();
    }

    if (this.isEditing && this.form.disabled) {
      this.form.controls.activity.disable();
    }

    this.form.controls.project.valueChanges.subscribe(
      value => {
        if (value) {
          buildItem(value.value);
          let activity = this.catalogs.activity.filter(a => a.serviceId === value.serviceId)
          if (activity.length <= 0) activity = this.catalogs.activity;
          this.activity = groupById(activity, "phaseId", "phase");
        }
      }
    )
  }

  private loadCatalogs(): void {
    this.service.findCatalogs(this.user.id).subscribe(response => { 
      this.catalogs = response.data;
    })
  }

  onSubmit(): void {
    if (this.form.invalid) return;

    

    const data: Activity = {
      id: this.form.value.id,
      personalId: this.user.id,
      projectId: this.form.value.project.id,
      activityId: this.form.value.activity.id,
      time: this.counterEnabled ? 0 : buildSeconds(this.form.value.hours),
      notes: this.form.value.notes,
      date: this.today,
      counterEnabled: this.counterEnabled,
    }
    this.loading = true;
    this.service.saveActivity(data).subscribe({
      next: response => {
        this.alert.showSuccess(response.message);

        const arr = buildItem(this.form.value.project.value);

        const item: ActivityItem = {
          id: response.data.id,
          company: arr[0],
          project: arr[1],
          activity: this.form.value.activity.value,
          time: this.counterEnabled ? 0 : response.data.time,
          notes: this.form.value.notes,
          initDate: response.data.date,
          endDate: response.data.date,
          counterActive: this.counterEnabled,
        }

        if (this.counterEnabled) {
          this.userService.setActiveCounter();
        }

        this.data.response = item;
        this.dialogRef.close(this.data);
        
        this.socket.send(`/send/time-tracking/${this.user.id}/1/0`, item);
        this.socket.send(`/send/dashboard-changes/${this.user.id}`);

        // this.service.showTable(0);
        this.loading = false;
      },
      error: response => {
        this.loading = false;
        this.alert.showError(response.error.message);
      }
    })
  }

  loadDate(): void {
    this.service.date$.subscribe(date => {
      this.today = date; 
    })
  }

  close(): void {
    this.dialogRef.close();
  }

  closeMenu(): void {
    this.trigger.closeMenu();
  }

  
  get dateIsToday(): boolean {
    return new Date(this.today).toDateString() === new Date().toDateString();
  }

  get counterEnabled(): boolean {
    return this.dateIsToday && !this.form.value.hours;
  }
  
  get maskHour(): string {
    return MASK_HOUR;
  }
  
  get selected(): Date {
    return new Date(this.today);
  }

  afterMenuClose(): void {
    
  }

}