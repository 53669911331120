import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin, observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface FileUploaded {
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class DriveService {

  USER_FOLDER = "";
  PARENT_FOLDER = "";
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) { }
  
  loadFolders(email: string, parent: string): Observable<any> {

    if(!email) return;

    if (!parent) return;

    this.PARENT_FOLDER = parent;

    const url = `${environment.gapis_url.drive}/files?q=name='${email}'+and+mimeType='application/vnd.google-apps.folder'+and+'${parent}'+in+parents&supportsAllDrives=true`;
    this.loadingSubject.next(true);
    return this.http.get(url).pipe(
      switchMap((data: any) => {
        const file = data?.files[0];

        if (!file) {
          
          return this.createFolder(email, this.PARENT_FOLDER);
        }

        if (file?.name !== email) {
          
          return this.createFolder(email, this.PARENT_FOLDER);
        }

        this.USER_FOLDER = file.id;
        this.loadingSubject.next(false);
        return of(true);
      })
    )
  }

  public loadFolder(email:string, office: string, parent: string) {
    if (!email || !office || !parent) return;
    const url = `${environment.gapis_url.drive}/files?q=name='${office}'+and+mimeType='application/vnd.google-apps.folder'+and+'${parent}'+in+parents&supportsAllDrives=true`;

    this.loadingSubject.next(true);
    return this.http.get(url).pipe(
      switchMap((data: any) => {
        const file = data?.files[0];
        console.log(file);

        if (!file || file?.name !== office) {
          
          return this.createPersonalFolder(email, office, parent);
        }

        this.USER_FOLDER = file.id;

        this.loadingSubject.next(false);
        return this.loadFolders(email, this.USER_FOLDER);
      })
    )

  }

  private createPersonalFolder(email:string, office: string, parent: string) {
    this.PARENT_FOLDER = parent;

    const createFolderUrl = `${environment.gapis_url.drive}/files?fields=id&supportsAllDrives=true`;
  
    const metadata = {
      'name': office,
      'mimeType': 'application/vnd.google-apps.folder',
      'parents': [parent],
    };

    this.loadingSubject.next(true);

    return this.http.post(createFolderUrl, metadata).pipe(
      switchMap((response: any) => {
        this.USER_FOLDER = response.id;
        this.setPermissions(this.USER_FOLDER, ["marco.cortes@crowe.mx", "arturo.cortes@crowe.mx"], "writer");
        this.loadingSubject.next(false);
        return this.createFolderEmail(email, response.id);
      })
    );
  }

  private createFolderEmail(email: string, parent: string) {
    this.PARENT_FOLDER = parent;

    const createFolderUrl = `${environment.gapis_url.drive}/files?fields=id&supportsAllDrives=true`;
  
    const metadata = {
      'name': email,
      'mimeType': 'application/vnd.google-apps.folder',
      'parents': [this.PARENT_FOLDER],
    };

    this.loadingSubject.next(true);
  
    return this.http.post(createFolderUrl, metadata).pipe(
      switchMap((response: any) => {
        this.USER_FOLDER = response.id;
        this.loadingSubject.next(false);
        return this.setPermissions(this.USER_FOLDER, ["marco.cortes@crowe.mx", "arturo.cortes@crowe.mx"], "writer");
      })
    );
  }

  public createFolder(email: string, parent: string) {
    
    this.PARENT_FOLDER = parent;

    const createFolderUrl = `${environment.gapis_url.drive}/files?fields=id&supportsAllDrives=true`;
  
    const metadata = {
      'name': email,
      'mimeType': 'application/vnd.google-apps.folder',
      'parents': [this.PARENT_FOLDER],
    };

    this.loadingSubject.next(true);
  
    return this.http.post(createFolderUrl, metadata).pipe(
      switchMap((response: any) => {
        this.USER_FOLDER = response.id;
        this.loadingSubject.next(false);
        return this.setPermissions(this.USER_FOLDER, ["marco.cortes@crowe.mx", "arturo.cortes@crowe.mx"], "writer");
      })
    );
  }

  deleteFolder() {
    return this.deleteFile(this.USER_FOLDER);
  }

  uploadFile(file: File): Observable<FileUploaded> {

    if (!this.USER_FOLDER) {
      
      return;
    }

    const fileMetadata = {
      name: file.name,
      parents: [this.USER_FOLDER]
    }

    const body = new FormData();
    body.append("metadata", new Blob([JSON.stringify(fileMetadata)], {type: 'application/json'}));
    body.append("file", file);

    return this.http.post<FileUploaded>(`${environment.gapis_url.drive_upload}/files?uploadType=multipart&supportsAllDrives=true`, body);
  }

  uploadFiles(files: File[]): Observable<FileUploaded[]> {
    const observables: Observable<any>[] = files.map(file => {
      return this.uploadFile(file);
    })
    return forkJoin(observables);
  }

  setPermissions(fileId: string, emailAddresses: string[], role: string): Observable<any[]> {
    const url = `${environment.gapis_url.drive}/files/${fileId}/permissions?sendNotificationEmail=false&supportsAllDrives=true`;
    
    const requests: Observable<any>[] = [];
    
    emailAddresses.forEach(email => {
      const requestBody = {
        role: role,
        type: 'user',
        emailAddress: email,
        sendNotificationEmail: false
      };
      const request = this.http.post(url, requestBody);
      requests.push(request);
    });
    
    return forkJoin(requests);
  }


  downloadFile(driveId: string) {
    return this.http.get(`${environment.gapis_url.drive}/files/${driveId}?alt=media`, { responseType: 'blob' });
  }

  deleteFile(driveId: string) {
    return this.http.delete(`${environment.gapis_url.drive}/files/${driveId}`);
  }
  
  setFolder(id: string) {
    this.USER_FOLDER = id;
  }
}
