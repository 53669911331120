import { Injectable } from '@angular/core';
import { FormGroupSteps, FormPersonal, Personal, initForm } from '../../../interfaces/personal.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEX_CURP, REGEX_NSS, REGEX_RFC } from 'src/app/shared/util/constants';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { HttpResponse } from 'src/app/shared/interfaces/http-response.interface';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/shared/services/validation/validation.service';
import { DatePipe } from '@angular/common';
import { PersonalHistoryForm } from '../../../interfaces/form.interface';
import { buildCatalog } from 'src/app/shared/util/helpers';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private currentMail = "";
  
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) { }

  loadForm(id: number): Observable<FormGroupSteps> {
    if (id <= 0) {
      return of(this.buildForm(initForm))
    }
    
    return this.getPersonalById(id).pipe(
      catchError(error => of(null)),
      switchMap(value => of(this.buildForm(value.data)))
    );
  }

  getPersonalById(id: number): Observable<HttpResponse<FormPersonal>> {
    return this.http.get<HttpResponse<FormPersonal>>(`${environment.base_url}/personal/detail/${id}`);
  }

  buildForm(personal: FormPersonal): FormGroupSteps {
    const firstStep = this.fb.group({
      id: [personal.firstStep.id],
      name: [personal.firstStep.name, Validators.required],
      lastName: [personal.firstStep.lastName, Validators.required],
      rfc: [personal.firstStep.rfc, [Validators.required, Validators.pattern(REGEX_RFC)]],
      curp: [personal.firstStep.curp, [Validators.required, Validators.pattern(REGEX_CURP)]],
      nss: [personal.firstStep.nss, [Validators.required, Validators.pattern(REGEX_NSS)]],
      birthDate: [new Date(personal.firstStep.birthDate), Validators.required],
      entryDate: [new Date(personal.firstStep.entryDate), Validators.required],
      birthLocation: [personal.firstStep.birthLocation, Validators.required],
      gender: [personal.firstStep.gender, Validators.required],
    });

    const secondStep = this.fb.group({
      personalType: [personal.secondStep.personalType, Validators.required],
      education: [personal.secondStep.education, Validators.required],
      career: [personal.secondStep.career, Validators.required],
      parent: [personal.secondStep.parent, Validators.required],
      workDay: [personal.secondStep.workDay, Validators.required],
      office: [personal.secondStep.office, Validators.required],
      area: [personal.secondStep.role?.area, Validators.required],
      role: [personal.secondStep.role, Validators.required],
      certification: [personal.secondStep.certification, Validators.required],
      certificate: [personal.secondStep.certificate],
      certificateExpiry: [personal.secondStep.certificateExpiry],
      hourCost: [personal.secondStep.hourCost, Validators.required],
      hourPrice: [personal.secondStep.hourPrice, Validators.required],
      internalId: [personal.secondStep.internalId]
    });

    this.currentMail = personal.thirdStep.email;

    const thirdStep = this.fb.group({
      email: [personal.thirdStep.email, [Validators.required, ValidationService.validateEmail], ValidationService.emailExists(this, this.currentMail)],
      phone: [personal.thirdStep.phone, Validators.required],
      phoneOffice: [personal.thirdStep.phoneOffice],
      address: [personal.thirdStep.address, Validators.required]
    });

    const summaryStep = this.fb.group({
      allergies: [personal.summaryStep.allergies, Validators.required],
      hobbies: [personal.summaryStep.hobbies, Validators.required],
      family: [null]
    });
    
    return { firstStep, secondStep, thirdStep, summaryStep };
  }

  certificationChange(secondStep: FormGroup, value: boolean) {
    if (value === true) {
      secondStep.get("certificate").setValidators(Validators.required);
      secondStep.get("certificateExpiry").setValidators(Validators.required);
    } else {
      secondStep.get("certificate").clearValidators();
      secondStep.get("certificateExpiry").clearValidators();
    }
    secondStep.get("certificate").updateValueAndValidity()
    secondStep.get("certificateExpiry").updateValueAndValidity()
    secondStep.updateValueAndValidity();
  }

  isValidEmail(email: string): Observable<HttpResponse<boolean>> {
    return this.http.post<HttpResponse<boolean>>(`${environment.base_url}/personal/validate/${email}`, {});
  }


  onSubmitFirstStep(step: FormGroup) {
    step.markAllAsTouched(); 
    if (step.invalid) return;
    
    const firstStep = {
      ...step.getRawValue(),
      birthDate: this.datePipe.transform(step.getRawValue().birthDate, 'yyyy-MM-dd HH:mm:ss'),
      entryDate: this.datePipe.transform(step.getRawValue().entryDate, 'yyyy-MM-dd HH:mm:ss'),
    };
    
    return firstStep;
  }

  onSubmitSecondStep(step: FormGroup) {
    step.markAllAsTouched(); 
    if (step.invalid) return;
    
    const secondStep = {
      ...step.getRawValue(),
    };

    if (secondStep.certificateExpiry) {
      secondStep.certificateExpiry = new Date(secondStep.certificateExpiry).toISOString();
    }

    
    
    return secondStep;
  }

  onSubmitThirdStep(step: FormGroup) {
    step.markAllAsTouched(); 
    if (step.invalid) return;

    const thirdStep = {
      ...step.getRawValue(),
    }

    return thirdStep;
  }

  onSubmitSummaryStep(step: FormGroup) {
    step.markAllAsTouched();
    if(step.invalid) return;
    const summaryStep = {
      ...step.value,
    }
    return summaryStep;
  }

  buildPersonalHistoryForm(personalId: number, email: string, typeId: number, reasonId: number, rehire: boolean): FormGroup<PersonalHistoryForm> {
    const form: FormGroup<PersonalHistoryForm> = this.fb.group({
      personalId: [personalId, [Validators.required]],
      notes: [null as string, Validators.required],
      email: [email, [Validators.required, ValidationService.validateEmail], ValidationService.emailExists(this, email)],
      typeId: [typeId, [Validators.required]], // 1 active, 2 inactive
      reasonId: [buildCatalog(reasonId), [Validators.required]],
      rehire: [rehire, [Validators.required]],
      changeDate: [null as string, [Validators.required]],
    });

    return form;
  }
}
