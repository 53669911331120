import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
import { Router } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd";
import { select, Store } from "@ngrx/store";
import * as UserActions from "src/app/store/user/actions";

import { auth } from "firebase/app";

import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/firestore";

import { UserData } from "./../interfaces/user-data";
import { UserDataService } from "./../api/user-data.service";

import { environment } from "src/environments/environment";

import storeDataU from "store";
import { AccessService } from "../api/access.service";
import Swal from "sweetalert2";

// interface User {
//   // uid: string
//   // email: string
//   // displayName: string
//   // photoURL: string
//   // emailVerified: boolean
//   // role: string
//   uid: string;
//   email: string;
//   displayName: string;
//   photoURL: string;
//   emailVerified: boolean;
// }

export const firebaseConfig = {
  apiKey: "AIzaSyBZb0DlnT0naAmz_-Dvft1t-h_bk92JSnA",
  authDomain: "sgicrowemx-ea3e4.firebaseapp.com",
  databaseURL: "https://sgicrowemx-ea3e4-default-rtdb.firebaseio.com",
  projectId: "sgicrowemx-ea3e4",
  storageBucket: "sgicrowemx-ea3e4.firebasestorage.app",
  messagingSenderId: "987136381150",
  appId: "1:987136381150:web:b69be075b36b2d5fd5324f",
  measurementId: "G-NEECP7T98G"
};

@Injectable({
  providedIn: "root"
})
export class firebaseAuthService {
  // authProvider: string = ''
  userDataBase: UserData;

  constructor(
    public firebaseAuth: AngularFireAuth,
    public firebaseDatabase: AngularFireDatabase,
    public router: Router,
    private notification: NzNotificationService,
    private store: Store<any>,
    public ngZone: NgZone,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public afs: AngularFirestore, // Inject Firestore service
    private userDataService: UserDataService,
    private accessService: AccessService
  ) {
    // this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
    //   this.authProvider = state.authProvider
    // })

    let user = storeDataU.get("userDataDB") !== undefined ? storeDataU.get("userDataDB")[0] : null;

    if (user) {
      this.accessService.getAccess(user.email).subscribe((data: any) => {
        environment.access = data[0];
      });
    }

    this.firebaseAuth.authState.subscribe(user => {
      if (user) {
        const userData = {
          id: user.uid,
          name: user.displayName,
          avatar: user.photoURL,
          email: user.email,
          emailVerified: user.emailVerified,
          authorized: true,
          loading: false
        };
        // this.router.navigate(["/"]);
        this.store.dispatch(
          new UserActions.LoadCurrentAccountSuccessful(userData)
        );
      } else {
        localStorage.removeItem("userDataDB");
      }
    });
  }

  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  AuthLogin(provider) {
    return this.firebaseAuth
      .signInWithPopup(provider)
      .then(result => {
        // 
        this.SetUserData(result.user, result.credential);
      })
      .catch(error => {
        window.alert(error);
      });
  }

  SetUserData(user, token) {
    return this.userDataService.getUserData(user.email, user.photoURL).subscribe(
      data => {
        const response = data[0][0];
        const loginOk = response["value"];
        const message = response["message"]

        if (!loginOk) {
          // window.alert("Error al iniciar sesión, si el error continua contactate con el administrador de la aplicación");

          Swal.fire({ title: "Error", text: message, icon: "error", confirmButtonText: "Aceptar"});

          return;
        } else {
          this.userDataBase = data[0];
          environment.riksCommitte = this.userDataBase[0]["riskCommittee"];
          environment.access = this.userDataBase[0].access;
          delete this.userDataBase[0].access;
          storeDataU.set("userDataDB", this.userDataBase);

          //Data From login google button
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${user.uid}`
          );
          const userData = {
            id: user.uid,
            name: user.displayName,
            avatar: user.photoURL,
            email: user.email,
            emailVerified: user.emailVerified,
            authorized: true,
            loading: false
          };

          this.router.navigate(["/"]);
          this.store.dispatch(
            new UserActions.LoadCurrentAccountSuccessful(userData)
          ); // save userData to store
          return userRef.set(userData, {
            merge: true
          });
        }
      },
      error => {
        Swal.fire({ title: "Error", text: "Ha ocurrido un error, intente más tarde.", icon: "error", confirmButtonText: "Aceptar"});
      }
    );
  }

  async logout() {
    return this.firebaseAuth.signOut().then(() => {
      this.inititalStateEnviroment();
    });
  }

  inititalStateEnviroment() {
    environment.counterActive = false;
    environment.idProject = 0;
    environment.projectSelectComponent = 0;
    environment.capitalHumanoComponent = 0;
    environment.editEmployee = false;
    environment.idEmployee = 0;
    environment.isEmployeeDisabled = 0;
    environment.clientesComponent = 0;
    environment.idCliente = 0;
    environment.varAux = false;
    environment.changeTableForm = false;
    environment.crmProspectComponent = 0;
    environment.idProspect = 0;
    environment.crmOppotunityComponent = 0;
    environment.idOpportunity = 0;
    environment.opportunityAction = "";
    environment.riksCommitte = false;

    environment.list = {
      capitalHumano: [],
      oportunidad: [],
      prospectos: [],
      proyectos: [[], [], []],
      clientes: [[],[]]
    };
    (environment.listAll = {
      capitalHumano: [],
      oportunidad: [],
      prospectos: [],
      proyectos: [[], [], []],
      clientes: [[],[]]
    }),
      (environment.search = {
        capitalHumano: "",
        oportunidad: ["", "", ""],
        prospectos: [""],
        proyectos: ["", "", ""],
        clientes: ['', '']
      }),
      (environment.tab = {
        capitalHumano: 1,
        oportunidad: 1,
        prospectos: 1,
        proyectos: 1,
        clientes: 1,
      }),
      (environment.page = {
        capitalHumano: 1,
        oportunidad: 1,
        prospectos: 1,
        proyectos: [1, 1, 1],
        clientes: [1,1]
      }),
      (environment.hideSearch = {
        capitalHumano: false,
        oportunidad: false,
        prospectos: false,
        proyectos: [false, false, false],
        clientes: [false, false]
      }),
      (environment.disabledBtns = {
        capitalHumano: true,
        oportunidad: true,
        prospectos: true,
        proyectos: true,
        clientes: true,
      });
  }
}
