export const dict_message = {
    "ok.response": "Datos guardados correctamente.",
    "created.response": "Registro creado correctamente.",
    "error.response.not_found": "Dato no encontrado",
    "error.response.user.not_found": "Usuario no encontrado",
    "eror.response.validation": "",
    "error.response.internal_exception": "Ha ocurrido un error, intente más tarde.",
    "error.response.unauthorized": "Inicie sesión por favor",
    "warning.personal.disable": "El personal seleccionado se dará de baja.",
    "ok.personal.deleted": "El personal se ha dado de baja",
    "error.unauthorized.office": "No tienes acceso a esta información",
    "error.response.subordinates_exists": "El personal tiene subordinados"
}