<mat-form-field (click)="open(inputElement)" *ngIf="required || showDisabled">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="_control" [required]="required" [disableOptionCentering]="true" #select autocomplete="off" [multiple]="multiple">
        <mat-option class="search">
            <input matInput placeholder="Buscar..." #inputElement (click)="$event.stopPropagation()" [(ngModel)]="search" (ngModelChange)="filter()" (keydown)="setValue($event)">    
        </mat-option>
        <div class="items-container" (scroll)="onScroll($event)">

            <mat-optgroup *ngFor="let item of displayedItems;" [label]="item.value">
                <mat-option *ngFor="let children of item.children;" [value]="children"> 
                    {{ children.value }} 
                </mat-option>
            </mat-optgroup>

            <mat-option *ngIf="displayedItems.length <= 0" [disabled]="true">
                Sin datos
            </mat-option>

        </div>
    </mat-select>

    <mat-error> {{ getFieldError() }} </mat-error>
</mat-form-field>
