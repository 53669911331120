<div class="page" @fadeInUp>
    
    <div class="form-header" *ngIf="isEditing"> <span [matTooltip]="display">{{ display }}</span> </div>

    <mat-stepper animationDuration="0"[linear]="isLinear" [orientation]="orientation" #stepper (selectionChange)="stepChange($event)" [selectedIndex]="tab">
        
        <mat-step [stepControl]="firstStep" label="Datos generales">
            <form [formGroup]="firstStep" [ngClass]="{'editing' : isEditing}">
                <div class="my-3" *ngIf="!isEditing">
                    <h1 class="text-lg font-semibold">Ingrese los datos del colaborador</h1>
                    <p class="font-light text-gray-600">Por favor complete los siguientes campos para continuar con el proceso.</p>
                </div>
                <div class="form-container">
                    <div class="md:flex gap-5 my-4">
                        <div class="col w-full md:w-1/2">
                            <app-input-text [form]="firstStep" field="name" label="Nombre(s)" placeholder="Ingrese el nombre"></app-input-text>
                            <app-input-text [form]="firstStep" field="lastName" label="Apellidos" placeholder="Ingrese los apellidos"></app-input-text>
                            <app-input-text [form]="firstStep" field="rfc" label="RFC" placeholder="Ingrese el RFC" [mask]="maskRFC" [upper]="true"></app-input-text>
                            <app-input-text [form]="firstStep" field="curp" label="CURP" placeholder="Ingrese la CURP" [mask]="maskCURP" [upper]="true"></app-input-text>
                            <app-input-text [form]="firstStep" field="nss" label="Número de seguridad social" placeholder="Ingrese el número de seguridad social" [mask]="maskNSS"></app-input-text>
                        </div>
                        <div class="col w-full md:w-1/2">
                            <app-input-date [form]="firstStep" field="birthDate" label="Fecha de nacimiento"></app-input-date>
                            <app-input-text [form]="firstStep" field="birthLocation" label="Lugar de nacimiento" placeholder="Ingrese el lugar de nacimiento"></app-input-text>
                            <app-input-select [form]="firstStep" field="gender" label="Sexo" [items]="catalogs.gender"></app-input-select>
                            <app-input-date [form]="firstStep" field="entryDate" label="Fecha de ingreso"></app-input-date>
                        </div>
                    </div>
                </div>
                <app-form-btns [form]="firstStep" [editFlag]="isEditing" (submit)="onSubmitStepOne()">
                    <button mat-raised-button matStepperNext color="primary"> <span>Siguiente</span> <mat-icon>navigate_next</mat-icon> </button>
                </app-form-btns>
            </form>
        </mat-step>

        <mat-step [stepControl]="secondStep" label="Datos laborales">
            <form [formGroup]="secondStep" [ngClass]="{'editing' : isEditing}">
                <div class="my-3" *ngIf="!isEditing">
                    <h1 class="text-lg font-semibold">Ingrese los datos laborales</h1>
                    <p class="font-light text-gray-600">Por favor complete los siguientes campos para continuar con el proceso.</p>
                </div>
                <div class="form-container">
                    <div class="md:flex gap-5 my-4">
                        <div class="col w-full md:w-1/2">
                            <app-input-select [form]="secondStep" field="personalType" label="Tipo de personal" [items]="catalogs.personalType"></app-input-select>
                            <app-input-select [form]="secondStep" field="education" label="Máximo grado de estudios" [items]="catalogs.education"></app-input-select>
                            <app-input-select [form]="secondStep" field="career" label="Carrera/Titulo" [items]="catalogs.career"></app-input-select>
                            <app-input-select [form]="secondStep" field="parent" label="Jefe directo" [items]="catalogs.personal"></app-input-select>
                            <app-input-select [form]="secondStep" field="office" label="Oficina" [items]="catalogs.office"></app-input-select>
                            <app-input-select [form]="secondStep" field="area" label="Área" [items]="catalogs.area"></app-input-select>
                            <app-input-select [form]="secondStep" field="role" label="Puesto" [items]="role"></app-input-select>
                        </div>
                        <div class="col w-full md:w-1/2">
                            <app-input-boolean [form]="secondStep" field="certification" label="¿Tiene certificado DPC?"></app-input-boolean>
                            <app-input-text *ngIf="certification" [form]="secondStep" field="certificate" label="Certificado DPC" placeholder="Ingrese el certificado"></app-input-text>
                            <app-input-date *ngIf="certification" [form]="secondStep" field="certificateExpiry" label="Vigencia"></app-input-date>
                            <app-input-select [form]="secondStep" field="workDay" label="Tipo de jornada" [items]="catalogs.workDay"></app-input-select>
                            <app-input-number [form]="secondStep" field="hourCost" label="Costo por hora (MXN)" placeholder="0000000.00"></app-input-number>
                            <app-input-number [form]="secondStep" field="hourPrice" label="Precio por hora (MXN)" placeholder="0000000.00"></app-input-number>
                            <app-input-text [form]="secondStep" field="internalId" label="Número Personal (Oficina)" placeholder="Ingrese el número"></app-input-text>
                        </div>
                    </div>
                </div>
                <app-form-btns [form]="secondStep" [editFlag]="isEditing" (submit)="onSubmitStepTwo()">
                    <div class="flex">
                        <button mat-raised-button matStepperPrevious class="mr-3">  <mat-icon>settings_backup_restore</mat-icon> <span class="ml-2">Regresar</span> </button>
                        <button mat-raised-button matStepperNext color="primary"><span>Siguiente</span> <mat-icon>navigate_next</mat-icon></button>
                    </div>
                </app-form-btns>
            </form>
        </mat-step>

        <mat-step [stepControl]="thirdStep" label="Datos de contacto">
            <form [formGroup]="thirdStep" [ngClass]="{'editing' : isEditing}">
                <div class="my-3" *ngIf="!isEditing">
                    <h1 class="text-lg font-semibold">Ingrese los datos de contacto</h1>
                    <p class="font-light text-gray-600">Por favor complete los siguientes campos para continuar con el proceso.</p>
                </div>
                <div class="form-container">
                    <div class="md:flex gap-5 my-4">
                        <div class="col w-full md:w-1/2">
                            <app-input-text [form]="thirdStep" field="email" label="Correo electrónico Crowe" placeholder="Ingrese el correo"></app-input-text>
                            <app-input-phone [form]="thirdStep" field="phone" label="Teléfono personal/Móvil"></app-input-phone>
                            <app-input-phone [form]="thirdStep" field="phoneOffice" label="Teléfono oficina"></app-input-phone>
                        </div>
                        <div class="col w-full md:w-1/2">
                            <app-input-text-area [form]="thirdStep" field="address" label="Dirección" placeholder="Ingrese la dirección"></app-input-text-area>
                        </div>
                    </div>
                </div>
                <app-form-btns [form]="thirdStep" [editFlag]="isEditing" (submit)="onSubmitStepThree()">
                    <div class="flex">
                        <button mat-raised-button matStepperPrevious class="mr-3">  <mat-icon>settings_backup_restore</mat-icon> <span class="ml-2">Regresar</span> </button>
                        <button mat-raised-button matStepperNext color="primary"><span>Siguiente</span> <mat-icon>navigate_next</mat-icon></button>
                    </div>
                </app-form-btns>
            </form>
        </mat-step>

        <mat-step label="Expediente">
            <app-personal-documents (sendDocuments)="setDocuments($event)"></app-personal-documents>
            <div class="flex" *ngIf="!isEditing">
                <button mat-raised-button matStepperPrevious class="mr-3">  <mat-icon>settings_backup_restore</mat-icon> <span class="ml-2">Regresar</span> </button>
                <button mat-raised-button matStepperNext color="primary"><span>Siguiente</span> <mat-icon>navigate_next</mat-icon></button>
            </div>
        </mat-step>

        <mat-step label="Datos de interés" [stepControl]="summaryStep">
            <app-personal-summary [form]="summaryStep"></app-personal-summary>
        </mat-step>

        <mat-step label="Proyectos Activos" *ngIf="isEditing">
            <app-my-projects [id]="id"></app-my-projects>
        </mat-step>
        <mat-step label="Linea de Reporte" *ngIf="isEditing">
            <app-org-chart [id]="id"></app-org-chart>
        </mat-step>
        <mat-step label="Registro de incidencias" *ngIf="isEditing">
            <app-incidences [id]="id"></app-incidences>
        </mat-step>
        <mat-step label="Diplomas" *ngIf="isEditing">
            <app-diplomas [id]="id" [personal]="personalName"></app-diplomas>
        </mat-step>

        <mat-step label="Confirmar" *ngIf="!isEditing">
            <div class="text-center">
                <h1 class="text-lg font-semibold mb-2">Finalización del registro</h1>
                <p class="font-light text-gray-600">Confirme los datos registrados, después haga click en "Enviar".</p>
                <img src="assets/img/illustrations/check.svg" class="finish-img" alt="">
                <div class="flex justify-center mt-5">
                    <button mat-raised-button matStepperPrevious class="mr-3">  <mat-icon>settings_backup_restore</mat-icon> <span class="ml-2">Regresar</span> </button>
                    <button mat-raised-button matStepperNext color="primary" (click)="onSubmit()"><span class="mr-2">Enviar</span> <mat-icon>send</mat-icon></button>
                </div>
            </div>
        </mat-step>

    </mat-stepper>

    <app-btn-back (emitter)="back()" [right]="23" [top]="18"></app-btn-back>

    <div class="loading-container" @fadeInUp *ngIf="loading">
        <app-loading [loading]="loading"></app-loading>
    </div>
    
    <div class="loading-container" @fadeInUp *ngIf="submitLoading">
        <app-loading [loading]="submitLoading"></app-loading>
    </div>
</div>

