import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ValidationService } from '../../services/validation/validation.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Catalog } from '../../interfaces/catalogs.interface';
import { MatSelect } from '@angular/material/select';
import { findItemGroupById, search } from '../../util/helpers';

@Component({
  selector: 'app-input-select-group',
  templateUrl: './input-select-group.component.html',
  styleUrls: ['./input-select-group.component.scss']
})
export class InputSelectGroupComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() field: string;
  @Input() label: string = "Seleccione una opción";
  @Input() form: FormGroup;
  @Input() items: Catalog[] = []
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Input() showDisabled: boolean = false;

  @ViewChild('#select', { static: false }) select: MatSelect;

  search: string = "";
  // filteredItems: Catalog[] = [];
  displayedItems: Catalog[] = [];
  slicedItems: Catalog[] = [];
  filteredItems: Catalog[] = [];

  constructor(private validator: ValidationService) { }

  ngAfterViewInit(): void {
    this.select?._closedStream.subscribe(data => {
      this.initValue();
    })
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue && changes.items.currentValue.length > 0) {
      this.items = changes.items.currentValue;
      
      this.displayedItems = this.sliceItems();
      this.initValue();
    }

    if (changes.form && changes.form.currentValue) {
      this.form = changes.form.currentValue;
      if (this.items && this.items.length > 0) {
        this.displayedItems = this.sliceItems();
        this.initValue();
      }
    }
  }

  ngOnInit() {
    
  }

  isValidField(): boolean | null {
    return this.validator.isValidField(this._control);
  }

  getFieldError(): string | null {
    return this.validator.getFieldError(this._control, this.field);
  }

  get _control(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  get required(): boolean {
    return this.validator.isRequired(this._control);
  }

  filter() {

    this.displayedItems = [];

    this.items.map(parent => {
      const resultadoParent = {
        id: parent.id,
        value: parent.value,
        enabled: parent.enabled,
        children: []
      }

      resultadoParent.children = parent.children.filter(item => search(item, this.search));

      if (resultadoParent.children.length > 0) {
        this.displayedItems.push(resultadoParent);
      }
    })
    
  }

  setValue(event: KeyboardEvent) {

    if(event.code === "Space") {
      event.stopPropagation();
      return;
    }

    if(event.code === "Escape") {
      this.select?.close();
      return;
    }

    if (event.code !== "Enter" && event.code !== "ArrowDown") {
      event.stopPropagation(); 
      return;
    }

    if (this.displayedItems.length <= 0) {
      return;
    }
    
    event.stopPropagation(); 
    
    const input = this.displayedItems[0];
    if (!this.multiple) this._control.setValue(input);
    

    if (event.code === "ArrowDown" && !this.multiple) return;

    if (event.code === "Enter")  {
      this.select?.close();
    }
  }

  findValue(catalog: Catalog): number {
    
    const a = findItemGroupById([catalog.id], this.items);
    if (a.length < 0) return -1;
    return a[0].id;
  }

  open(inputElement: HTMLInputElement) {
    this.search = "";
    this.initValue();
    inputElement.focus();
  }

  loadMoreItems() {
    const itemsToDisplay = 30; // Cantidad de elementos a cargar
    const startIndex = this.displayedItems.length;
    const endIndex = startIndex + itemsToDisplay;

    if (this.search === "") {
      if (startIndex < this.items.length) {
        this.displayedItems = this.displayedItems.concat(this.items.slice(startIndex, endIndex));
      }
    } else {
      if (startIndex < this.filteredItems.length) {
        this.displayedItems = this.displayedItems.concat(this.filteredItems.slice(startIndex, endIndex));
      }
    }

    
  }

  sliceItems(rango: [number, number] = [0, 30]) {
    if (this.items.length <= 30) return this.items;
    return this.items.slice(rango[0], rango[1])
  }

  onScroll(event) {
    const container = event.target;
    const scrollHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;
    const clientHeight = container.clientHeight;
    
    if (scrollHeight - scrollTop === clientHeight) {
      this.loadMoreItems();
    }
  }

  initValue() {

    if (this.multiple) {

      let items = [];
      this.items.filter(item => {
        
        const a = item.children.filter(child => this._control.value.some(item => item.id === child.id));

        if (a.length > 0) {
          items = [ ...a, ...items ]
          return true;
        }

        return false;

      })

      this._control.setValue(items);

    } else {
      const a = findItemGroupById([this._control?.value?.id], this.items);
      if (a.length < 0) return;
      this._control.setValue(a[0]);
    }

    this.slicedItems = this.displayedItems;

    
  }

  
}
