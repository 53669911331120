// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  access: [],
  production: false,
  authenticated: false,
  hmr: false,
  version: require('../../package.json').version,
  wsUrl: "http://localhost:5000",
  urlServe: "https://apis.crowe.mx/sgi-pruebas/",
  // urlServe: "https://apis.crowe.mx/sgi-pruebas/",
  //urlServe: 'http://localhost:8080/erpcrowe/sgiAppCroweAngular-local/',
  // urlServe: 'http://localhost:8080/erpcrowe/sgiAppCroweAngular/',
  // urlServe: 'http://localhost:8080/erpcrowe/sgiAppCroweAngular-pruebas/',
  counterActive: false,
  idProject: 0,
  projectSelectComponent: 0,
  planeacionEstrategicaComponent: 0,
  capitalHumanoComponent: 0,
  editEmployee: false,
  idEmployee: 0,
  isEmployeeDisabled: 0,
  clientesComponent: 0,
  idCliente: 0,
  varAux: false,
  changeTableForm: false,
  crmProspectComponent: 0,
  idProspect: 0,
  crmOppotunityComponent: 0,
  idOpportunity: 0,
  opportunityAction: "",
  riksCommitte: false,
  belongsTo: 0,
  crmOpportype: 0,
  capacitacion: {
    oficinasRelacionadas:  "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33",
    oficina: "1",
    esConcentradora: "1",
  },
  //Se guarda el estado de la tabla al cambiar de componente en reportes
  list: {
    capitalHumano: [],
    oportunidad: [],
    prospectos: [],
    proyectos: [[], [], []],
    clientes: [[], []]
  },
  listAll: {
    capitalHumano: [],
    oportunidad: [],
    prospectos: [],
    proyectos: [[], [], []],
    clientes: [[], []]
  },
  search: {
    capitalHumano: "",
    oportunidad: ["", "", ""],
    prospectos: [""],
    proyectos: ["", "", ""],
    clientes: ["", ""]
  },
  tab: {
    capitalHumano: 1,
    oportunidad: 1,
    prospectos: 1,
    proyectos: 1,
    clientes: 1
  },
  page: {
    capitalHumano: 1,
    oportunidad: 1,
    prospectos: 1,
    proyectos: [1, 1, 1],
    clientes: [1, 1]
  },
  hideSearch: {
    capitalHumano: false,
    oportunidad: false,
    prospectos: false,
    proyectos: [false, false, false],
    clientes: [false, false]
  },
  disabledBtns: {
    capitalHumano: true,
    oportunidad: true,
    prospectos: true,
    proyectos: true,
    clientes: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
