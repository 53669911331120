import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileItem } from '../../interfaces/file.interface';
import { DriveService } from '../../services/gapis/drive/drive.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { fileExtension } from '../../util/helpers';

@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss']
})
export class ViewFileComponent implements OnInit, OnDestroy {

  file: FileItem;
  url: SafeResourceUrl = "";
  loading = false;
  subscription: Subscription;
  isImage: boolean = false;
  urlImage: string | SafeResourceUrl = "";

  constructor(
    public dialogRef: MatDialogRef<ViewFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private drive: DriveService,
    private sanitizer: DomSanitizer
  ) { }
  
  ngOnDestroy(): void {
    if (!this.subscription) return;
    this.subscription.unsubscribe();
    this.subscription.remove(this.subscription);
  }

  ngOnInit(): void {
    this.file = this.data.file;
    this.loading = true;

    const extension = fileExtension(this.file.name);

    this.isImage = extension !== "pdf";

    if (this.file?.notUploaded) {
      this.render(this.file.uploadFile);
      return;
    }

    if (this.file?.extern || this.file.driveId.includes("http")) {
      this.renderLink(this.file.driveId);
      return;
    }

    this.subscription = this.drive.downloadFile(this.file.driveId).subscribe(
      data => {
        this.render(data);
      }
    )

  }

  close() {
    this.dialogRef.close();
  }

  render(file: any) {
    if (this.isImage) {
      this.renderImage(file);
    } else {
      this.renderPdf(file);
    }
  }

  renderPdf(file: any) {
    const url = URL.createObjectURL(file);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.loading = false;
  }

  renderImage(file: any) {
    const url = URL.createObjectURL(file);
    this.urlImage = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.loading = false;
  }

  renderLink(url: string) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.loading = false;
  }

}
