export enum Catalogs {
    AREA = "area",
    CAREER = "career",
    EDUCATION = "education",
    GENDER = "gender",
    PERSONAL_TYPE = "personalType",
    ROLE = "role",
    WORK_DAY = "workDay",
    OFFICE = "office",
    REASON = "reason",
}