<div>
    <div class="logo-container">
        <a [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 flex items-center">
            <img alt="Logo" class="w-8 select-none" src="assets/img/common/logo.svg" />
            <h1 class="logo-title ltr:pl-4 rtl:pr-4 m-0 select-none">Crowe SGI</h1>
        </a>
    </div>
    <div class="not-found-container">
        <div class="content" @fadeInUp>
    
            <mat-icon class="warning-icon">warning</mat-icon>
    
            <h1 class="title">¡Oops, Página no encontrada!</h1>
            <p class="text">La página que estás buscando no existe. Verifique la URL o intente regresar a la página de
                inicio.</p>
            <div class="btns-container">
                <a class="btn btn-primary" [routerLink]="['/']">Ir a la página de Inicio</a>
            </div>
            <p class="text">Si cree que se trata de un error, comuníquese con nuestro equipo de soporte.</p>
            <div class="footer-container">
                <p>Correo:</p>
                <p class="footer-text">ti@crowe.mx</p>
                <br>
                <p>Teléfono:</p>
                <p class="footer-text">+52 (55) 0000 0000</p>
            </div>
        </div>
    </div> 
    <div class="footer flex">
        <div class="flex-auto flex items-center justify-center ">
            <div class="flex-none">
                <img src="assets/img/common/crowe-logo.png" height="24px" width="24px" alt="SGI Crowe" />
            </div>
    
            <div class="font-small ltr:ml-4 rtl:mr-4 hidden sm:block">
                Copyright © {{ date }}
                <a href="https://www.crowe.com/mx" target="_blank" rel="noopener noreferrer">
                    Gossler S.C. miembro de Crowe Global
                </a>
                |
                <a href="https://www.crowe.com/mx/privacy-policy/" target="_blank" rel="noopener noreferrer">
                    Aviso de Privacidad
                </a>
            </div>
        </div>
    </div>
</div>