<div class="p-2 px-3">
    
    <div class="flex justify-between items-center">
        <h1 mat-dialog-title class="m-0 flex items-center"> 
            <span>Diploma</span> 
            <!-- <span class="text-file">{{ file.name }}</span>  -->
            <!-- <mat-icon class="not-uploaded" *ngIf="file?.notUploaded"> cloud_off </mat-icon>  -->
        </h1>
        <button class="close-button" (click)="close()"> <mat-icon>close</mat-icon> </button>
    </div>
    <div mat-dialog-content class="p-6">
        <div class="file-container">
            <iframe *ngIf="content" [src]="content" width="100%" height="100%"></iframe>
            <app-loading [loading]="loading"></app-loading>
        </div>
    </div>
    <div mat-dialog-actions align="end" class="px-3">
        <a [href]="content" [download]="data.diploma.diplomaName" mat-raised-button color="primary">Descargar</a>
    </div>
</div>
