import { Component, Inject, OnInit } from '@angular/core';
import { PersonalService } from '../../services/personal/personal.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-org-chart-office',
  templateUrl: './org-chart-office.component.html',
  styleUrls: ['./org-chart-office.component.scss']
})
export class OrgChartOfficeComponent implements OnInit {

  nodes: any = [];

  constructor(
    private service: PersonalService,
    public dialogRef: MatDialogRef<OrgChartOfficeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    // this.organigrama = ORGANIGRAMA;
    this.service.getOfficeTree(this.data.officeId).subscribe(response => {
      this.nodes = response.data;
    })
  }

  close() {
    this.dialogRef.close();
  }

}
