import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneComponent implements OnInit {

  @Input() field: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() form: FormGroup;
  @Input() disabled: boolean = false;
  mask: string = "";


  constructor(private validator: ValidationService) { }

  ngOnInit(): void {
    this._control.valueChanges.subscribe((value: string) => {
      
      // if (value.startsWith('+')) {
      //   if (value.length > 5) {
      //     this.mask = '+00 (000) 000 0000 ext. 00000';
      //   } else {
      //     this.mask = '+00 (00) 0000 0000 ext. 00000';
      //   }
      // } else if (value.length > 0) {
      //   if (value.length > 4) {
      //     this.mask = '(000) 000 0000 ext. 00000';
      //   } else {
      //     this.mask = '(00) 0000 0000 ext. 00000';
      //   }
      // } else {
      //   this.mask = "";
      // }
      
    })
  }

  isValidField(): boolean | null {
    return this.validator.isValidField(this._control);
  }

  getFieldError(): string | null {
    return this.validator.getFieldError(this._control, this.field);
  }

  get _control(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  get required(): boolean {
    return this.validator.isRequired(this._control);
  }

}
