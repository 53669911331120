import { HttpHeaders } from '@angular/common/http';
import { Catalog } from '../interfaces/catalogs.interface';
import { DateTime } from 'luxon';
import { FormGroup } from '@angular/forms';


export const authHeaders = (token: string): HttpHeaders=> {
    return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
} 

export const search = (data: any, filter: string): boolean => {
  const filterValues = filter.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(' ');
  const concatenatedValues = Object.values(data).join(' ').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return filterValues.every(value => concatenatedValues.includes(value));
}

export const buildId = (value: string): number => {
  const id = parseInt(value);
  return !value || isNaN(id) ? null : id;
}

export const buildCatalog = (id: number): Catalog | null => {
  if (!id && id < 0) return null;
  
  return {
    id,
    value: "",
    enabled: true
  }

}

export const buildCatalogMultiple = (arr: any[], bindId: string) : Catalog[] => {
  return arr.map( value => {
    return {
      id: value[bindId],
      value: "",
      enabled: true
    }
  })
}


export const formatDate = (value: string, format: string = "yyyy MMMM d", showHour = true, hoursValue: string = ""): string => {
  if (!value) return "";
  
  const arr = value.split("T");
  const hourZero = arr.length <= 1;
  const init = DateTime.fromJSDate(new Date(value));

  if (hoursValue !== "" && showHour) {
    const { hours, minutes } = getTime(hoursValue);

    const newValue =  init.set({
      hour: hours,
      minute: minutes,
      second: 0,
      millisecond: 0,
    });
    return newValue.toFormat(`${format}, H:mm a`);
  }


  if(hourZero || (init.hour === 0 && init.minute === 0 && init.second === 0) || !showHour) {
    return init.toFormat(format);
  }

  if (format === "") {
    return init.toFormat(`H:mm a`);  
  }

  
  return init.toFormat(`${format}, H:mm a`);
}

export const fileExtension = (name: string): string => {
  const arr = name.split(".");
  return arr[arr.length - 1];
}

export const findItemById = (id: number, arr: Catalog[]): Catalog | null => {

  const value = arr.find(i => i.id === id);

  if (value) return value;

  return null;
}

export const findItemGroupById = (arrIds: number[], arr: Catalog[]): Catalog[] => {
  let items: Catalog[] = [];
  arr.filter(item => {
    
    const a = item.children.filter(child => arrIds.some(item => item === child.id));

    if (a.length > 0) {
      items = [ ...a, ...items ]
      return true;
    }

    return false;

  })
  return items;
}

export const validateOfficesRelation = (officeIdItem: number, officesRelated: string): boolean => {
  const arr = officesRelated.split(',');
  return arr.includes(officeIdItem + "");
}

export const secretKey = 'TuClaveSecreta'; // Cambia esto por una clave segura
export const baseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';



export const encrypt = (id: number): string => {

  if (!id) return null;

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const length = 20;
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
  }
  const randomChars = Array.from({ length: 7 }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  return result + id.toString() + randomChars;
};

export const decrypt = (cifrado: string): string => {

  if (!cifrado) return null;

  const id = cifrado.substring(20, cifrado.length - 7);
  return id;
};

export const buildSeconds = (valor: string): number => {
  if (!valor) return 0;
  const strValor = valor.replace(":", "");
  // Separar las horas y los minutos
  let horas: string;
  let minutos: string;

  if (strValor.length === 1) {
      horas = '0' + strValor;
      minutos = '00';
  } else if (strValor.length === 2) {
      horas = strValor;
      minutos = '00';
  } else if (strValor.length === 3) {
      horas = '0' + strValor.charAt(0);
      minutos = strValor.substring(1);
  } else if (strValor.length === 4) {
      horas = strValor.substring(0, 2);
      minutos = strValor.substring(2);
  } else {
    return 0;
  }
  
  return parseInt(horas) * 3600 + parseInt(minutos) * 60;
} 

export const buildSecondsTime = (valor: string): number => {

  let horas: string = "0";
  let minutos: string = "0";
  const index = valor.indexOf(":");

  if (valor.includes(":")) {
    minutos = valor.substring(index + 1);
    if (minutos.length <= 1) {
      minutos = minutos + "0";
    }
    horas = valor.substring(0, index);
  } else {
    horas = valor;
  }

  return parseInt(horas) * 3600 + parseInt(minutos) * 60;
}

export const buildItem = (item: string): string[] => {
  const regex = /([^ ]+) \(([^)]+)\) - (.+) \(([^)]+)\)/;
  
  const [, project, activity, client, clientId] = item.match(regex) || [];
  
  return [
    `${clientId} - ${client}`,
    project,
    activity,
  ];
}

export const formatTime = (seconds: number): string => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    
    let formatHours = hours < 10 ? '0' + hours : hours;
    let formatMinutes = minutes < 10 ? '0' + minutes : minutes;

    const final = formatHours + ':' + formatMinutes;
    if (final === "00:00") return "N/A"
    
    return final;
}

export const formatTimeString = (time: string): string => {
  // if (time.includes(":")) return formatTime(buildSecondsTime(time));
  return formatTime(buildSeconds(time));
}

export const formatCurrency = (value: number): string => {
  return `$ ${value.toFixed(2)}`
}

export const dpcPoint = (value: number): string => {
  return (value / 3600).toFixed(2);
}

export const dateToTime = (date: string): string => {
  const dateTime = new Date(date);
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();

  let formatHours = hours < 10 ? '0' + hours : hours;
  let formatMinutes = minutes < 10 ? '0' + minutes : minutes;

  return formatHours + ':' + formatMinutes;
}

export const getHours = (time: string): number => {
  const arr = time.split(':');
  return parseInt(arr[0]);
}

export const getMinutes = (time: string): number => {
  const arr = time.split(':');
  return parseInt(arr[1]);
}

export const buildDateTime = (date: Date, time: string): Date => {
  const newDate = new Date(date?.getTime());
  const newTime = getTime(time);
  newDate.setHours(newTime.hours);
  newDate.setMinutes(newTime.minutes);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
}

export const getTime = (value: string): { hours: number, minutes: number } => {
  const arr = value.split(":");
  return {
    hours: parseInt(arr[0]),
    minutes: parseInt(arr[1]),
  }
}

export const buildTime = (hours: number, minutes: number): string => {
  const value = `${hours < 9 ? "0" : ""}${hours}:${minutes < 9 ? "0" : ""}${minutes}`;

  if (isNaN(hours) || isNaN(minutes)) return "";

  return value;
}

export const getRoot = (rotue: string): string => {
  const arr = rotue.split("/");

  if (arr.length <= 2) {

    if (arr[0] === "" && arr[1] === "") return "/";

    return "/" + arr[1];
  }

  return "/" + arr[1] + "/" + arr[2];
}

export const fixRoot = (items: any[]): any => {
  for (const item of items) {
      if (item.route === "/") {
          // Agregar la propiedad routerLinkActiveOptions al elemento encontrado
          item.routerLinkActiveOptions = { exact: true };
          return item;
      }
      if (item.children) {
          const foundInChildren = fixRoot(item.children);
          if (foundInChildren) {
              return foundInChildren;
          }
      }
  }
  return null;
}

export const findMenuItemByRoute = (items: any[], route: string): any | null => {
  for (const item of items) {
    if (item.route === route) {
      return item;
    }

    if (item.children) {
      const foundChild = findMenuItemByRoute(item.children, route);
      if (foundChild) {
        return foundChild;
      }
    }
  }

  return null;
}

export const markAllAsTouched = (form: FormGroup): void => {
  form.markAllAsTouched();
}

export const groupBy = (data: Catalog[], key: string): any => {
  const groups: { [key: string]: Catalog } = {};

  data.forEach(item => {
    const children: Catalog = item[key];
    if (!groups[children.value]) {
        groups[children.value] = {
            id: children.id,
            value: children.value,
            enabled: children.enabled,
            children: []
        };
    }

    groups[children.value].children?.push({
        id: item.id,
        value: item.value,
        enabled: item.enabled
    });
  });

  return Object.values(groups);
}

export const groupById = (array: any[], groupIdKey: string, groupNameKey: string) => {
  const grouped = array.reduce((acc, item) => {
    const groupId = item[groupIdKey];
    const groupName = item[groupNameKey];

    let group = acc.find(g => g.groupId === groupId);

    if (!group) {
        group = {
            groupId,
            groupName,
            children: []
        };
        acc.push(group);
    }

    group.children.push(item);
    return acc;
}, [] as { groupId: number, groupName: string, children: any[] }[]);
  
  return grouped.map(group => ({
    id: group.groupId,
    value: group.groupName,
    enabled: true,
    children: group.children
}));
};