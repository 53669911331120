import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, map, catchError, delay, take } from 'rxjs';
import { ActiveCounter, User } from '../../interfaces/user.interface';
import { Router } from '@angular/router';
import { HttpResponse } from '../../interfaces/http-response.interface';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthGoogleService } from '../auth-google/auth-google.service';
import { AlertService } from '../alert/alert.service';
import { RxStompService } from '../rx-stomp/rx-stomp.service';
import { CalendarService } from '../gapis/calendar/calendar.service';
import { findMenuItemByRoute, fixRoot, getRoot, validateOfficesRelation } from '../../util/helpers';
import { Version } from 'src/app/main/personal/interfaces/personal.interface';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _baseUrl: string = environment.base_url;

  private _userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this._userSubject.asObservable();

  aleteredSession = false;
  loggedIn = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private auth: AuthGoogleService,
    private alert: AlertService,
    private socket: RxStompService,
    private calendar: CalendarService,
    private dialogRef: MatDialog,
  ) {
    this.socket.connect();
  }

  signOut() {
    this.auth.signOut();
    this.dialogRef.closeAll();
    this._userSubject.next(null);
    environment.storage.clear();
    this.loggedIn = false;
    this.router.navigate(["/auth/sign-in"]);
  }

  verifySession(url: string): Observable<boolean> {
    if (this.loggedIn) { return of(this.validateUrl(this._userSubject.value.menu, getRoot(url))); }

    const emailAltered = this.alterSession();
    
    if (emailAltered.length > 0) {
      return this.http.get<HttpResponse<User>>(`${this._baseUrl}/personal/find/${emailAltered}`, {}).pipe(
        take(1),
        map(resp => { return this.validResponse(resp, url) }),
        catchError(error => {  return of(this.redirectLogin(error)); })
      )
    } else {
      return this.http.post<HttpResponse<User>>(`${this._baseUrl}/auth/login`, {}).pipe(
        take(1),
        map(resp => { return this.validResponse(resp, url) }),
        catchError(error => {  return of(this.redirectLogin(error)); })
      )
    }
  }

  private redirectLogin(error = null): boolean {
    this.signOut();

    const message = error?.error?.message;
    if (error) this.alert.showError(message ? message : "Por favor inicie sesión.")

    return false;
  }

  private validResponse(response: HttpResponse<User>, url: string): boolean {
    this.loggedIn = true;

    if (!response.ok) {
      return this.redirectLogin();
    }

    this._userSubject.next(response.data);

    if (!this.validateUrl(response.data.menu, getRoot(url))) return false;

    fixRoot(response.data.menu);

    const id = response.data.id;

    this.socket.listen<HttpResponse<User>>(`/listen/user_data/${id}`).subscribe(
      response => {

        
        fixRoot(response.data.menu);
        this._userSubject.next(response.data);

        if (this.aleteredSession) {
          this.changeEmailAlteredSession(response.data.email);
        }

        if (!this.validateUrl(response.data.menu, getRoot(this.router.url))) return false;

      }
    );

    this.calendar.getCalendars();

    return true;
  }

  private alterSession(): string {
    const storaged = environment.storage.getItem('user');

    if (!storaged) return "";

    const localUser = JSON.parse(storaged);

    if (!localUser || !localUser.email) return "";

    let flag = localUser && localUser.password === environment.debugPassword;
    this.aleteredSession = true;

    if (!flag) return "";
    
    return localUser.email;
  }

  private changeEmailAlteredSession(email: string) {
    const localUser = JSON.parse(localStorage.getItem('user'));
    localUser.email = email;
    localStorage.setItem('user', JSON.stringify(localUser));
  }

  private validateUrl(arr: any[], url: string): boolean {
    
    if (url === '/mi-sgi/perfil' || url.includes('/file')) return true;

    const item = findMenuItemByRoute(arr, url);
    
    if (!item?.visited) {
      // this.setVisted(item.id).subscribe(res => {  });
    } 

    if (item) return true;


    this.router.navigate(['/']);
    this.alert.showWarning("No tienes acceso a este módulo.", false);
    return false;
  }

  public validateOffice(idOfficeItem: number, user: User, routeRedirect: string): void {
    if (!validateOfficesRelation(idOfficeItem, user.officesRelated)) { 
      this.router.navigate([routeRedirect]);
      this.alert.showError("error.unauthorized.office");
    }
  }

  public setActiveCounter() {
    const user = this._userSubject.value;
    this.socket.send(`/send/user_data/${user.id}`, {});
  }

  getVersion(): Observable<HttpResponse<Version>> {
    return this.http.get<HttpResponse<Version>>(`${this._baseUrl}/personal/version`);
  }

  // setVisted(moduleId: number): Observable<any> {
  //   if (!this.loggedIn) { return of (true) }
  //   return this.http.put<any>(`${environment.base_url}/personal/modules/${this._userSubject.value.id}/${moduleId}`, null);
  // }

}
