import { environment } from 'src/environments/environment';
import { RxStompService } from './rx-stomp.service';

import { RxStompConfig } from '@stomp/rx-stomp';

export const myRxStompConfig: RxStompConfig = {
  // Which server?
  brokerURL: environment.ws_url, 

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: '',
    passcode: '',
    Authorization: "Bearer " + localStorage.getItem(environment.credential.idToken)
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 200,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: any): void => {
    if(environment.ws_debug) {
      
    }
  },
};

export function rxStompServiceFactory() {
  const rxStomp = new RxStompService();
  rxStomp.configure(myRxStompConfig);
  if (!environment.offline) rxStomp.activate();
  return rxStomp;
}