import { Component, Input, OnInit } from '@angular/core';
import { DiplomaItem } from '../../interfaces/personal.interface';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { Observable, map } from 'rxjs';
import { PersonalService } from '../../services/personal/personal.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewDiplomaComponent } from '../view-diploma/view-diploma.component';
import { DialogViewDiploma } from '../../interfaces/document-dialog.interface';
import { options } from '../../../../app.module';
import { TypeAssitance } from 'src/app/main/training/interfaces/constants';

@Component({
  selector: 'app-diplomas',
  templateUrl: './diplomas.component.html',
  styleUrls: ['./diplomas.component.scss']
})
export class DiplomasComponent implements OnInit {

  columns: TableColumn<DiplomaItem>[] = [
    { label: 'Capacitación', property: 'event', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Modalidad', property: 'modality', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Cobertura', property: 'type', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Fecha Inicial', property: 'initDate', type: 'datetime', visible: true, cssClasses: ['text-secondary'] },
    { label: 'Fecha Final', property: 'endDate', type: 'datetime', visible: true, cssClasses: ['text-secondary'] },
    { label: 'Asistencia', property: 'assistanceTypeId', type: 'assistanceType', visible: true, cssClasses: ['font-medium'] },
    // { label: 'HORAS', property: 'time', type: 'time', visible: true, cssClasses: ['text-secondary', 'w-10'] }, 
    // { label: '', property: 'menu', type: 'button', visible: true, cssClasses: ['text-secondary', 'w-10'] },
  ];

  @Input() id: number = 0;
  @Input() personal: string = 'personal';

  constructor(
    private service: PersonalService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  show(diploma: DiplomaItem) {
    // 
    const data: DialogViewDiploma = {
      diploma: {
        personalId: diploma.personalId,
        eventId: diploma.eventId,
        isExponent: diploma.assistanceTypeId === TypeAssitance.EXPONENT,
        diplomaName: this.personal + " " + diploma.event
      }
    }
    this.dialog.open(ViewDiplomaComponent, { data, disableClose: true })
  }

  get data(): Observable<DiplomaItem[]> {
    return this.service.getDiplomas(this.id).pipe(
      map(response => {
        
        return response.data;
      })
    )
  }

}
