import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonalService } from '../../services/personal/personal.service';
import { FormGroup } from '@angular/forms';
import { PersonalHistory, PersonalHistoryDialog, PersonalHistoryForm } from '../../interfaces/form.interface';
import { FormService } from '../../services/personal/form/form.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';

@Component({
  selector: 'vex-active-form',
  templateUrl: './active-form.component.html',
  styleUrls: ['./active-form.component.scss']
})
export class ActiveFormComponent implements OnInit {

  public form: FormGroup<PersonalHistoryForm> = null;
  public loading: boolean = false;

  constructor(
    private service: PersonalService,
    private formService: FormService,
    public dialogRef: MatDialogRef<ActiveFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PersonalHistoryDialog,
    private alert: AlertService,
    private socket: RxStompService
  ) { }

  ngOnInit(): void {
    this.form = this.formService.buildPersonalHistoryForm(this.data.personalId, this.data.email, 1, null, null);

    this.form.controls.reasonId.clearValidators();
    this.form.controls.rehire.clearValidators();

    this.service.getEmail(this.data.personalId).subscribe(response => {
      this.form.controls.email.setValue(response.data);
    })
  }

  onSubmit(): void {
    
    if (this.form.invalid) return;

    const data: PersonalHistory = {
      personalId: this.form.value.personalId,
      email: this.form.value.email,
      notes: this.form.value.notes,
      typeId: this.form.value.typeId,
      reasonId: 1,
      rehire: true,
      changeDate: this.form.value.changeDate,
    }

    this.loading = true;
    this.service.setStatus(data).subscribe({
      next: response => {
        this.loading = false;
        this.close();
        this.alert.showSuccess(response.message);
        this.socket.send(`/send/personal/changes/${this.data.personalId}`, response.data);
      },
      error: response => {
        this.loading = false;
        this.alert.showError(response.error.message);
      }
    })

  }

  close(): void {
    this.dialogRef.close();
  }

}
