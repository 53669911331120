import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DriveService } from 'src/app/shared/services/gapis/drive/drive.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

  url: SafeResourceUrl = null;
  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private drive: DriveService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.drive.downloadFile(this.route.snapshot.params["id"]).subscribe({
      next: data => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
        this.loading = false;
      },
      error: error => {
        this.loading = false;
      }
    })
  }

}
