import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { ProjectItem } from 'src/app/main/projects/interfaces/projects.interface';
import { PersonalService } from '../../services/personal/personal.service';

@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.scss']
})
export class MyProjectsComponent implements OnInit {

  @Input() id: number = 0;

  columns: TableColumn<ProjectItem>[] = [
    { label: 'Clave', property: 'idKey', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Razón Social', property: 'companyName', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'RFC', property: 'rfc', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Nombre', property: 'name', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Servicio', property: 'service', type: 'text', visible: true, cssClasses: ["text-light"] },
  ];

  // private dataSubject: BehaviorSubject<ProjectItem[]> = new BehaviorSubject<ProjectItem[]>([]);
  // public data: Observable<ProjectItem[]> = this.dataSubject.asObservable();

  constructor(
    private service: PersonalService,
  ) { }

  ngOnInit(): void {
    this.service.getProjects(this.id).subscribe(projects => {
      
    });
  }

  get data(): Observable<ProjectItem[]> {
    return this.service.getProjects(this.id).pipe(
      map(response => response.data)
    )
  }
}
