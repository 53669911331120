import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Catalogs } from '../../constants/catalogs';
import { Observable } from 'rxjs';
import { Catalog } from 'src/app/shared/interfaces/catalogs.interface';
import { HttpClient } from '@angular/common/http';
import { ListCatalogs } from '../../interfaces/personal.interface';
import { HttpResponse } from 'src/app/shared/interfaces/http-response.interface';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  private _baseUrl: string = environment.base_url;

  constructor(private http: HttpClient) { }

  getCatalog(catalog: Catalogs, officesRelated: string): Observable<HttpResponse<Catalog[]>> {
  return this.http.get<HttpResponse<Catalog[]>>(`${this._baseUrl}/personal/catalogs/${catalog}`, { headers: { offices: officesRelated } });
  }

  getCatalogs(officesRelated: string): Observable<HttpResponse<ListCatalogs>> {
    return this.http.get<HttpResponse<ListCatalogs>>(`${this._baseUrl}/personal/catalogs`, { headers: { offices: officesRelated } });
  }

}
