import { Catalog } from "src/app/shared/interfaces/catalogs.interface";

export interface TimeTrackingItem {
    id: number;
    // rfc: string;
    // companyName: string;
    // idKey: string;
    // stage: string;
    // activity: string;
    trackingResume: string;
    hours: number;
    notes: string;
}

export interface CalendarItem {
  initDate: string;
  endDate: string;
  status: "valid" | "invalid" | "incidence" | "incidence-overload" | "incidence-valid" | "overload" | "spent";
}

export interface Item {
  id: number;
  type: number;
  activity: string;
  project: string;
  value: string | number;
  initDate: string;
  endDate: string;
  notes: string;
  counterActive: boolean;
}

export interface ActivityItem {
  id: number;
  company: string;
  project: string;
  activity: string;
  time: number;
  notes: string;
  initDate: string;
  endDate: string;
  counterActive: boolean;
}

export interface Activity {
  id: number;
  personalId: number;
  projectId: number;
  activityId: number;
  time: number;
  notes: string;
  date: string;
  
  counterEnabled?: boolean;
}

export interface IncidenceItem {
  id: number;
  company: string;
  project: string;
  activity: string
  time: number;
  notes: string;
  date: string;
}


export interface Incidence {
  id: number;
  personalId: number;
  typeId: number;
  notes: string;
  initDate: string;
  endDate: string;
}

export interface SpentItem {
  id: number;
  company: string;
  project: string;
  type: string;
  file: string;
  fileName: string;
  amount: number;
  notes: string;
  date: string;
  status: string;
}

export interface Spent {
  id: number;
  personalId: number;
  projectId: number;
  typeId: number;
  amount: number;
  file: string | File;
  fileName: string;
  notes: string;
  date: string;
  statusId: number;
}

export interface Reservation {
  id: number;
  personalId: number;
  placeId: number;
  roomId?: number;
  edificeId?: number;
  initDate: string;
  endDate: string;
  notes: string;
}

export interface ReservationItem {
  id: number;
  personalId: number;
  placeId: number;
  place: string;
  type: number;
  initDate: string;
  endDate: string;
  notes: string;
  roomId: number
  personal: string
}

export interface ListCatalogs {
  project: CatProject[];
  spent: Catalog[];
  activity: CatActivity[];
  incidence: Catalog[];
}

export interface CatActivity extends Catalog {
  // id: number;
  // value: string;
  phaseId: number;
  phase: string;
  serviceId: number;
  // enabled: boolean;
}

export interface CatProject {
  id: number;
  value: string;
  serviceId: number;
  idKey: string;
  office: string;
  enabled: boolean;
}

export const initCatalogs: ListCatalogs = {
  project: [],
  spent: [],
  activity: [],
  incidence: [],
}


export interface TimeTrackingData {
  body: any;
  type: 0 | 1;
  list: 0 | 1 | 2 | 3;
}