import { FormGroup } from "@angular/forms";
import { Catalog } from "src/app/shared/interfaces/catalogs.interface";
import { FileItem } from "src/app/shared/interfaces/file.interface";

export interface Personal {
     id: number;
     name: string;
     lastName: string;
     email: string;
     office: string;
     role: string;
     enabled: boolean;
}

export interface ListPersonal {
    active: Personal[];
    inactive: Personal[];
}

export interface ListCatalogs {
    area: Catalog[];
    career: Career[];
    education: Catalog[];
    gender: Catalog[];
    personalType: Catalog[];
    role: Role[];
    workDay: Catalog[];
    personal: Catalog[];
    office: Catalog[];
}

export interface Career extends Catalog {
    code: string;
}

export interface Role extends Catalog {
    area: Catalog;
}


export interface FormPersonal {
    documents: FileItem[];
    sender: string;
    firstStep: {
        id: number;
        name: string;
        lastName: string;
        rfc: string;
        curp: string;
        nss: string;
        birthDate: string;
        entryDate: string;
        birthLocation: string;
        gender: Catalog;
        enabled: boolean;
    },
    secondStep: {
        personalType: Catalog;
        education: Catalog;
        career: Career;
        parent: Catalog;
        workDay: Catalog;
        office: Catalog;
        area: Catalog;
        role: Role;
        certification: boolean;
        certificate: string;
        certificateExpiry: string;
        hourCost: number;
        hourPrice: number;
        internalId: string;
    },
    thirdStep: {
        email: string;
        phone: string;
        phoneOffice: string;
        address: string;
    },
    summaryStep: {
        allergies: string;
        hobbies: string;
        family: PersonalFamily[]
    }
}

export interface FormGroupSteps {
    firstStep: FormGroup;
    secondStep: FormGroup;
    thirdStep: FormGroup;
    summaryStep: FormGroup;
}

export interface PersonalFamily {
    id?: number;
    typeId: number;
    typeName: string;   
    // type: Catalog;
    name: string;
    phone: string;
    age: number;
    personalId: number;
}

export const initForm: FormPersonal = {
    sender: "",
    firstStep: { 
        id: 0, name: "", lastName: "", rfc: "", curp: "",
        nss: "", birthDate: "", entryDate: "",
        birthLocation: "", gender: null, enabled: true
    },
    secondStep: {
        personalType: null, education: null, career: null, parent: null,
        workDay: null, office: null, area: null,
        role: null, certification: false, certificate: "",
        certificateExpiry: "", hourCost: null,
        hourPrice: null, internalId: "",
    },
    thirdStep: {
        email: "", phone: "", phoneOffice: "", address: "",
    },
    summaryStep: {
        allergies: "",
        hobbies: "",
        family: [],
    },
    documents: []
}

export const initCatalogs: ListCatalogs = { area: [], career: [], education: [], gender: [], personalType: [], role: [], workDay: [], personal: [], office: [] };

export interface DiplomaItem {
    eventId: number;
    personalId: number;
    assistanceTypeId: number;
    assistanceType: string;
    event: string;
    modality: string;
    type: string;
    duration: number;
    invoice: string;
    initDate: string;
    endDate: string;
    dpc: boolean;
}

export interface Version {
    id: number;
    version: string;
    notes: string;
}