import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import * as XLSX from 'xlsx';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { dpcPoint, formatCurrency, formatDate, formatTime, formatTimeString } from 'src/app/shared/util/helpers';
import { DIR_TEXT } from 'src/app/main/training/interfaces/constants';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  export(data: any[], columns: TableColumn<any>[], name: string, user: string): void {
    const fileName = `Reporte ${name} - ${new Date().toISOString()}`;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.book_new();

    const header = [[ `${name} - Reporte generado por ${user} el día: ${DateTime.local().toFormat('d \'de\' LLLL \'del\' yyyy')}` ]];

    XLSX.utils.sheet_add_aoa(ws, header);
    XLSX.utils.sheet_add_aoa(ws, [columns.map(c => c.label === "Acciones" ? "" : c.label)], {origin: 'A5'});

    let totalCells = 6;
    for(let i = 0; i < data.length; i++) {
      XLSX.utils.sheet_add_aoa(ws, [columns.map(c => this.format(data[i], c))], {origin: 'A' + totalCells});
      totalCells++;
    }

    XLSX.utils.book_append_sheet(wb, ws, "Datos");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

  }

  format(data: any, c: TableColumn<any>) {
    if (c.type === "time") {
      // return formatTime(data[c.property]);
      const value = data[c.property];
      return typeof value === "number" ? formatTime(value as number) : formatTimeString(value as string);
    } else if (c.type === "currency") {
      return formatCurrency(data[c.property]);
    } else if (c.type === "date") {
      return formatDate(data[c.property], "d 'de' MMMM 'de' yyyy", false);
    } else if (c.type === "datetime") {
      return formatDate(data[c.property], "d 'de' MMMM 'de' yyyy");
    } else if (c.type === "boolean") {
      return data[c.property] ? "Sí" : "No";
    } else if (c.type === "format_hour") {
      return formatDate(data[c.property], "");
    } else if (c.type === "res_type") {
      return data[c.property] === 2 ? "Estacionamiento" : "Lugar";
    } else if (c.type === "assistanceType") {
      return DIR_TEXT[data[c.property]];
    } else if (c.type === "event_score") {
      return [1, 5].includes(data.assistanceTypeId) ? data[c.property] + "/10" : "N/A";
    } else if (c.type === "event_survey") {
      return ![1, 5].includes(data.assistanceTypeId) ? '' : data[c.property] ? 'Sí' : 'No';
    } else if (c.type === "activity_value") {
      if (data.type === 0) {
        return formatTime(parseInt(data[c.property]));
      } else if (data.type === 2) {
        return formatCurrency(parseInt(data[c.property]));
      }
    } else if (c.type === "point") {
      return dpcPoint(data[c.property]);
    }

    return data[c.property];
  }
}
