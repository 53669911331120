import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { PersonalService } from '../../services/personal/personal.service';
import { Observable, map } from 'rxjs';
import { PersonalIncidence } from 'src/app/main/projects/interfaces/projects.interface';

@Component({
  selector: 'app-incidences',
  templateUrl: './incidences.component.html',
  styleUrls: ['./incidences.component.scss']
})
export class IncidencesComponent implements OnInit {

  @Input() id: number = 0;

  columns: TableColumn<PersonalIncidence>[] = [
    { label: 'Incidencia', property: 'incidence', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Fecha Inicial', property: 'initDate', type: 'date', visible: true, cssClasses: ['text-secondary'] },
    { label: 'Fecha Final', property: 'endDate', type: 'date', visible: true, cssClasses: ['text-secondary'] },
    // { label: 'HORAS', property: 'time', type: 'time', visible: true, cssClasses: ['text-secondary', 'w-10'] }, 
    // { label: '', property: 'menu', type: 'button', visible: true, cssClasses: ['text-secondary', 'w-10'] },
  ];

  

  constructor(
    private service: PersonalService
  ) { }

  ngOnInit(): void {
  }

  get data(): Observable<PersonalIncidence[]> {
    return this.service.getIncidences(this.id).pipe(
      map(response => {
        
        return response.data;
      })
    )
  }

}
