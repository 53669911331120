import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProgressBarModule } from '../components/progress-bar/progress-bar.module';
import { SearchModule } from '../components/search/search.module';
import { PageLayoutModule } from '../components/page-layout/page-layout.module';
import { BreadcrumbsModule } from '../components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from '../components/secondary-toolbar/secondary-toolbar.module';
import { MaterialModule } from 'src/app/shared/material.module';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';


@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    ProgressBarModule,
    SearchModule,
    PageLayoutModule,
    BreadcrumbsModule,
    SecondaryToolbarModule,
    MaterialModule,
    TourMatMenuModule.forRoot()
  ],
  exports: [LayoutComponent]
})
export class LayoutModule {
}
