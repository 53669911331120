import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import { UsernamePipe } from './pipes/username/username.pipe';
import { TableComponent } from './components/table/table.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MY_DATE_FORMATS, MaterialModule } from './material.module';
import { InputTextComponent } from './components/input-text/input-text.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { NgxMaskModule } from 'ngx-mask';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputPhoneComponent } from './components/input-phone/input-phone.component';
import { InputTextAreaComponent } from './components/input-text-area/input-text-area.component';
import { InputBooleanComponent } from './components/input-boolean/input-boolean.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BtnBackComponent } from './components/btn-back/btn-back.component';
import { FormBtnsComponent } from './components/form-btns/form-btns.component';
import { RouterModule } from '@angular/router';
import { DatePipe } from './pipes/date/date.pipe';
import { InputFileComponent } from './components/input-file/input-file.component';
import { ViewFileComponent } from './components/view-file/view-file.component';
import { DisableAutoSelectDirective } from './directives/disable-auto-select.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { InputSelectGroupComponent } from './components/input-select-group/input-select-group.component';
import { InputDateRangeComponent } from './components/input-date-range/input-date-range.component';
import { TimePipe } from './pipes/time/time.pipe';
import { CronPipe } from './pipes/cron.pipe';
import { InputTimeComponent } from './components/input-time/input-time.component';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { IconAssistanceComponent } from './components/icon-assistance/icon-assistance.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InputColorComponent } from './components/input-color/input-color.component';
import { InputMonthComponent } from './components/input-month/input-month.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { PhoneMaskPipe } from './pipes/phone-mask.pipe';

const arr = [
  UsernamePipe, 
  DatePipe,
  TimePipe,
  CronPipe,
  OrderByPipe,
  PhoneMaskPipe,
  TableComponent, 
  InputTextComponent, 
  InputSelectComponent, 
  InputSelectGroupComponent, 
  InputTimeComponent,
  InputDateComponent, 
  InputDateRangeComponent, 
  InputPhoneComponent, 
  InputTextAreaComponent,
  InputBooleanComponent,
  InputNumberComponent,
  InputColorComponent,
  InputMonthComponent,
  InputFileComponent,
  LoadingComponent,
  BtnBackComponent,
  FormBtnsComponent,
  ViewFileComponent,
  IconAssistanceComponent,
  DisableAutoSelectDirective,
  AutoFocusDirective
]

@NgModule({
  declarations: arr,
  exports: arr,
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    RouterModule,
    NgxMatTimepickerModule.setLocale("es-Es"),
    ScrollingModule,
    TourMatMenuModule.forRoot(),
  ],
})
export class SharedModule { }
