<div class="page">
    <div class="file-container">
        <iframe *ngIf="url" [src]="url" width="100%" height="100%"></iframe>
        <app-loading [loading]="loading"></app-loading>
    </div>
</div>


<!-- <div> -->
    
        <!-- <div class="flex justify-between items-center"> -->
            <!-- <h1 mat-dialog-title class="m-0 flex items-center">  -->
                <!-- <span>Archivo:</span>  -->
                <!-- <span class="text-file">{{ file.name }}</span>  -->
                <!-- <mat-icon class="not-uploaded" *ngIf="file?.notUploaded"> cloud_off </mat-icon>  -->
            <!-- </h1> -->
        <!-- </div> -->
        <!-- <div mat-dialog-content> -->
            
            
        <!-- </div> -->
        <!-- <div mat-dialog-actions align="end" class="px-3"> -->
            <!-- <a [href]="url" mat-raised-button color="primary">Descargar</a> -->
        <!-- </div> -->
    <!-- </div> -->
