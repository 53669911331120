import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, debounceTime, map, of, switchMap, timer } from 'rxjs';
import { FormService } from 'src/app/main/personal/services/personal/form/form.service';
import { PersonalService } from 'src/app/main/personal/services/personal/personal.service';
import { CalendarItem } from 'src/app/main/time-tracking/interfaces/time-tracking.interface';
import { buildDateTime, buildSeconds } from '../../util/helpers';
import { FormEvent } from 'src/app/main/training/interfaces/form.interface';

export const EMAIL_REGEX = /^[a-z]+\.[a-z]+@crowe.mx$/;

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  

  constructor() { }

  isValidField(control: FormControl): boolean | null {
    return control?.valid || !control?.touched;
  }

  getFieldError(control: FormControl, field: string): string | null {
    if (control?.valid) return null;

    const errors = control.errors || {}

    for (const key of Object.keys(errors)) {
      switch (key) {
        case 'required':
          return 'Este campo es requerido';
        case 'minlength':
          return `Minimo ${errors['minlength'].requiredLength} de caracteres`;
        case 'min':
          return `El valor debe ser mayor a ${errors['min'].min}`;
        case 'validateHours':
          return "La hora de inicio debe ser menor a la hora final.";
        case 'emailTaken':
          return "El correo ya está en uso.";
        case 'invalidEmail':
          return "El correo no es válido";
        case 'pattern':
          switch (field) {
            case 'password':
              return 'La contraseña es muy débil.';
            case 'email':
              return "El campo debe ser un email.";
            case 'rfc':
              return 'El RFC no es válido.';
            case 'curp':
              return "La CURP no es válida.";
            case 'nss':
              return "El NSS no es válido."
          }
          return "El campo no es válido."
        case "datesOverlap":
          return "Ya existe una incidencia registrada dentro de estas fechas.";
        case "invalidHours":
          return "La hora final debe ser mayor a la hora inicial.";
        case "invalidDateTime":
          return "La fecha inicial debe ser anterior a la fecha final.";
        case "invalidDuration":
          return "La duración excede el tiempo entre las fechas de inicio y fin"
        case "minLengthArray": 
          return "Se debe agregar al menos un expositor";
        case "minDate":
          return "La hora 1 ingresada está fuera del horario del evento";
        case "maxDate":
          return "La hora 2 ingresada está fuera del horario del evento";
        case "invalidRegisterLimitDate":
          return "El campo debe ser menor o igual a la fecha de inicio del evento.";
        case "hoursExceeded":
          return "El valor ingresado excede las horas del proyecto";
        case "hoursNotzERO":
          return "El valor debe ser mayor a 0";
        default:
          return "El campo no es válido."
      }
    }

    return null;
  }

  isRequired(control: FormControl): boolean {
    if (!control.validator) return false;
    if (!control.validator?.({} as any)) return false;
    if (!control.validator?.({} as any).required) return false;
    return true;
  }

  passwordMatchValidator(g: FormGroup) {
    const password = g.get('password');
    const confirmPassword = g.get('confirmPassword');
    if (!password || !confirmPassword) return;
    return password.value === confirmPassword.value ? null : { 'password_mismatch': true };
  }

  static emailExists(service: FormService, currentMail: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      
      if (control.value === currentMail && currentMail.length > 0) {
        return of(null);
      }

      return timer(500).pipe(
        switchMap(() => {
          return service.isValidEmail(control.value)
            .pipe(
              map(resp => {
                return resp.data ? null : { emailTaken: true };
              })
            );
        })
      );
    };
  }

  static validateEmail(control: FormControl) {
    const email = control.value;
    if(!email) return;
    return EMAIL_REGEX.test(email) ? null : { 'invalidEmail': true };
  }  

  static dateOverlap(g: FormGroup, list: CalendarItem[], range: Date[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!g) return null;

        const initDateControl = g.get("initDate");
        const endDateControl = g.get("endDate");

        if (!initDateControl || !endDateControl) return null;

        const initDate = new Date(initDateControl?.value);
        const endDate = new Date(endDateControl?.value);

        const overlap = list.some(date => {
            const dateInit = new Date(date.initDate);
            const dateEnd = new Date(date.endDate);
        
            // Verificar si los rangos se traslapan
            const dateOverlaps = (dateInit <= endDate && dateEnd >= initDate) ||
                                (initDate <= dateEnd && endDate >= dateInit);

            if (!dateOverlaps) return false;

            // Verificar si el estado es "incidence"
            const statusIsIncidence = date.status.includes("incidence");

            if (!statusIsIncidence) return false;

            if (!range[0] || !range[1]) return dateOverlaps && statusIsIncidence;

            const rangeOverlaps = (initDate <= range[1] && endDate >= range[0]) ||
                                  (range[0] <= endDate && range[1] >= initDate);
            
            // Devolver true si hay traslape y el estado es "incidence"
            return dateOverlaps && statusIsIncidence && !rangeOverlaps;
        });

        if (!overlap) {
          initDateControl.setErrors(null);
          endDateControl.setErrors(null);
        } else {
          initDateControl.setErrors({ datesOverlap: true });
          endDateControl.setErrors({ datesOverlap: true });
        }

        return overlap ? { datesOverlap: true } : null;
    };
  }

  static validHours(g: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (!g) return null;

      const initHour = g.get("initHour");
      const endHour = g.get("endHour");

      if (!initHour?.value || !endHour?.value) return null;

      const timeInit = buildSeconds(initHour?.value);
      const timeEnd = buildSeconds(endHour?.value);

      if (!(timeInit >= timeEnd)) {
        initHour.setErrors(null);
        endHour.setErrors(null);
      } else {
        initHour.setErrors( { invalidHours: true } );
        endHour.setErrors( { invalidHours: true } );
      }

      return timeInit >= timeEnd ? { invalidHours: true } : null

    }
  }

  static validDateTimes(g: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (!g) return null;

      const initHourControl = g.get("initHour");
      const endHourControl = g.get("endHour");
      const initDateControl = g.get("initDate");
      const endDateControl = g.get("endDate");

      if (!initHourControl?.value || !endHourControl?.value || !initDateControl?.value || !endDateControl?.value) return null;

      const initDate: Date = buildDateTime(initDateControl.value, initHourControl.value);
      const endDate: Date = buildDateTime(endDateControl.value, endHourControl.value);

      if (!(initDate >= endDate)) {
        initHourControl.setErrors(null);
        endHourControl.setErrors(null);
      } else {
        initHourControl.setErrors( { invalidDateTime: true } );
        endHourControl.setErrors( { invalidDateTime: true } );
      }

      return initDate >= endDate ? { invalidDateTime: true } : null

    }
  }
  

  static validTimeBetweenDates(g: FormGroup, controlNames: string[] = ['initDate', 'initHour', 'endDate', 'endHour']): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (!g) return null;

      const initDateControl = g.get(controlNames[0]);
      const initHourControl = g.get(controlNames[1]);
      const endDateControl = g.get(controlNames[2]);
      const endHourControl = g.get(controlNames[3]);
      const durationControl = g.get("duration");

      if (!initHourControl?.value || !endHourControl?.value || 
          !initDateControl?.value || !endDateControl?.value || !durationControl?.value) return null;

      const initDate: Date = buildDateTime(initDateControl.value, initHourControl.value);
      const endDate: Date = buildDateTime(endDateControl.value, endHourControl.value);

      const duration = buildSeconds(durationControl.value);
      const limit = Math.floor((endDate.getTime() - initDate.getTime()) / 1000);

      durationControl.setErrors(duration > limit ? { invalidDuration: true } : null);

      return null;

    }
  }

  static validMinMaxDates(
    g: FormGroup, 
    controlNames: string[] = ['initDate', 'initHour', 'endDate', 'endHour'],
    f: FormGroup<FormEvent>,
    controlNamesF: string[] = ['initDate', 'initHour', 'endDate', 'endHour'],
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (!g) return null;

      if (!f) return null;

      const initDateControl = g.get(controlNames[0]);
      const initHourControl = g.get(controlNames[1]);
      const endDateControl = g.get(controlNames[2]);
      const endHourControl = g.get(controlNames[3]);

      const initDateControlF = f.get(controlNamesF[0]);
      const initHourControlF = f.get(controlNamesF[1]);
      const endDateControlF = f.get(controlNamesF[2]);
      const endHourControlF = f.get(controlNamesF[3]);

      if (!initHourControl?.value || !endHourControl?.value || 
          !initDateControl?.value || !endDateControl?.value ||
          !initHourControlF?.value || !endHourControlF?.value || 
          !initDateControlF?.value || !endDateControlF?.value) return null;
          

      const initDate: Date = buildDateTime(initDateControl.value, initHourControl.value);
      const endDate: Date = buildDateTime(endDateControl.value, endHourControl.value);
      const initDateF: Date = buildDateTime(initDateControlF.value, initHourControlF.value);
      const endDateF: Date = buildDateTime(endDateControlF.value, endHourControlF.value);
      
      initHourControl.setErrors( initDate < initDateF ? { minDate: true } : null);
      endHourControl.setErrors( endDate > endDateF ? { maxDate: true } : null);

      return null;

    }
  }

  static minLengthArray(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const arr = control;

      if (!arr || !arr?.value) return null;

      return arr.value?.length < 1 ? { minLengthArray: true } : null

    }
  }

}
