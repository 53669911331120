// inactivity.service.ts
import { Injectable, NgZone, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private inactivityTimeout: any;
  private readonly inactivityTimeLimit = 600000; // Tiempo de inactividad en milisegundos (10 minuto)
  public inactivityDetected = new EventEmitter<void>(); // Evento para notificar inactividad

  constructor(private ngZone: NgZone) {}

  public initInactivityListener() {
    this.resetInactivityTimer();
    
    // Detectar actividad del usuario
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('mousemove', () => this.resetInactivityTimer());
      window.addEventListener('keydown', () => this.resetInactivityTimer());
    });
  }

  private resetInactivityTimer() {
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = setTimeout(() => this.handleInactivity(), this.inactivityTimeLimit);
  }

  private handleInactivity() {
    console.log("User has been inactive for the set time limit, logging out...");
    this.inactivityDetected.emit(); // Emite el evento en lugar de cerrar sesión directamente
  }
}
