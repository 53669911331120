<mat-form-field class="mb-3">
    <mat-label> {{ label }} </mat-label>
    <mat-date-range-input [formGroup]="form" [rangePicker]="dateRangePicker">
        <input matStartDate placeholder="Start date" [formControl]="_control_start" (click)="dateRangePicker.open()">
        <input matEndDate placeholder="End date" [formControl]="_control_end" (click)="dateRangePicker.open()">
    </mat-date-range-input> 
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
    <!-- <mat-date-range-picker #dateRangePicker></mat-date-range-picker> -->
    <mat-date-range-picker #dateRangePicker>
        <mat-date-range-picker-actions>
          <button mat-button matDateRangePickerCancel>Cancelar</button>
          <button mat-raised-button color="primary" matDateRangePickerApply>Aplicar</button>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>
    <mat-error> {{ getFieldError() }} </mat-error>
</mat-form-field>