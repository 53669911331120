import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { AuthGuard } from '../shared/services/guard/auth.guard';
import { FileComponent } from './components/file/file.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: '',
        data: { label: "General" },
        children: [
          {
            path: "",
            data: { label: "Dashboard" },
            loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
            canActivate: [AuthGuard],
          },
          {
            path: "configuracion",
            data: { label: "Configuración" },
            loadChildren: () => import("./configuration/configuration.module").then(m => m.ConfigurationModule),
            canActivate: [AuthGuard],
          }
        ],
      },
      {
        path: 'capital-humano',
        data: { label: "Capital Humano" },
        children: [
          {
            path: 'personal',
            data: { label: "Personal" },
            loadChildren: () => import("./personal/personal.module").then(m => m.PersonalModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'vacantes',
            data: { label: "Vacantes" },
            loadChildren: () => import("./careers/careers.module").then(m => m.CareersModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'marketing',
            data: { label: "Marketing" },
            loadChildren: () => import("./marketing/marketing.module").then(m => m.MarketingModule),
            canActivate: [AuthGuard],
          },
          {
            path: "enlace-crowe",
            data: { label: "Enlace Crowe" },
            loadChildren: ()  => import("./crowe-link/crowe-link.module").then(m => m.CroweLinkModule),
            canActivate: [AuthGuard],
          },
          {
            path: "capacitacion",
            data: { label: "Capacitación" },
            loadChildren: () => import("./training/training.module").then(m => m.TrainingModule),
            canActivate: [AuthGuard],
          },
          {
            path: "reservaciones",
            data: { label: "Reservaciones" },
            loadChildren: () => import("./reservations/reservations.module").then(m => m.ReservationsModule),
            canActivate: [AuthGuard],
          },
          {
            path: "equipo-computo",
            data: { label: "Equipos de Cómputo" },
            loadChildren: () => import("./computers/computers.module").then(m => m.ComputersModule),
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'crm',
        data: { label: "CRM" },
        children: [
          {
            path: 'oportunidades',
            data: { label: "Oportunidades" },
            loadChildren: () => import("./opportunities/opportunities.module").then(m => m.OpportunitiesModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'prospectos',
            data: { label: "Prospectos" },
            loadChildren: () => import("./prospects/prospects.module").then(m => m.ProspectsModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'proyectos', 
            data: { label: "Proyectos" },
            loadChildren: () => import("./projects/projects.module").then(m => m.ProjectsModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'clientes',
            data: { label: "Clientes" },
            loadChildren: () => import("./clients/clients.module").then(m => m.ClientsModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'gastos',
            data: { label: "Gastos" },
            loadChildren: () => import("./bills/bills.module").then(m => m.BillsModule),
            canActivate: [AuthGuard],
          }
        ],
      },
      {
        path: "mi-sgi",
        data: { label: "Mi SGI" },
        children: [
          {
            path: "control-tiempos",
            data: { label: "Control de Tiempos" },
            loadChildren: () => import("./time-tracking/time-tracking.module").then(m => m.TimeTrackingModule),
            canActivate: [AuthGuard],
          },
          {
            path: "reportes",
            data: { label: "Reportes" },
            loadChildren: () => import("./reports/reports.module").then(m => m.ReportsModule),
            canActivate: [AuthGuard],
          },
          {
            path: "perfil",
            data: { label: "Perfil" },
            loadChildren: () => import("./profile/profile.module").then(m => m.ProfileModule),
            canActivate: [AuthGuard],
          }
        ],
      },
      {
        path: "file/:id",
        data: { label: "Archivo" },
        component: FileComponent,
        canActivate: [AuthGuard],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
