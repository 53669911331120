<mat-form-field *ngIf="required || showDisabled">
    <mat-label>{{ label }}</mat-label>

    <mat-select [formControl]="_control" [required]="required">
        <mat-option [value]="true"> {{ trueText }} </mat-option>
        <mat-option [value]="false"> {{ falseText }} </mat-option>
    </mat-select>

    <mat-error> {{ getFieldError() }} </mat-error>
</mat-form-field>


