import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Personal, PersonalFamily } from '../../interfaces/personal.interface';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PersonalService } from '../../services/personal/personal.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { FamilyFormComponent } from '../family-form/family-form.component';
import { Catalog } from 'src/app/shared/interfaces/catalogs.interface';
import { Subscription } from 'rxjs';
import { FamilyFormDialog } from '../../interfaces/family-dialog.interface';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { User } from 'src/app/shared/interfaces/user.interface';
import { UserService } from 'src/app/shared/services/user/user.service';
import { HttpResponse } from 'src/app/shared/interfaces/http-response.interface';
import { decrypt } from 'src/app/shared/util/helpers';

@Component({
  selector: 'app-personal-summary',
  templateUrl: './personal-summary.component.html',
  styleUrls: ['./personal-summary.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class PersonalSummaryComponent implements OnInit, OnDestroy, OnChanges {

  // @Output() sendFamily: EventEmitter<PersonalFamily[]> = new EventEmitter();
  @Input() form!: FormGroup;
  public loading: boolean = false;
  public data: PersonalFamily[] = [];
  public types: Catalog[] = []
  public isEditing = false;
  public id = 0;
  public subscriptions$: Subscription[] = [];
  private user!: User;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private service: PersonalService,
    private socket: RxStompService,
    private alert: AlertService,
    private userService: UserService
  ) { }
  
  ngOnDestroy(): void {
    this.subscriptions$.map(s => {
      s.unsubscribe();
      s.remove(s);
    })
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.form?.currentValue) {
      this.form = changes.form.currentValue;
    }
  }

  ngOnInit(): void {

    const user$ = this.userService.user$.subscribe(user => {
      this.user = user;
    })

    this.subscriptions$.push(user$);

    this.loadTypes();

    this.id = parseInt(decrypt(this.activatedRoute.snapshot.paramMap.get('id')));
    
    if (isNaN(this.id) || this.id < 1) {
      this.id = 0;
      return;
    }

    this.loadFamily();

    this.isEditing = true;
  }

  submit() {
    this.form.get("family").setValue(this.data);
  }

  save() {
    
    this.form.markAllAsTouched();

    if (this.form.invalid) { return; }

    const sender = this.user.email;
    const form = {
      summaryStep: this.form.value,
      sender
    }
    

    this.socket.send(`/send/save/${this.id}/4`, form);
  }

  add() {
    const data: FamilyFormDialog = {
      types: this.types,
      isEditing: this.isEditing,
      personalId: this.id
    }
    const dialogRef = this.dialog.open(FamilyFormComponent, { data,  disableClose: true });

    const add$ = dialogRef.afterClosed().subscribe((result: FamilyFormDialog) => {
      if (!result) return;

      if (!this.isEditing) {
        this.data.push(result.item);
      }

    });

    this.subscriptions$.push(add$);
  }

  edit(item: PersonalFamily) {

    const index = this.data.indexOf(item);

    const data: FamilyFormDialog = {
      types: this.types,
      isEditing: this.isEditing,
      personalId: this.id,
      item,
    }
    const dialogRef = this.dialog.open(FamilyFormComponent, { data,  disableClose: true });

    const edit$ = dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      if (this.isEditing) {
        this.data[index] = result.item;
      }
    });

    this.subscriptions$.push(edit$);
  }

  delete(event: PersonalFamily) {
    
    const index = this.data.indexOf(event);

    if (index < 0) {
      return;
    }

    this.alert.showWarning(`Se eliminará el registro ${event.name}`, true).then(res => {
      if (res.isConfirmed) {
        if (!this.isEditing) {
          this.data = this.data.splice(index, 1);
          return;
        }
    
        this.service.deleteFamily(event).subscribe({
          next: response => {
            this.alert.showSuccess(response.message);
            this.data = this.data.splice(index, 1);
          },
          error: response => {
            this.alert.showError(response.error.message);
          }
        })
      }
    })

    

  }

  loadTypes() {
    this.loading = true;
    const types$ = this.service.getTypeFamily().subscribe({
      next: response  => {
        this.types = response.data;
      },
      complete: () => {
        this.loading = false;
      }
    })

    this.subscriptions$.push(types$);
  }

  loadFamily() {
    this.loading = true;
    const family$ = this.service.getFamily(this.id).subscribe({
      next: response => {
        this.data = response.data;
      },
      error: response => {

      },
      complete: () => {
        this.loading = false;
      }
    });

    this.subscriptions$.push(family$);
    this.subscribeToChanges(this.id);
  }

  get disabled() {
    return this.form.disabled;
  }

  subscribeToChanges(id: number) {
    const changes$ = this.socket.listen<HttpResponse<PersonalFamily[]>>(`/listen/personal_family/${id}`).subscribe(
      response => {

        if (!response.ok) {
          return;
        }


        this.data = response.data;
      }
    )

    this.subscriptions$.push(changes$);
  }

}
