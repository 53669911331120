import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';
import { User } from 'src/app/shared/interfaces/user.interface';
import { UserService } from 'src/app/shared/services/user/user.service';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { HelpTourService } from 'src/app/shared/services/help-tour/help-tour.service';
import { Router } from '@angular/router';
import { findMenuItemByRoute, getRoot } from 'src/app/shared/util/helpers';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));

  userMenuOpen$: Observable<boolean> = of(false);

  items = this.navigationService.items;

  user$: Observable<User | null> = null;

  // private steps: IStepOption[] = [
  //   {
  //     anchorId: 'tour.home.1.0',
  //     content: 'Haz click aquí para mostrar u ocultar el menú',
  //     title: 'Ocultar menú',
  //   },
  //   {
  //     anchorId: 'tour.home.1.1',
  //     content: 'Este es el menú',
  //     title: 'Menú',
  //   },
  //   {
  //     anchorId: 'tour.home.1.2',
  //     content: 'Haz click aquí para ver tu perfil o cerrar sesión',
  //     title: 'Mi Perfil',
  //   },
  //   {
  //     anchorId: 'tour.home.1.3',
  //     content: 'Haz click aquí para agregar registros de horas, incidecias o gastos',
  //     title: 'Acciones',
  //   },
  //   {
  //     anchorId: 'tour.home.1.4',
  //     content: 'Haz click aquí para ver tus eventos',
  //     title: 'Eventos',
  //   },
  //   {
  //     anchorId: 'tour.home.1.5',
  //     content: 'Aquí se mostrará la página actual',
  //     title: 'Contenido',
  //   },
  // ];

  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private readonly popoverService: PopoverService,
              private readonly dialog: MatDialog,
              // private tourService: TourService,
              // private helpService: HelpTourService,
              private router: Router,
              private userService: UserService) { }

  ngOnInit() {
    this.user$ = this.userService.user$;
    // this.tourService.initialize(this.steps, {
    //   enableBackdrop: true,
    //   backdropConfig: {
        
    //   },
    //   nextBtnTitle: "Siguiente",
    //   prevBtnTitle: "Regresar",
    //   endBtnTitle: "Siguiente",
    // });

    // this.user$.subscribe(user => {
    //   if (!user) return;
    //   const url = getRoot(this.router.url)
    //   const item = findMenuItemByRoute(user.menu, url);
    //   if (!item?.visited && url === "/") {
    //     this.tourService.start();
    //   }

    // });

    
    // this.helpService.tour.subscribe(value => {
    //   if (value === 0) {
    //     this.tourService.start();
    //   }
    // })

    // this.tourService.end$.subscribe(() => {
    //   this.helpService.nextTour(1);
    // })
    

  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
   
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          }
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    });
  }


  onErrorImage(event: any): void {
    event.target.src = "assets/img/illustrations/avatar.jpeg";
  }
}
