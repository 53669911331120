import { Injectable } from '@angular/core';
import { TableState } from '../../interfaces/table.interface';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  
  constructor(
    private storageService: StorageService,
  ) { }

  getState(key: string): TableState | null {
    const savedState = this.storageService.storage.getItem(key);
    return savedState ? JSON.parse(savedState) : null;
  }

  saveState(state: TableState, key: string): void {
    this.storageService.storage.setItem(key, JSON.stringify(state));
  }

}
