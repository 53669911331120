import { Injectable, OnDestroy } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

export type ListenerCallBack = (message: Task) => void;
export interface Payload { destination: string, body: any }

@Injectable({
  providedIn: 'root'
})
export class RxStompService extends RxStomp implements OnDestroy {
  
  storage = environment.storage;
  
  constructor() {
    super();
  }
  
  ngOnDestroy(): void {
    if(this.connected()) {
      this.deactivate();
    }
  }

  connect() {
    if (!environment.offline) {
      this.activate();
    }
  }

  send(destination: string, body: any = {}) {
    this.publish({ destination, body: JSON.stringify(body), headers: { 'Authorization': this.storage.getItem(environment.credential.idToken) } });
  }

  listen<T>(url: string): Observable<T> {
    return this.watch(url).pipe(
      map(
        response => JSON.parse(response.body).body

      )
    )
  }
}