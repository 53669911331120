import { FormControl } from "@angular/forms";
import { Catalog } from "src/app/shared/interfaces/catalogs.interface";
import { Activity, CatActivity, CatProject, Incidence, Reservation, Spent } from "./time-tracking.interface";
import { Edifice, Place, Room } from "./reservation.interface";

export interface ActivityForm {
    id: FormControl<number>
    project: FormControl<CatProject>
    activity: FormControl<CatActivity>
    hours: FormControl<string>
    notes: FormControl<string>
}

export const initActivityForm: Activity = {
    id: null,
    personalId: null,
    projectId: null,
    activityId: null,
    time: null,
    notes: null,
    date: null,
}

export interface IncidenceForm {
    id: FormControl<number>
    type: FormControl<Catalog>
    initDate: FormControl<Date>
    endDate: FormControl<Date>
    notes: FormControl<string>
}

export const initIncidenceForm: Incidence = {
    id: null,
    personalId: null,
    typeId: null,
    notes: null,
    initDate: null,
    endDate: null,
}

export interface SpentForm {
    id: FormControl<number>
    project: FormControl<CatProject>
    type: FormControl<Catalog>
    amount: FormControl<number>
    file: FormControl<File | string>
    notes: FormControl<string>   
}

export const initSpentForm: Spent = {
    id: null,
    personalId: null,
    projectId: null,
    typeId: null,
    amount: null,
    file: null,
    fileName: null,
    notes: null,
    date: null,
    statusId: null,
}

export interface AddReservationForm {
    id: FormControl<number>
    edifice: FormControl<Edifice>
    room: FormControl<Room>
    place: FormControl<Place>
    initHour: FormControl<string>
    endHour: FormControl<string>
    notes: FormControl<string>
}

const initDate = new Date();
const endDate = new Date();

initDate.setHours(9, 0, 0, 0);
endDate.setHours(10, 0, 0, 0);

export const initReservationForm: Reservation = {
    id: null,
    personalId: null,
    placeId: null,
    initDate: initDate.toISOString(),
    endDate: endDate.toISOString(),
    notes: null,
}