<mat-form-field *ngIf="required || showDisabled">
    <mat-label [attr.for]="field">{{ label }}</mat-label>
    <input [id]="field" matInput [placeholder]="placeholder" [formControl]="_control" [required]="required" [mask]="mask" [type]="type" [dropSpecialCharacters]="dropSpecialCharacters">
    
    <button *ngIf="loading" type="button" matSuffix mat-icon-button>
        <span class="loader"></span>
    </button>
    
    <mat-error *ngIf="showError"> {{ getFieldError() }} </mat-error>
    <mat-hint class="text-primary w-full text-right" *ngIf="emailValid" @fadeInUp> Email válido </mat-hint>
</mat-form-field>