import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(private readonly popoverRef: PopoverRef, private userService: UserService) { }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  logout(): void {
    this.popoverRef.close();
    this.userService.signOut();
  }
}
