import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CatalogsService } from '../../services/catalogs/catalogs.service';
import { FormGroupSteps, FormPersonal, ListCatalogs, Role, initCatalogs, initForm } from '../../interfaces/personal.interface';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { MASK_CURP, MASK_NSS, MASK_RFC } from 'src/app/shared/util/constants';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from 'src/app/shared/interfaces/http-response.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { UserService } from 'src/app/shared/services/user/user.service';
import { environment } from 'src/environments/environment';
import { FileItem } from '../../../../shared/interfaces/file.interface';
import { DriveService } from 'src/app/shared/services/gapis/drive/drive.service';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { ModuleStateService } from 'src/app/shared/services/module-state/module-state.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { FormService } from '../../services/personal/form/form.service';
import { decrypt } from 'src/app/shared/util/helpers';

@Component({
  selector: 'vex-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class FormPageComponent implements OnInit, OnDestroy {

  firstStep: FormGroup;
  secondStep: FormGroup;
  thirdStep: FormGroup;
  documents: FileItem[] = [];
  summaryStep: FormGroup;

  catalogs: ListCatalogs = initCatalogs;
  role: Role[] = [];

  isEditing = false;
  loading = false;
  submitLoading = false;
  isLinear: boolean = environment.production;

  orientation: "vertical" | "horizontal" = "vertical";

  display: string = "";

  user!: User;
  uploaded = false;

  public id: number = 0;
  private subscriptions$: Subscription[] = [];

  tab = 0;

  constructor(
    private catalogsService: CatalogsService,
    private alert: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private socket: RxStompService,
    private userService: UserService,
    private drive: DriveService,
    private stateService: ModuleStateService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions$.map( s => {
      s.unsubscribe();
      s.remove(s);
    } );
  }

  ngOnInit(): void {
    // Se cargan los datos del usuario
    const subscription$ = this.userService.user$.subscribe(user => {  
      if(!user) return;
      this.user = user; 
      this.responseFormSubscription(this.user.email);
      this.init();
    });
    this.subscriptions$.push(subscription$);

    // Se inicializa el componente
    
  }

  private init(): void {
    // Se construyen los formularios
    this.setForm(this.formService.buildForm(initForm));
    
    this.id = parseInt(decrypt(this.activatedRoute.snapshot.paramMap.get('id')));
    this.isEditing = !isNaN(this.id) && this.id > 0;
    
    // Se valida si se está editando un usuario
    if (this.isEditing) {
      this.orientation = "horizontal";
      this.isLinear = false;
      this.tab = this.stateService.getTab();
    } 
    const loadForm$ = this.formService.loadForm(this.id);
    const getCatalogs$ = this.catalogsService.getCatalogs(this.user.officesRelated);
    

    const arr = isNaN(this.id) ? [getCatalogs$] : [getCatalogs$, loadForm$];

    const combined$: Observable<any> = forkJoin(arr);
    
    // Se obtienen los datos de la API
    this.loading = true;
    const subscription$ = combined$.subscribe({
      next: ([catalogsResp, loadFormResp]) => {

        if (loadFormResp) {
          this.onLoadPersonal(loadFormResp);
        }
        
        this.catalogs = catalogsResp.data;
        this.role = catalogsResp.data.role;
      },
      error: error => {
        this.onLoadPersonalError(error);
      },
      complete: () => {
        this.loading = false;
      }
    });
    
    this.subscriptions$.push(subscription$);
  }

  // Si la API devuelve los datos correctamente se asignan al componente
  private onLoadPersonal(form: FormGroupSteps): void {
    this.display = `Personal: ${form.secondStep.value.career.code} ${form.firstStep.value.name} ${form.firstStep.value.lastName} - ${form.secondStep.value.office.value}`;
    this.setForm(form)

    const office = form.secondStep.value.office.value;
    const email = this.thirdStep.value.email;
    const enabled = this.firstStep.value.enabled;

    if (!this.isEditing) return;

    this.firstStep.disable();
    this.secondStep.disable();
    this.thirdStep.disable();
    this.summaryStep.disable();

    this.subscribeToChanges();

    if (!email) {
      if (enabled) this.alert.showWarning("El usuario actual no tiene correo electrónico");
      return;
    }

    this.drive.loadFolder(email, office, environment.idsGoogleDrive.capitalHumano).subscribe();
    // this.drive.loadPersonalFolder(email, office, environment.idsGoogleDrive.capitalHumano).subscribe();
  }

  // Si falla algo en el back nos muestra una alerta y redirige al inicio
  private onLoadPersonalError(response: HttpErrorResponse) {
    this.alert.showError(response.error.message).then(
      () => {  this.redirectBack(); }
    )
  }

  // Función para escuchar cambios en el usuario actual por medio del WebSocket
  private subscribeToChanges(): void {
    const subscription$ = this.socket.listen<HttpResponse<FormPersonal>>(`/listen/personal_form/${this.id}`).subscribe(
      response => {
        this.setForm(this.formService.buildForm(response.data));
        this.firstStep.disable();
        this.secondStep.disable();
        this.thirdStep.disable();
        this.summaryStep.disable();
      }
    )
    this.subscriptions$.push(subscription$);
  }

  // Asignación de los datos de la API al stepper
  private setForm(form: FormGroupSteps) {
    this.firstStep = form.firstStep;
    this.secondStep = form.secondStep;
    this.thirdStep = form.thirdStep;
    this.summaryStep = form.summaryStep;

    const subsOne$ = this.secondStep.get("area").valueChanges.subscribe(value => { 
      this.role = this.catalogs.role.filter(role => role.area.id === value.id); 
      const area = this.secondStep.get("role")?.value?.area;
      if (area && area?.id !== this.secondStep.get("area").value.id) {
        this.secondStep.get("role").setValue(null);
      }

    });
    const subsTwo$ = this.secondStep.get("certification").valueChanges.subscribe((value: boolean) => { this.formService.certificationChange(this.secondStep, value); });

    this.subscriptions$.push(subsOne$);
    this.subscriptions$.push(subsTwo$);
  }

  private sendData(data: any, step: number) {
    const sender = this.user.email;
    const id = this.firstStep.value.id;
    this.submitLoading = true;
    const body = {
      ...data,
      sender
    }

    

    this.socket.send(`/send/save/${id}/${step}`, body)
  }

  onSubmitStepOne() {
    if (!this.isEditing) return;
    const firstStep = this.formService.onSubmitFirstStep(this.firstStep);
    if (!firstStep) return;
    this.sendData({ firstStep }, 1);
  }

  onSubmitStepTwo() {
    if (!this.isEditing) return;
    const secondStep = this.formService.onSubmitSecondStep(this.secondStep);
    if (!secondStep) return;
    this.sendData({ secondStep }, 2);
  }

  onSubmitStepThree() {
    if (!this.isEditing) return;
    const thirdStep = this.formService.onSubmitThirdStep(this.thirdStep);
    if (!thirdStep) return;
    this.sendData({ thirdStep }, 3);
  }

  setDocuments(documents: FileItem[]) {
    this.documents = documents;
  }

  private responseFormSubscription(sender: string) {
    const subscription$ = this.socket.listen<HttpResponse<FormPersonal>>(`/listen/personal_form_response/${sender}`).subscribe({
      next: (response) => {

        this.submitLoading = false;
        
        if(!response.ok) {
          this.alert.showError(response.message);
          return;
        }

        this.setForm(this.formService.buildForm(response.data));

        this.firstStep.disable();
        this.secondStep.disable();
        this.thirdStep.disable();
        this.summaryStep.disable();
        
        if(response.showAlert) this.alert.showSuccess(response.message)
        .then(() => {
            if (response.message === "created.response") {
              this.redirectBack()
            }
          } 
        )
      },
      error: (response) => {
        this.submitLoading = false;
        
        this.alert.showError(response.error.message);
      }
    })
    this.subscriptions$.push(subscription$);
  }

  onSubmit() {
    
    const firstStep = this.formService.onSubmitFirstStep(this.firstStep);
    const secondStep = this.formService.onSubmitSecondStep(this.secondStep);
    const thirdStep = this.formService.onSubmitThirdStep(this.thirdStep);
    const summaryStep = this.formService.onSubmitSummaryStep(this.summaryStep);

    if (!firstStep || !secondStep || !thirdStep || !summaryStep) {
      
      return;
    }
    
    const documents = this.documents;
    const sender = this.user.email;

    const form = { firstStep, secondStep, thirdStep, summaryStep, documents, sender };

    if (this.uploaded) {
      this.socket.send(`/send/save`, form);
      return;
    }

    this.submitLoading = true;
    const email = form.thirdStep.email;
    const parent = environment.idsGoogleDrive.capitalHumano;

    const createFolder$ = this.drive.createFolder(email, parent).subscribe({
      next: (res: any) => {

        if (documents.length <= 0) {
          this.socket.send(`/send/save`, form)
          this.uploaded = true;
          return;
        }

        const uploadRequests: Observable<any>[] = this.documents.map(doc => this.drive.uploadFile(doc.uploadFile));
        const requests$ = forkJoin(uploadRequests).subscribe(resp => {

          resp.map((res, index) => {
            this.documents[index].driveId = res.id;
            this.documents[index].id = null;
            this.documents[index].notUploaded = false;
            this.documents[index].uploadFile = null;
          })
  
          this.uploaded = true;
          form.documents = this.documents;

          this.socket.send(`/send/save`, form)
        })
  
        this.subscriptions$.push(requests$);
      },
      error: this.onLoadPersonalError
    }); 

    this.subscriptions$.push(createFolder$);

  }

  back() {
    if(this.uploaded) {
      this.deleteFiles();
    } else {
      this.redirectBack();
    }
  }

  redirectBack() {
    this.router.navigate(["/capital-humano/personal"]);
  }
 
  stepChange(event: StepperSelectionEvent) {
    if (!this.isEditing) return;
    this.stateService.saveTab(event.selectedIndex);
  }

  deleteFiles() {
    this.submitLoading = true;

    const deleteRequests = this.documents.map(d => this.drive.deleteFile(d.driveId));
    deleteRequests.push(this.drive.deleteFolder());

    const requests$ = forkJoin(deleteRequests).subscribe(resp => {
      
      
      this.submitLoading = false;
      this.redirectBack();
    })

    this.subscriptions$.push(requests$);
  }

  get certification(): boolean { return this.secondStep?.get("certification")?.value }
  get maskRFC(): string { return MASK_RFC; }
  get maskCURP(): string { return MASK_CURP; }
  get maskNSS(): string { return MASK_NSS; }
  get personalName(): string {
    return  this.firstStep.value.name + ' ' + this.firstStep.value.lastName;
  }
}
