// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const uri = "localhost";
export const uri = "desarrollo.crowe.mx";

export const environment = {
  production: false,
  base_url: `https://${uri}/api/v1`,
  ws_url: `wss://${uri}/ws/v1`,
  ws_debug: false,
  credential: { accessToken: "access_token", idToken: "id_token", oauthToken: "oauth_token" },
  scopes: ['https://www.googleapis.com/auth/calendar, https://www.googleapis.com/auth/drive'],
  client_id: "614157247556-0q49ggmjobls40ativ9mo13f4e4thjo9.apps.googleusercontent.com",
  client_secret: "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",
  debugPassword: "Crowe2024",
  idsGoogleDrive: {
    capitalHumano: "1Nz5ZaDW6mtK5JtWXf_aNTij2c8t5Bz6o",
    oportunidades: "1eDFteaxhDKcK93mgSckTJ2L5TleNr8bK",
    proyectos: "1dVIBzBOuB-6KGcp26iX8X1lvf_pfAiod",
    capacitacion: "11R-uFcBx_oppi22kF1pPhQtPsUWM-X6M",
    equipoComputo: "1qp8rlnAENOWg9zI7oU8zeIq2REWFRSh8",
    marketing: "1NWOb7-sS7U_YMTtfqV706m8a_kk20iRB",
    enlace_crowe: "1joW5iYzHM1n283OQE38ryTS60QheMeCG",
    enlace_crowe_categorias: "1BX5wlc2M1IsQBDXX7b6MlXv4PsjUXcdV"
  },
  gapis_url: {
    drive: "https://www.googleapis.com/drive/v3",
    drive_upload: "https://www.googleapis.com/upload/drive/v3",
    calendar: "https://www.googleapis.com/calendar/v3"
  },
  storage: localStorage,
  offline: false,
  zoom_client_id: "v4JU5sqWRxKMwhjIrpbH7g",
  zoom_client_secret: "dOCZe1OGukl0huHTZjXww4lbJn1FVyRR"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
