import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '../../services/validation/validation.service';


@Component({
  selector: 'app-input-date-range',
  templateUrl: './input-date-range.component.html',
  styleUrls: ['./input-date-range.component.scss']
})
export class InputDateRangeComponent implements OnInit {

  @Input() fieldStart: string;
  @Input() fieldEnd: string;
  @Input() label: string;
  @Input() hint: string = "MM/DD/YYYY";
  @Input() form: FormGroup;
  @Input() mask: string = "d0/M0/0000";
  @Input() disabled: boolean = false;

  constructor(private validator: ValidationService) { }

  ngOnInit(): void {
    
  }

  isValidFieldStart(): boolean | null {
    return this.validator.isValidField(this._control_start);
  }

  isValidFieldEnd(): boolean | null {
    return this.validator.isValidField(this._control_end);
  }

  getFieldError(): string | null {
    return this.validator.getFieldError(this._control_start, this.fieldStart) + "  " + this.validator.getFieldError(this._control_end, this.fieldEnd);
  }

  getFieldErrorEnd(): string | null {
    return this.validator.getFieldError(this._control_end, this.fieldEnd);
  }

  get _control_start(): FormControl {
    return this.form.get(this.fieldStart) as FormControl;
  }

  get _control_end(): FormControl {
    return this.form.get(this.fieldEnd) as FormControl;
  }

  get requiredStart(): boolean {
    return this.validator.isRequired(this._control_start);
  }

  get requiredEnd(): boolean {
    return this.validator.isRequired(this._control_end);
  }
  
}
