import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { UserService } from '../../../app/shared/services/user/user.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { TimeTrackingService } from 'src/app/main/time-tracking/services/time-tracking/time-tracking.service';
import { MatDialog } from '@angular/material/dialog';
import { AddActivityDialog } from 'src/app/main/time-tracking/interfaces/dialog-data.interface';
import { AddHourComponent } from 'src/app/main/time-tracking/components/add-hour/add-hour.component';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { AddIncidenceComponent } from 'src/app/main/time-tracking/components/add-incidence/add-incidence.component';
import { AddSpentComponent } from 'src/app/main/time-tracking/components/add-spent/add-spent.component';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

  megaMenuOpen$: Observable<boolean> = of(false);

  user: User = null;
  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
              private userService: UserService,
              private alert: AlertService,
              private service: TimeTrackingService,
              private dialog: MatDialog,
              private socket: RxStompService,
            ) { }
  
  ngOnInit(): void {
    this.userService.user$.subscribe(user => {
      if (!user) return;
      this.user = user;
    })
  }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  stop():void {
    this.service.setActive(this.user.activeCounter);
    this.alert.showWarning(`Se finalizará el contador para la actividad: ${this.user.activeCounter.activity}`, true).then(res => {
      if (res.isConfirmed) {
        this.service.stopActivity(this.user.activeCounter.id).subscribe({
          next: response => {
            this.alert.showSuccess(response.message);
            this.userService.setActiveCounter();
            this.socket.send(`/send/time-tracking/${this.user.id}/1/0`, response.data);
          },
          error: response => {
            this.alert.showError(response.error.message);
          }
        })
      }

      this.service.setActive(null);
    })
  }

  newActivity(): void {
    const data: AddActivityDialog = {
      date: new Date().toISOString(),
    };
    this.dialog.open(AddHourComponent, { data, disableClose: true })
  }

  newIncidence(): void {
    const data: AddActivityDialog = {
      date: new Date().toISOString(),
    };
    this.dialog.open(AddIncidenceComponent, { data, disableClose: true })
  }

  newSpent(): void {
    const data: AddActivityDialog = {
      date: new Date().toISOString(),
    };
    this.dialog.open(AddSpentComponent, { data, disableClose: true })
  }

}
