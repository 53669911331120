import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ValidationService } from '../../services/validation/validation.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
})
export class InputTimeComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() label: string = "Ingrese el tiempo";
  // value: string = "09:00"
  @Input() field: string;
  @Input() disabled: boolean = false;

  constructor(private validator: ValidationService) { }
  
  ngOnInit(): void {
  }

  isValidField(): boolean | null {
    return this.validator.isValidField(this._control);
  }

  getFieldError(): string | null {
    return this.validator.getFieldError(this._control, this.field);
  }

  get _control(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  get required(): boolean {
    return this.validator.isRequired(this._control);
  }

}
