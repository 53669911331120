import { Component, Inject, OnInit } from '@angular/core';
import { SpentForm, initSpentForm } from '../../interfaces/form.interface';
import { AddSpentDialog } from '../../interfaces/dialog-data.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { buildCatalog, buildItem, formatDate } from 'src/app/shared/util/helpers';
import { User } from 'src/app/shared/interfaces/user.interface';
import { CatActivity, CatProject, ListCatalogs, Spent, SpentItem, initCatalogs } from '../../interfaces/time-tracking.interface';
import { TimeTrackingService } from '../../services/time-tracking/time-tracking.service';
import { DriveService } from 'src/app/shared/services/gapis/drive/drive.service';
import { environment } from 'src/environments/environment';
import { Catalog } from 'src/app/shared/interfaces/catalogs.interface';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';

@Component({
  selector: 'vex-add-spent',
  templateUrl: './add-spent.component.html',
  styleUrls: ['./add-spent.component.scss'],
  animations: [fadeInRight400ms]
})
export class AddSpentComponent implements OnInit {

  public form: FormGroup<SpentForm>;
  public loading: boolean = false;
  public today: string = "";
  public user: User;
  public catalogs: ListCatalogs = initCatalogs
  public activity: CatActivity[] = [];
  public file: File = null;
  public isUploaded: boolean = false;
  public spent: Spent = null;
  public isEditing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddSpentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddSpentDialog,
    private fb: FormBuilder,
    private alert: AlertService,
    private socket: RxStompService,
    private service: TimeTrackingService,
    private userService: UserService,
    private drive: DriveService
  ) { }

  ngOnInit(): void {
    this.userService.user$.subscribe(user => {
      if (!user) return;
      
      this.user = user;

      this.loadDate();

      if (this.data?.id && this.data.id) {
        this.loading = true;
        this.isEditing = true;
        this.service.findSpent(this.data.id).subscribe({
          next: response => {
            this.spent = response.data;
            this.loadForm(response.data)
            this.today = response.data.date;
            this.loading = false;
            this.isUploaded = true;
            this.form.disable();
          },
          error: response => {
            
            this.loading = false;
          }
        })
      }

      
      this.loadForm();
      this.loadCatalogs();
    })
  }

  private loadForm(data = initSpentForm): void {
    // this.today = this.data.date;

    this.form = this.fb.group({
      id: [data.id],
      project: [buildCatalog(data.projectId) as CatProject, [Validators.required]],
      type: [buildCatalog(data.typeId) as Catalog, [Validators.required]],
      amount: [data.amount, [Validators.required]],
      file: [this.file as string | File, [Validators.required]],
      notes: [data.notes, [Validators.required]],
    });

    if (this.data?.id) {
      this.form.controls.file.clearValidators();
      this.form.controls.file.updateValueAndValidity();
    }

    this.form.controls.file.valueChanges.subscribe(value => {
      this.isUploaded = value ? false : true;

      if (!this.isUploaded) {
        this.form.controls.file.setValidators([Validators.required]);
        this.form.updateValueAndValidity();
      } 
      // else {
      //   this.form.controls.file.clearValidators();
      // }
      
    })

    this.form.controls.project.valueChanges.subscribe(data => {
      if (data?.idKey) {
        this.drive.loadFolder(data.idKey, data.office, environment.idsGoogleDrive.proyectos).subscribe();
      }
    })
  }

  private loadCatalogs(): void {
    this.service.findCatalogs(this.user.id).subscribe(response => { this.catalogs = response.data; })
  }

  onSubmit(): void {
    if (this.form.invalid) return;

    this.loading = true;

    if (this.isUploaded) {
      const data: Spent = {
        id: this.form.value.id,
        personalId: this.user.id,
        projectId: this.form.value.project.id,
        typeId: this.form.value.type.id,
        amount: this.form.value.amount,
        file: this.spent.file,
        fileName: this.spent.fileName,
        notes: this.form.value.notes,
        date: this.today,
        statusId: 3,
      }
      this.save(data)
      return;
    }

    if (this.data?.id) {
      
      this.drive.deleteFile(this.spent.file as string).subscribe({
        next: response => {
          
        },
        error: error => {
          
        }
      });
    }

    this.drive.uploadFile(this.file).subscribe({
      next: response => {
        const data: Spent = {
          id: this.form.value.id,
          personalId: this.user.id,
          projectId: this.form.value.project.id,
          typeId: this.form.value.type.id,
          amount: this.form.value.amount,
          file: response.id,
          fileName: response.name,
          notes: this.form.value.notes,
          date: this.today,
          statusId: 3,
        }

        this.save(data);

      },
      error: error => {
        this.alert.showError("Ha ocurrido un error al subir el archivo, intente más tarde.");
        this.loading = false;
      }
    })

    

    
  }

  close(): void {
    this.dialogRef.close();
  }

  setFile($event: any): void {
    this.file = $event;
  }

  save(data: Spent): void {
    this.service.saveSpent(data).subscribe({
      next: response => {
        this.close();
        this.alert.showSuccess(response.message);
        

        const arr = buildItem(this.form.value.project.value);
        
        const item: SpentItem = {
          id: response.data.id,
          company: arr[0],
          project: arr[1],
          type: this.form.value.type.value,
          file: response.data.file as string,
          fileName: response.data.fileName,
          amount: response.data.amount,
          notes: response.data.notes,
          date: response.data.date,
          status: "Pendiente",
        }

        // this.service.setSpent(item);

        this.socket.send(`/send/time-tracking/${this.user.id}/1/2`, item);

        // this.service.showTable(2);
        this.loading = false;
      },
      error: response => {
        this.alert.showError(response.error.message);
        this.loading = false;
      }
    })
  }

  loadDate(): void {
    this.service.date$.subscribe(date => {
      this.today = date;
    })
  }

  get selected(): Date {
    return new Date(this.today);
  }

}
