import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent implements OnInit {

  @Input() field: string;
  @Input() label: string;
  @Input() hint: string = "MM/DD/YYYY";
  @Input() form: FormGroup;
  @Input() mask: string = "d0/M0/0000";
  @Input() disabled: boolean = false;
  @Input() min: Date = null;
  @Input() max: Date = null;

  constructor(private validator: ValidationService) { }

  ngOnInit(): void {
    
  }

  isValidField(): boolean | null {
    return this.validator.isValidField(this._control);
  }

  getFieldError(): string | null {
    return this.validator.getFieldError(this._control, this.field);
  }

  get _control(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  get required(): boolean {
    return this.validator.isRequired(this._control);
  }

}
