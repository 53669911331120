<div>
    <input class="input-file" type="file" [id]="id" [formControl]="_control" [required]="required" [accept]="accept" (change)="setFile($event)" [multiple]="multiple">
    <label class="input-file-container" [ngClass]="{ 'file-error': getFieldError() }" [for]="id">
        <label class="label-text" [ngClass]="{ 'error-text': getFieldError() }"> {{ label }} <span *ngIf="required" style="color: #007bff;"> * </span> </label>
        <div class="input-text">
            <span class="text-gray-500" *ngIf="fileName === ''"> Haz click aquí para <strong> seleccionar {{ multiple ? 'tus archivos' : 'tu archivo' }} </strong> </span>
            <span class="file-name text-center" *ngIf="fileName !== ''"> {{ fileName }} </span>

            <div class="icon-container">
                <mat-icon> attach_file </mat-icon>
            </div>
        </div>
    </label>
    <mat-error [style.margin-top.px]="-4" class="error-message" [ngClass]="{ 'error-message__show': getFieldError() }"> {{ getFieldError() }} </mat-error>

    <div *ngIf="multiple">
        <div class="file" *ngFor="let file of files">
            <mat-icon [ngClass]="{ 'icon-pdf': file.type === 'application/pdf', 'icon-img': file.type !== 'application/pdf' }"> 
                {{ file.type === "application/pdf" ? "picture_as_pdf" : "photo_library" }} 
            </mat-icon> 
            <span> {{ file.name }} </span>
            <button class="bg-gray-100 btn-sm delete" type="button" (click)="deleteFile(file)"> 
                <mat-icon>close</mat-icon> 
            </button>
        </div>
    </div>

</div>