import { Pipe, PipeTransform } from '@angular/core';
import { buildSecondsTime, formatTime, formatTimeString } from '../../util/helpers';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: number | string, flag: boolean = false): string {

    // if (flag) {
    //   if (typeof value === 'number') {
    //     return formatTime(value as number);  
    //   }
    //   return formatTime(buildSecondsTime(value as string));
    // }

    return typeof value === "number" ? formatTime(value as number) : flag ? formatTime(buildSecondsTime(value as string)) : formatTimeString(value as string);
  }

}
