<mat-form-field (click)="open(inputElement)" *ngIf="required || showDisabled">
    <mat-label>{{ label }}</mat-label>
    <mat-select #select [formControl]="_control" [required]="required" [disableOptionCentering]="true" autocomplete="off" [multiple]="multiple">
        <mat-option class="search">
            <input matInput placeholder="Buscar..." #inputElement (click)="$event.stopPropagation()" [(ngModel)]="search" (ngModelChange)="filter()" (keydown)="setValue($event)">    
        </mat-option>
        <div class="items-container" (scroll)="onScroll($event)">
            <mat-option *ngFor="let item of displayedItems;" [value]="item"> 
                {{ item.value }} 
            </mat-option>
        </div>
    </mat-select>

    <mat-error> {{ getFieldError() }} </mat-error>
</mat-form-field>
