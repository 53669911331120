import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LayoutService } from '../services/layout.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterOutlet, Scroll } from '@angular/router';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { checkRouterChildsData } from '../utils/check-router-childs-data';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../config/config.service';
import { Crumb } from '../components/breadcrumbs/breadcrumbs.component';
import { MatIcon } from '@angular/material/icon';
import { FormPageComponent } from 'src/app/main/personal/pages/form-page/form-page.component';
import { fadeInUp400ms } from '../animations/fade-in-up.animation';
import { stagger40ms } from '../animations/stagger.animation';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

@UntilDestroy()
@Component({
  selector: 'vex-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class LayoutComponent implements OnInit, AfterViewInit {

  @Input() sidenavRef: TemplateRef<any>;
  @Input() toolbarRef: TemplateRef<any>;
  @Input() footerRef: TemplateRef<any>;
  @Input() quickpanelRef: TemplateRef<any>;

  public showMenuBtn: boolean = false;

  isLayoutVertical$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isBoxed$ = this.configService.config$.pipe(map(config => config.boxed));
  isToolbarFixed$ = this.configService.config$.pipe(map(config => config.toolbar.fixed));
  isFooterFixed$ = this.configService.config$.pipe(map(config => config.footer.fixed));
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isDesktop$ = this.layoutService.isDesktop$;

  scrollDisabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  containerEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.containerEnabled))
  );

  searchOpen$ = this.layoutService.searchOpen$;

  breadcrumbs: Crumb[] = [];

  @ViewChild('quickpanel', { static: true }) quickpanel: MatSidenav;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild(MatSidenavContainer, { static: true }) sidenavContainer: MatSidenavContainer;

  constructor(private cd: ChangeDetectorRef,
              private breakpointObserver: BreakpointObserver,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              // private tourService: TourService,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    /**
     * Expand Sidenav when we switch from mobile to desktop view
     */
    // this.isDesktop$.pipe(
    //   filter(matches => !matches),
    //   untilDestroyed(this)
    // ).subscribe(() => this.layoutService.expandSidenav());

    /**
     * Open/Close Quickpanel through LayoutService
     */
    this.layoutService.quickpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.quickpanel.open() : this.quickpanel.close());

    /**
     * Open/Close Sidenav through LayoutService
     */
    this.layoutService.sidenavOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.sidenav.open() : this.sidenav.close());

    /**
     * Mobile only:
     * Close Sidenav after Navigating somewhere (e.g. when a user clicks a link in the Sidenav)
     */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      withLatestFrom(this.isDesktop$),
      filter(([event, matches]) => !matches),
      untilDestroyed(this)
    ).subscribe(() => this.sidenav.close());

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      }
    );

    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
  }

  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      

      const label = this.getLabel(child.snapshot.data);

      if(label !== "") {
        breadcrumbs.push({
          label,
          route: url,
        });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  getLabel(data: any): string {

    if (data && data.label) {
      return data.label;
    }

    return '';
  }

  @ViewChild(RouterOutlet) routerOutlet: RouterOutlet;


  ngAfterViewInit(): void {
    /**
     * Enable Scrolling to specific parts of the page using the Router
     */
    this.router.events.pipe(
      filter<Event, Scroll>((e: Event): e is Scroll => e instanceof Scroll),
      untilDestroyed(this)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        this.sidenavContainer.scrollable.scrollTo({
          start: e.position[0],
          top: e.position[1]
        });
      } else if (e.anchor) {
        // anchor navigation

        const scroll = (anchor: HTMLElement) => this.sidenavContainer.scrollable.scrollTo({
          behavior: 'smooth',
          top: anchor.offsetTop,
          left: anchor.offsetLeft
        });

        let anchorElem = this.document.getElementById(e.anchor);

        if (anchorElem) {
          scroll(anchorElem);
        } else {
          setTimeout(() => {
            anchorElem = this.document.getElementById(e.anchor);
            scroll(anchorElem);
          }, 100);
        }
      } else {
        // forward navigation
        this.sidenavContainer.scrollable.scrollTo({
          top: 0,
          start: 0
        });
      }
    });


    this.showMenuBtn = false;

    this.router.events.subscribe(event => {
      this.showMenuBtn = false;
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.showMenuBtn = this.document.getElementsByClassName("mat-horizontal-stepper-header-container").length > 0;
        }, 500); // Espera 100 milisegundos antes de acceder al DOM
      }
    })

    setTimeout(() => {
      this.showMenuBtn = this.document.getElementsByClassName("mat-horizontal-stepper-header-container").length > 0;
    }, 500); // Espera 100 milisegundos antes de acceder al DOM
  }

  @ViewChild('element') element: MatIcon;

  showMenu(element: MatIcon) {
    const menu = document.getElementsByClassName("mat-horizontal-stepper-header-container")[0];
    menu.classList.toggle("menu-open");
    element._elementRef.nativeElement.classList.toggle("opened");
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (this.showMenuBtn && !this.element._elementRef.nativeElement.contains(event.target)) {
      const menu = document.getElementsByClassName("mat-horizontal-stepper-header-container")[0];
      menu.classList.remove("menu-open");
      this.element._elementRef.nativeElement.classList.remove("opened");
    }
  }
}
