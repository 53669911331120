<!-- <p> {{ label }} {{ required ? "*" : "" }} </p>
<ngx-mat-timepicker-field [formControl]="_control" [required]="required"
class="input-time" [class]="getFieldError() ? 'error' : ''" color="primary" [format]="24" 
[defaultTime]="value"></ngx-mat-timepicker-field>
<mat-error class="error-message" [ngClass]="{ 'error-message__show': getFieldError() }"> {{ getFieldError() }} </mat-error> -->

<mat-form-field>
    <mat-label [attr.for]="field">{{ label }}</mat-label>
    <input matInput
    [format]="24"
    [formControl]="_control"
    [ngxMatTimepicker]="pickerD"
    placeholder="00:00"
    readonly />
    <mat-icon matSuffix class="text-gray-600" [ngClass]="{ 'disabled': _control.disabled  }"
              (click)="pickerD.open()">
        watch_later
    </mat-icon>
    <mat-error> {{ getFieldError() }} </mat-error>
</mat-form-field>
<div>
    <!-- <small>ngx-mat-timepicker is here</small> -->
     <ngx-mat-timepicker color="primary"
                         appendToInput="true"
                         #pickerD></ngx-mat-timepicker>
</div>