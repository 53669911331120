import { Pipe, type PipeTransform } from '@angular/core';
import { formatDate } from '../../util/helpers';

@Pipe({
  name: 'dateFormat',
  // pure: true,
})
export class DatePipe implements PipeTransform {

  transform(value: string, format: string = "yyyy MMMM d", showTime: boolean = true, hours: string = ""): string {
    if (!value) return "";
    
    return formatDate(value, format, showTime, hours);
  }

}
