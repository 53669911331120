import { Component, OnDestroy, OnInit } from '@angular/core';
import { Personal } from '../../interfaces/personal.interface';
import { PersonalService } from '../../services/personal/personal.service';
import { HttpResponse } from 'src/app/shared/interfaces/http-response.interface';
import { Observable, BehaviorSubject, Subscription, take, tap } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ModuleStateService } from 'src/app/shared/services/module-state/module-state.service';
import { encrypt } from 'src/app/shared/util/helpers';
import { UserService } from '../../../../shared/services/user/user.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { MatDialog } from '@angular/material/dialog';
import { OrgChartOfficeComponent } from '../../components/org-chart-office/org-chart-office.component';
import { PersonalHistoryDialog } from '../../interfaces/form.interface';
import { InactiveFormComponent } from '../../components/inactive-form/inactive-form.component';
import { ActiveFormComponent } from '../../components/active-form/active-form.component';

@Component({
  selector: 'vex-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class MainPageComponent implements OnInit, OnDestroy {

  columns: TableColumn<Personal>[] = [
    // { label: 'ID', property: 'id', type: 'text', visible: false },
    // { label: "Checkbox", property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Nombre', property: 'name', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Apellido', property: 'lastName', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Puesto', property: 'role', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Oficina', property: 'office', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Correo', property: 'email', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Acciones', property: 'actions', type: 'button', visible: true, cssClasses: ["text-light"] },
  ];

  columnsInactive: TableColumn<Personal>[] = [
    // { label: 'ID', property: 'id', type: 'text', visible: false },
    // { label: "Checkbox", property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Nombre', property: 'name', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Apellido', property: 'lastName', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Puesto', property: 'role', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Oficina', property: 'office', type: 'text', visible: true, cssClasses: ["text-light"] },
    // { label: 'Correo', property: 'email', type: 'text', visible: true, cssClasses: ["text-light"] },
    { label: 'Acciones', property: 'actions', type: 'button', visible: true, cssClasses: ["text-light"] },
  ];


  private personalActiveSubject: BehaviorSubject<Personal[]> = new BehaviorSubject<Personal[]>([]);
  public personalActive: Observable<Personal[]> = this.personalActiveSubject.asObservable();

  private personalInactiveSubject: BehaviorSubject<Personal[]> = new BehaviorSubject<Personal[]>([]);
  public personalInactive: Observable<Personal[]> = this.personalInactiveSubject.asObservable();

  private subscriptions$: Subscription[] = [];

  tab = 0;

  user: User = null;

  constructor(
    private service: PersonalService,
    private alert: AlertService, 
    private router: Router,
    private socket: RxStompService,
    private stateService: ModuleStateService,
    private userService: UserService,
    private dialog: MatDialog
  ) { }

  ngOnDestroy(): void {
    this.subscriptions$.map(s => {
      s.unsubscribe();
      s.remove(s);
    })
  }

  ngOnInit(): void { 
    this.userService.user$.subscribe(user => {
      if (!user) return;
      this.user = user;
      this.loadData();
      this.subscribeToChanges();
      this.tab = this.stateService.getTab();
      // this.service.getOfficeTree(this.user.office.id).subscribe(response => {
      // })
    })
  } 

  private loadData(): void {
    const personalActive$ = this.service.getPersonalActive(this.user.officesRelated).subscribe(
      response => {
        this.personalActiveSubject.next(response.data);
      }
    )

    this.subscriptions$.push(personalActive$);

    const personalInactive$ = this.service.getPersonalInactive(this.user.officesRelated).subscribe(
      response => {
        this.personalInactiveSubject.next(response.data);
      }
    )

    this.subscriptions$.push(personalInactive$);
  }

  private subscribeToChanges(): void {
    const changes$ = this.socket.listen<HttpResponse<Personal>>("/listen/personal").subscribe({
      next: response => {
        const personal = response.data;

        if (personal.enabled) {

          const value = this.personalInactiveSubject.value.filter(i => i.id !== personal.id);
          this.personalInactiveSubject.next(value);


          this.personalActive.pipe(
            take(1),
            tap(listPersonal => {
              const list = this.updateList(listPersonal, personal);
              this.personalActiveSubject.next(list);
            })
          ).subscribe(data => { 
            this.personalActiveSubject.next(data); 
          });

          

        } else {

          const value = this.personalActiveSubject.value.filter(i => i.id !== personal.id);
          this.personalActiveSubject.next(value);

          this.personalInactive.pipe(
            take(1),
            tap(listPersonal => {
              const list = this.updateList(listPersonal, personal);
              this.personalInactiveSubject.next(list); })
          ).subscribe(data => { 
            this.personalInactiveSubject.next(data); 
          });
        }

        
      }
    })

    this.subscriptions$.push(changes$);
  }

  private updateList(listPersonal: Personal[], personal: Personal): Personal[] {
    const current = listPersonal.findIndex(p => p.id === personal.id);

    if (current > -1) {
      listPersonal[current] = personal;
      return listPersonal;
    }
    
    listPersonal.push(personal);
    return listPersonal;
  }

  edit(personal: Personal) {
    // 
    this.redirect(`/capital-humano/personal/edit/${encrypt(personal.id)}`)
  }

  disable(personal: Personal) {
    const data: PersonalHistoryDialog = {
      personal: `${personal.lastName}, ${personal.name}`,
      email: `${personal.email}`,
      personalId: personal.id,
    }
    this.dialog.open(InactiveFormComponent, { data, disableClose: true, });
  }

  enable(personal: Personal) {
    const data: PersonalHistoryDialog = {
      personal: `${personal.lastName}, ${personal.name}`,
      email: `${personal.email}`,
      personalId: personal.id,
    }
    this.dialog.open(ActiveFormComponent, { data, disableClose: true, });
  }

  add() {
    this.redirect(`/capital-humano/personal/add`)
  }

  tabChanged(event: MatTabChangeEvent) {
    this.stateService.saveTab(event.index);
  }

  redirect(route: string) {
    this.router.navigate([route]);
  }

  showDialog(): void {
    this.dialog.open(OrgChartOfficeComponent, {
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      data: {
        officeId: this.user.office.id
      }
    });
  }

}
