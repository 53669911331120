import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { PersonalService } from '../../services/personal/personal.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { DialogViewDiploma } from '../../interfaces/document-dialog.interface';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
// import { FileItem } from 'src/app/shared/interfaces/file.interface';

@Component({
  selector: 'vex-view-diploma',
  templateUrl: './view-diploma.component.html',
  styleUrls: ['./view-diploma.component.scss'],
})
export class ViewDiplomaComponent implements OnInit {

  loading = false;
  subscription: Subscription;
  isImage: boolean = false;
  urlImage: string | SafeResourceUrl = "";
  // pdfData: any = "";

  content: SafeUrl | undefined;

  constructor(
    public dialogRef: MatDialogRef<ViewDiplomaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogViewDiploma,
    private alert: AlertService,
    private service: PersonalService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.service.generateDiploma(this.data.diploma.personalId, this.data.diploma.eventId, this.data.diploma.isExponent).subscribe(response => {
      this.content = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+response.data);
      this.loading = false;
    })
  }

  close() {
    this.dialogRef.close();
  }



}
