import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  pure: false,
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], key: string, order: 'asc' | 'desc' = 'asc'): any[] {
    if (!Array.isArray(value) || !key) {
      return value;
    }

    const finalValue = [...value]

    const sorted = finalValue.sort((a, b) => {
      const valueA = this.getPropertyValue(a, key);
      const valueB = this.getPropertyValue(b, key);

      let result: number;

      if (valueA < valueB) {
        result = -1;
      } else if (valueA > valueB) {
        result = 1;
      } else {
        result = 0;
      }

      return order === 'asc' ? result : -result;
    });
    
    return sorted;
  }

  private getPropertyValue(object: any, key: string): any {
    if (!object || !key) {
      return null;
    }

    const keys = key.split('.');
    let value = object;

    for (const k of keys) {
      if (!value.hasOwnProperty(k)) {
        return null;
      }
      value = value[k];
    }

    return value;
  }

}
