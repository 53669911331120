import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MainModule } from './main/main.module';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { RxStompService } from './shared/services/rx-stomp/rx-stomp.service';
import { rxStompServiceFactory } from './shared/services/rx-stomp/stompFactory';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

export function storageFactory() : OAuthStorage {
  return environment.storage;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MainModule,
    VexModule,
    NgxMaskModule.forRoot(),
    OAuthModule.forRoot(),
    TourMatMenuModule.forRoot(),
  ],
  providers: [
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: OAuthStorage, useFactory: storageFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
