<div class="relative">
    <div class="mt-16" *ngIf="!isEditing"></div>
    
    <div [ngClass]="{'table-container': !isEditing}">
        <app-table title="Expediente" [columns]="columns" [data]="data" [isEditing]="true"
                    [actionsMenu]="actionsMenu" (clickEvent)="show($event)" (addEvent)="add()"
                    key="PERSONAL_DOCUMENTS"></app-table>
    </div>

    <div class="absolute w-full h-full top-0 left-0 bg-loader" *ngIf="loading">
        <app-loading [loading]="loading"></app-loading>
    </div>
</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-doc="item" matMenuContent>
        <button mat-menu-item (click)="edit(doc)">
            <mat-icon svgIcon="mat:edit"></mat-icon>
            <span>Editar</span>
        </button>
        <button mat-menu-item (click)="delete(doc)">
            <mat-icon svgIcon="mat:delete"></mat-icon>
            <span>Eliminar</span>
        </button>
        <button mat-menu-item (click)="show(doc)">
            <mat-icon svgIcon="mat:visibility"></mat-icon>
            <span>Ver</span>
        </button>
    </ng-template>
</mat-menu>