import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Event } from 'src/app/shared/interfaces/user.interface';
import { MatIcon } from '@angular/material/icon';
import { CalendarService } from 'src/app/shared/services/gapis/calendar/calendar.service';

@Component({
  selector: 'vex-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {
  
  date = "";
  dayName = "";
  currentEvents: Event[] = [];
  events: Event[] = [];

  constructor(private calendar: CalendarService) { }

  ngOnInit() {
    this.date = DateTime.local().toFormat('DD');
    this.dayName = DateTime.local().toFormat('EEEE');

    this.calendar.events$.subscribe(
      (events: Event[]) => {
        this.events = events;
        this.events.sort((a: Event, b:Event) => new Date(a.start).getTime() - new Date(b.start).getTime())
        
        const currentDate = new Date();
        const targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        
        this.currentEvents = events.filter((evento) => new Date(evento.start) < targetDate );
        this.events = events.filter((evento) => new Date(evento.start) >= targetDate );

      }
    )
  }

  toggleEvents(div: HTMLDivElement, icon: MatIcon) {

    if (div.className === "events-container") {
      div.className = "events-container events-hidden";
      icon._elementRef.nativeElement.classList.add("icon-rotate");
      return;
    }

    div.className = "events-container"
    icon._elementRef.nativeElement.classList.remove("icon-rotate");

  }

}
