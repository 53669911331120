import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface ProgressState {
  [key: string]: number; // Almacena el progreso para cada petición
}

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  private _progressValue = new BehaviorSubject<number>(0);
  progressValue$ = this._progressValue.asObservable();

  start() {
    this._progressValue.next(0);
  }

  setProgress(progress: number) {
    this._progressValue.next(progress);
  }

  complete() {
    this._progressValue.next(100);
    setTimeout(() => this._progressValue.next(0), 500); // Resetea después de un breve retraso
  }
  
}
