import { Pipe, type PipeTransform } from '@angular/core';
import { User } from '../../interfaces/user.interface';

@Pipe({
  name: 'userName',
})
export class UsernamePipe implements PipeTransform {

  transform(value: User, ...args: unknown[]): string {
    const arrName = value?.name?.split(" ");
    const arrLastName = value?.lastName?.split(" ");

    if(!arrName) {return "" }
    if(!arrLastName) {return "" }

    const name = arrName[0];
    const lastName = arrLastName[0];

    return name + " " + lastName;
  }

}
