<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-container"  [ngClass]="{'editing': isEditing}" >
        <div class="my-3" *ngIf="!isEditing">
            <h1 class="text-lg font-semibold">Ingrese los datos de interés</h1>
            <p class="font-light text-gray-600">Por favor complete los siguientes campos para continuar con el proceso.</p>
        </div>
        <div class="flex gap-5 my-4">
            <div class="col w-1/2">
                <app-input-text [form]="form" field="hobbies" label="Pasatiempos" placeholder="Ingrese los pasatiempos"></app-input-text>
                <app-input-text [form]="form" field="allergies" label="Alergias" placeholder="Ingrese las alergias"></app-input-text>
            </div>
            <div class="col w-1/2">

                <div class="flex justify-between items-center max-w-[450px] mb-10 m-auto">
                    <div>
                        <h1 class="text-lg font-semibold">Datos familiares</h1>
                        <p class="font-light text-gray-600">Por favor ingrese los datos familiares.</p>
                    </div>
                    
                    <button class="text-contrast-white bg-primary btn-sm" (click)="add()" matTooltip="Nuevo familiar" type="button" [disabled]="disabled">
                        <mat-icon svgIcon="mat:add" [style.width.px]="18"></mat-icon>
                    </button>
                </div>
        
                <div class="items-container">
                    <div class="card-item" [ngClass]="{ 'disabled': disabled }" (click)="edit(item)" *ngFor="let item of data" @fadeInUp>
                        <div class="card-icon">
                            <span> {{ item.typeName }} </span>
                        </div>
                        <div class="card-body">
                            <div class="card-text">
                                {{ item.name }}, {{ item.age }} años
                            </div>
                            <div class="card-button">
                                <button mat-icon-button type="button" (click)="$event.stopPropagation()" [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{ item }" [disabled]="disabled"> 
                                    <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="data.length <= 0" class="text-center"> 
                        <img class="max-w-[200px] m-auto" src="assets/img/illustrations/empty.svg" alt=""> 
                        <h3 class="font-bold mt-3 text-lg"> Lo sentimos </h3>
                        <p> No se encontraron registros =( </p>
                    </div>
                </div>
                
            </div>
        </div>

        

    </div>
    <app-form-btns [form]="form" [editFlag]="isEditing" (submit)="save()">
        <div class="flex">
            <button mat-raised-button matStepperPrevious class="mr-3"> <mat-icon>settings_backup_restore</mat-icon>
                <span class="ml-2">Regresar</span> </button>
            <button mat-raised-button matStepperNext color="primary"><span>Siguiente</span>
                <mat-icon>navigate_next</mat-icon></button>
        </div>
    </app-form-btns>
</form>



<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-item="item" matMenuContent>
        <button mat-menu-item (click)="edit(item)">
            <mat-icon svgIcon="mat:edit"></mat-icon>
            <span>Editar</span>
        </button>
        <button mat-menu-item (click)="delete(item)">
            <mat-icon svgIcon="mat:delete"></mat-icon>
            <span>Eliminar</span>
        </button>
    </ng-template>
</mat-menu>