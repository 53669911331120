import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatMenu } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subscription, filter } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { StateService } from '../../services/table/state.service';
import { TableState } from '../../interfaces/table.interface';
import { findMenuItemByRoute, getRoot, search } from '../../util/helpers';
import { UserService } from '../../services/user/user.service';
import { User } from '../../interfaces/user.interface';
import { ExcelService } from '../../services/export/excel/excel.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpStatusService } from '../../services/http-status/http-status.service';


@UntilDestroy()
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class TableComponent implements OnInit {

  @ViewChild('inputRef') inputRef: ElementRef;
  

  @Input() title: string = "";
  @Input() columns: TableColumn<any>[] = [];
  @Input() export: TableColumn<any>[] = [];
  @Input() data!: Observable<any>;
  @Input() actionsMenu: MatMenu;
  @Input() key = "";
  @Input() loading: Observable<boolean> = new Observable<boolean>();
  @Input() showAdd: boolean = true;
  @Input() isEditing: boolean = false;

  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() addEvent: EventEmitter<any> = new EventEmitter<any>();

  enabled: boolean = false;
  allData: any[] = [];
  // subs: Subscription[] = [];
  
  // layoutCtrl = new UntypedFormControl('boxed');
  
  pageSize: number = 50;
  pageSizeOptions: number[] = [5, 10, 20, 50];

  dataSource: MatTableDataSource<any> | null;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();

  subscriptions$: Subscription[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  state!: TableState;
  user!: User;

  profileRoute: boolean = false;

  constructor(
    private tableState: StateService,
    private userService: UserService,
    private excel: ExcelService,
    private router: Router,
    private status: HttpStatusService, 
    private activatedRoute: ActivatedRoute
  ) {}
  
  ngOnDestroy(): void {
    this.subscriptions$.map(s => {
      s.unsubscribe();
      s.remove(s);
    })
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  init: number = 0;
  items: number = 50;

  ngOnInit() {

    if (this.export.length <= 0) {
      this.export = this.columns;
    }
    
    this.profileRoute = this.router.url.includes("perfil");

    const user$ =  this.userService.user$.subscribe(
      user => {
        if (!user) { return; }

        this.user = user;

        this.setPermissions();

      }
    )

    this.subscriptions$.push(user$);

    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;

    this.paginator._intl.itemsPerPageLabel = "Elementos por página";

    const data$ = this.data.subscribe((data: any) => {

      if (data.length > 50) {
        this.pageSizeOptions = [5, 10, 20, 50, data.length];
      }
      
      const state = this.tableState.getState(this.key);
      this.state = state ? state : { pagination: (data.length > 50 ? data.length : 50), page: 0, search: "", columns: this.columns };

      this.allData = data;
      
      this.paginator.pageIndex = this.state.page;
      this.dataSource.paginator = this.paginator;
      this.columns = this.state.columns
      this.pageSize = this.state.pagination;
      
      this.dataSource.data = data;
      
      this.searchCtrl.setValue(this.state.search);
    });

    this.subscriptions$.push(data$);

    const search$ = this.searchCtrl.valueChanges.pipe().subscribe(value => this.onFilterChange(value));
    this.subscriptions$.push(search$);
    this.dataSource.sort = this.sort;
  }

  loadItems(event: any) {
    const element = event.target;

    if (element.offsetHeight + element.scrollTop >= (element.scrollHeight - 250) && this.dataSource.data.length < this.allData.length) {

      if (this.init + (this.items * 2) >= this.allData.length) {
        this.dataSource.data = this.allData;
        this.init = this.allData.length;
      } else {
        this.dataSource.data = [...this.dataSource.data, ...this.allData.slice(this.init, this.init + this.items)];
        this.init += this.items;
      }
    }
  }


  onFilterChange(value: string) {

    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();

    this.dataSource.data = this.allData.filter(item => search(item, value)).slice(0, this.items);
    
    this.state = {
      ...this.state,
      search: value
    }
    
    this.tableState.saveState(this.state, this.key);

  }

  toggleColumnVisibility(column, event, index) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.saveColumns(column, column.visible, index);
  }

  saveColumns(column, event, index) {
    const columns = this.columns;
    columns[index].visible = event;
    this.state = {
      ...this.state,
      columns,
    }
    this.tableState.saveState(this.state, this.key);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  get searchEmpty() {
    return this.searchCtrl.value === "" || this.searchCtrl.value === null || this.searchCtrl.value === undefined;
  }

  get isEmpty(): boolean {
    return this.dataSource?.filteredData?.length <= 0;
  }

  textTooltip(text: string, element: HTMLElement): string {
    return text;
  }

  clear() {
    this.searchCtrl.setValue("");
    this.inputRef.nativeElement.focus();
  }

  click(item: any) {
    this.clickEvent.emit(item);
  }
  
  add(): void {
    this.addEvent.emit();
  }

  exportExcel() {
    this.excel.export(this.dataSource.filteredData, this.export, this.title, `${this.user.name} ${this.user.lastName}`);
  }

  savePageSize(event: PageEvent) {
    this.state = {
      ...this.state,
      page: event.pageIndex,
      pagination: event.pageSize,
    }

    this.tableState.saveState(this.state, this.key);

    if (event.pageSize > 50) {
      this.init = 0;
      this.items = 50;
      this.dataSource.data = this.allData.slice(this.init, this.init + this.items);
    } else {
      this.dataSource.data = this.allData;
    }
    
  }

  filterPredicate() {
    return (data: any, filter: string): boolean => {
      return search(data, filter);
    };
  }

  calculateTotal(columnProperty: string): number {
    return this.dataSource.data.reduce((total, currentValue) => {
        if (currentValue[columnProperty]) {
            return total + currentValue[columnProperty];
        }
        return total;
    }, 0);
  }

  get hidden(): string {
    return this.isEmpty ? "" : "hidden";
  }

  get hiddenFooter(): string {
    return this.columns.some(item => item.showTotal) ? "" : "hidden";
  }

  private setPermissions(): void {
    const item = findMenuItemByRoute(this.user.menu, getRoot(this.router.url))
      if (!item) return;

      if (item.label === "Oportunidades" || item.label === "Prospectos") {
        this.enabled = true;
        return;
      }

      this.enabled = item.enabled;

      const sub = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        const item = findMenuItemByRoute(this.user.menu, getRoot(this.router.url))
        if (!item) return;
        this.enabled = item.enabled;
      });
      this.subscriptions$.push(sub);
  }

  get disableCheck(): boolean {
    return this.columns.filter(c => c.visible).reduce((acc, value) => acc + 1, 0) === 1;
  }

}
