<h2 class="title" mat-dialog-title>Organigrama</h2>
<!-- <mat-dialog-content> -->
    <div class="flex justify-between items-center mb-4">
        <app-btn-back (emitter)="close()" [top]="30" [right]="30"></app-btn-back>
    </div>
    <div class="chart-container">
        <ngx-org-chart [nodes]="nodes" direction="vertical"></ngx-org-chart>
    </div>
<!-- </mat-dialog-content> -->
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>