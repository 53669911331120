import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { dict_message } from '../../util/messages';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  show(title: string, id_message: string, icon: Icon, showCancelButton: boolean = false): Promise<SweetAlertResult> {
    const text = dict_message[id_message];
    return Swal.fire({ title, text: (text ? text : id_message), icon, confirmButtonText: "Aceptar", cancelButtonText: "Cancelar", confirmButtonColor: "#FDBB13", showCancelButton });
  }

  showSuccess(id_message: string): Promise<SweetAlertResult> {
    return this.show("Éxito", id_message, "success");
  }

  showError(id_message: string): Promise<SweetAlertResult> {
    return this.show("Error", id_message, "error");
  }

  showWarning(id_message: string, showCancel: boolean = true): Promise<SweetAlertResult> {
    return this.show("Advertencia", id_message, "warning", showCancel);
  }

  showInfo(id_message: string): Promise<SweetAlertResult> {
    return this.show("Aviso", id_message, "info");
  }

}

export type Icon = "info" | "warning" | "error" | "success";