import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { User } from '../../interfaces/user.interface';
import { UserService } from '../../services/user/user.service';
import { findMenuItemByRoute, getRoot } from '../../util/helpers';

@Component({
  selector: 'app-form-btns',
  templateUrl: './form-btns.component.html',
  styleUrls: ['./form-btns.component.scss']
})
export class FormBtnsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() form: FormGroup;
  @Input() editFlag: boolean = false;
  @Input() alwaysDisabled: string[] = [];
  @Input() onlyNationalAccess: boolean = false;
  @Output() submit = new EventEmitter();
  @Output() clickEvent = new EventEmitter();
  isEditing: boolean = false;


  user: User = null;
  enabled: boolean = false;
  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnDestroy(): void {
    this.subs.map(s => {
      s.unsubscribe();
      s.remove(s);
    })
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.form?.currentValue) {
      this.isEditing = changes.form.currentValue.enabled;
      this.form = changes.form.currentValue;
    }
  }

  ngOnInit(): void { 
    this.setPermissions();
  }

  enableForm(): void {
    this.form.enable();
    this.isEditing = true;
    this.alwaysDisabled.map(item => { this.form.get(item).disable(); })
    this.clickEvent.emit(true);
  }

  disableForm(): void {
    this.form.disable();
    this.isEditing = false;
    this.alwaysDisabled.map(item => { this.form.get(item).disable(); })
    this.clickEvent.emit(false);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    this.submit.emit();
    this.disableForm();
  }
  private setPermissions(): void {
    const sub = this.userService.user$.subscribe((user: User) => {
      if (!user) return;
      this.user = user;
      
      const item = findMenuItemByRoute(this.user.menu, getRoot(this.router.url))
      if (!item) return;

      this.enabled = this.onlyNationalAccess ? item.enabled && this.user.office.id === 1 : item.enabled;

      const sub = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        const item = findMenuItemByRoute(this.user.menu, getRoot(this.router.url))
        if (!item) return;
        this.enabled = this.onlyNationalAccess ? item.enabled && this.user.office.id === 1 : item.enabled;
      });
      this.subs.push(sub);
    })

    this.subs.push(sub);
  }

}
