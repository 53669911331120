import { Catalog } from "./catalogs.interface";

export interface FileItem {
    id?: number;
    driveId: string;
    name: string;
    typeId: number;
    fileType: string;
    categoryId: number;
    category: string;
    personalId: number;
    uploadFile?: File
    notUploaded?: boolean;
    extern?: boolean;
}

export const initFile: FileItem = {
    id: 0,
    driveId: "",
    name: "",
    typeId: 1,
    fileType: "",
    categoryId: 1,
    category: "",
    personalId: 0
    
}

export interface EventFile {
    item: FileItem
}