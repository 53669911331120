import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AddIncidenceDialog } from '../../interfaces/dialog-data.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { IncidenceForm, initIncidenceForm } from '../../interfaces/form.interface';
import { buildCatalog, buildItem, formatDate } from 'src/app/shared/util/helpers';
import { TimeTrackingService } from '../../services/time-tracking/time-tracking.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { ActivityItem, CalendarItem, Incidence, IncidenceItem, ListCatalogs, initCatalogs } from '../../interfaces/time-tracking.interface';
import { DatePipe } from '@angular/common';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { ValidationService } from 'src/app/shared/services/validation/validation.service';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';

@Component({
  selector: 'vex-add-incidence',
  templateUrl: './add-incidence.component.html',
  styleUrls: ['./add-incidence.component.scss'],
  animations: [fadeInRight400ms]
})
export class AddIncidenceComponent implements OnInit {

  public form: FormGroup<IncidenceForm>;
  public loading: boolean = false;
  public today: string = '';
  public user: User;
  public catalogs: ListCatalogs = initCatalogs
  public isEditing: boolean = false;
  days: CalendarItem[] = [];
  initDate: Date = null;
  endDate: Date = null;

  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  constructor(
    public dialogRef: MatDialogRef<AddIncidenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddIncidenceDialog,
    private fb: FormBuilder,
    private alert: AlertService,
    private socket: RxStompService,
    private service: TimeTrackingService,
    private userService: UserService,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private validator: ValidationService
  ) { }

  ngOnInit(): void {
    this.userService.user$.subscribe(user => {
      if (!user) return;
      
      this.user = user;
      this.loadForm();
      this.loadCatalogs();
      this.loadDate();      

      if (this.data?.id) {
        this.isEditing = true;
        this.loading = true;
        this.service.findIncidence(this.data.id).subscribe({
          next: response => {
            this.initDate = new Date(response.data.initDate);
            this.endDate = new Date(response.data.endDate);

            this.loadForm(response.data);
            this.today = response.data.initDate;
            
            this.buildCalendar();
            this.loading = false;

            this.form.disable();
          },
          error: response => {
            
            this.loading = false;
          }
        })
      }
      

    })
  }

  ngAfterViewInit() {
    const monthPrevBtn = document.querySelectorAll(
      '.mat-calendar-previous-button'
    );
    const monthNextBtn = document.querySelectorAll('.mat-calendar-next-button');

    if (monthPrevBtn) {
      Array.from(monthPrevBtn).forEach((button) => {
        this.renderer.listen(button, 'click', (event) => {
          this.buildCalendar(this.calendar.activeDate);
        });
      });
    }

    if (monthNextBtn) {
      Array.from(monthNextBtn).forEach((button) => {
        this.renderer.listen(button, 'click', (event) => {
          this.buildCalendar(this.calendar.activeDate);
        });
      });
    }

    if (this.today) this.buildCalendar();
  }

  private loadForm(data = initIncidenceForm): void {
    this.today = this.data.date;
    this.form = this.fb.group({
      id: [data.id],
      type: [buildCatalog(data.typeId), [Validators.required]],
      initDate: [data.initDate ? new Date(data.initDate) : null, [Validators.required]],
      endDate: [data.endDate ? new Date(data.endDate) : null, [Validators.required]],
      notes: [data.notes, [Validators.required]],
    });

    // this.form.controls.initDate.setValidators([Validators.required, , ValidationService.dateOverlap(this.form, this.days, [this.initDate, this.endDate])]);
    // this.form.controls.endDate.setValidators([Validators.required, , ValidationService.dateOverlap(this.form, this.days, [this.initDate, this.endDate])]);

    // this.form.controls.initDate.updateValueAndValidity();
    // this.form.controls.endDate.updateValueAndValidity();

    this.form.controls.type.valueChanges.subscribe(
      data => {
        if (data.value === "Vacaciones" || data.value === "Incapacidad") {
          // this.form.controls.initDate.setValue(null);
          // this.form.controls.endDate.setValue(null);
          this.form.controls.endDate.setValidators([Validators.required, ValidationService.dateOverlap(this.form, this.days, [this.initDate, this.endDate])]);
          this.form.controls.initDate.setValidators([Validators.required, ValidationService.dateOverlap(this.form, this.days, [this.initDate, this.endDate])]);
        } else {
          this.form.controls.initDate.setValue(new Date(this.today));
          this.form.controls.endDate.setValue(new Date(this.today));

          this.form.controls.endDate.setValidators([Validators.required]);
          this.form.controls.initDate.setValidators([Validators.required]);
        }

        this.form.controls.endDate.updateValueAndValidity();
        this.form.controls.initDate.updateValueAndValidity();
      }
    )
  }

  private loadCatalogs(): void {
    this.service.findCatalogs(this.user.id).subscribe(response => { this.catalogs = response.data; })
  }

  onSubmit(): void {

    if (this.form.invalid) return;
    
    const data: Incidence = {
      id: this.form.value.id,
      personalId: this.user.id,
      typeId: this.form.value.type.id,
      notes: this.form.value.notes,
      initDate: this.datePipe.transform(this.form.value.initDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ'),
      endDate: this.datePipe.transform(this.form.value.endDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ'),
    }

    const item: IncidenceItem = {
      id: this.form.value.id,
      company: "Gossler S.C.",
      project: "Proyectos Internos",
      activity: this.form.value.type.value,
      time: 0,
      notes: this.form.value.notes,
      date: this.form.value.initDate.toISOString()
    }

    this.service.saveIncidence(data).subscribe(response => {
      this.alert.showSuccess(response.message);
        item.id = response.data.id;
        this.dialogRef.close(this.data);

        const date = new Date(this.today);
        if (date >= new Date(data.initDate) && date <= new Date(data.endDate)) {
          this.service.setIncidence(item);
          // this.service.showTable(1);
        }

        this.socket.send(`/send/time-tracking/${this.user.id}/1/1`, item);
        
        this.loading = false;
    })
  }

  close(): void {
    this.dialogRef.close();
  }

  buildCalendar(event: Date = null): void {
    const selected = event ? event : new Date(this.today);
    this.days = [];
    this.calendar?.updateTodaysDate();
    

    this.service.buildCalendar(this.user.id, selected.toISOString()).subscribe(response => {
      this.days = response.data;
      this.calendar?.updateTodaysDate();
      
    })
  }

  dateClass() {
    return (cellDate: Date, view): MatCalendarCellCssClasses => {
      if (view === 'month') {
        
        if (cellDate.getDay() === 0 || cellDate.getDay() === 6) return "disabled date-disabled";

        const date = this.days.find(d => cellDate >= new Date(d.initDate) && cellDate <= new Date(d.endDate));
        
        return date ? this.disableDates(cellDate, date.status) : ""
      }
  
      return '';
    };
  }

  change(date: any): void {
    this.buildCalendar(date);
  }

  getFieldError(): string | null {

    const initError = this.validator?.getFieldError(this.form.controls.initDate, "initDate");
    const endError = this.validator.getFieldError(this.form.controls.endDate, "endDate");
    return initError ? initError : "" + "  " + endError ? endError : "";
  }

  disableDates(day: Date, status: string): string {

    if (!this.initDate || !this.endDate) return status + " date-disabled large";

    if (day >= this.initDate && day <= this.endDate) return "";

    return status + " date-disabled large";
  }

  loadDate(): void {
    this.service.date$.subscribe(date => {
      this.today = date; 
    })
  }

  get selected(): Date {
    return new Date(this.today);
  }

}
