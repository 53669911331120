import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { LayoutComponent } from '../layout/layout.component';
import { PersonalModule } from './personal/personal.module';
import { SharedModule } from '../shared/shared.module';
import { VexModule } from '../shared/vex.module';
import { MaterialModule } from '../shared/material.module';
import { FileComponent } from './components/file/file.component';
import { NotFoundComponent } from './components/not-found/not-found.component';


@NgModule({
  declarations: [
    LayoutComponent,
    FileComponent,
    NotFoundComponent,
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    
    VexModule,
    MaterialModule, 

    PersonalModule,
    SharedModule

  ]
})
export class MainModule { }
