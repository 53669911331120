<div class="page">
    <mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
        <ng-template let-personal="item" matMenuContent>
            <button mat-menu-item (click)="edit(personal)">
                <mat-icon svgIcon="mat:edit"></mat-icon>
                <span>Editar</span>
            </button>
            <button mat-menu-item (click)="disable(personal)">
                <mat-icon svgIcon="mat:remove_circle_outline"></mat-icon>
                <span>Dar de baja</span>
            </button>
        </ng-template>
    </mat-menu>
    
    <mat-menu #actionsMenuInactive="matMenu" xPosition="before" yPosition="below">
        <ng-template let-personal="item" matMenuContent>
            <button mat-menu-item (click)="edit(personal)">
                <mat-icon svgIcon="mat:edit"></mat-icon>
                <span>Editar</span>
            </button>
            <button mat-menu-item (click)="enable(personal)">
                <mat-icon svgIcon="mat:trending_up"></mat-icon>
                <span>Reactivar</span>
            </button>
        </ng-template>
    </mat-menu>
    
    
    <div class="trans-ease-out" @fadeInUp>
        <mat-tab-group (selectedTabChange)="tabChanged($event)" [selectedIndex]="tab">
            <mat-tab label="Personal activo">
                <div class="p-6">
                    <app-table title="Personal activo" [columns]="columns" [data]="personalActive" [actionsMenu]="actionsMenu"
                        (clickEvent)="edit($event)" (addEvent)="add()" key="PERSONAL_ACTIVE">
                        
                        <button class="ml-4 flex-none text-contrast-white bg-blue-400 btn-sm" matTooltip="Organigrama" type="button" (click)="showDialog()">
                            <mat-icon svgIcon="mat:account_tree" [style.width.px]="18"></mat-icon>
                        </button>

                    </app-table>
                </div>
            </mat-tab>
            <mat-tab label="Personal inactivo">
                <div class="p-6">
                    <app-table title="Personal inactivo" [columns]="columnsInactive" [data]="personalInactive"
                        [actionsMenu]="actionsMenuInactive" (clickEvent)="edit($event)" (addEvent)="add()"
                        key="PERSONAL_INACTIVE"></app-table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<!-- <iframe 
    width="100%" 
    height="1000" 
    src="https://lookerstudio.google.com/embed/reporting/c1c8f5a3-5c22-4a27-8012-077d027fffb9/page/NmZqD" 
    frameborder="0" style="border:0" 
    allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
</iframe> -->

<!-- <input type="file" [(ngModel)]="input" (change)="cambio($event)" class="mr-3"> <button (click)="subir()" mat-raised-button color="primary"> Subir archivo </button> -->