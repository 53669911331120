<div class="footer flex">
  <div class="flex-auto flex items-center justify-center ">
    <div  class="flex-none">
      <img src="assets/img/common/crowe-logo.png" height="24px" width="24px" alt="SGI Crowe" />
    </div>
 
    <div class="font-small ltr:ml-4 rtl:mr-4 hidden sm:block">
      Copyright © {{ date }}
      <a href="https://www.crowe.com/mx" target="_blank" rel="noopener noreferrer">
        Gossler S.C. miembro de Crowe Global
      </a>
      |
      <a href="https://www.crowe.com/mx/privacy-policy/" target="_blank" rel="noopener noreferrer">
        Aviso de Privacidad
      </a>
    </div>
  </div>
</div>

