export enum TypeAssitance {
    VIRTUAL = 1,
    IN_PERSON = 2,
    EXPONENT = 3,
    SPECIAL = 4,
    REGISTERED = 5
}

export enum Modality {
    VIRTUAL = 1,
    IN_PERSON = 2,
    HYBRID = 3
}

export enum IconAssistance {
    VIRTUAL = 'laptop_windows',
    IN_PERSON = 'groups',
    EXPONENT = 'person',
    SPECIAL = 'star',
    REGISTERED = 'devices',
    NOT_DPC = 'school'
}

export const DIR_TEXT = {
    1: "Virtual",
    2: "Presencial",
    3: "Expositor",
    4: "Especial",
    5: "Registrado"
}