import { Component, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { delayWhen, interval, Observable, of } from 'rxjs';
import { ProgressService } from 'src/app/shared/services/progress/progress.service';

@Component({
  selector: 'vex-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  progressValue = this.progress.progressValue$;


  constructor(public loader: LoadingBarService, 
    private progress: ProgressService
  ) { }

  ngOnInit() {
    
  }

}
