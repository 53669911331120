import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonalService } from '../../services/personal/personal.service';
import { FormGroup } from '@angular/forms';
import { PersonalHistory, PersonalHistoryDialog, PersonalHistoryForm } from '../../interfaces/form.interface';
import { FormService } from '../../services/personal/form/form.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';
import { Catalog } from 'src/app/shared/interfaces/catalogs.interface';
import { CatalogsService } from '../../services/catalogs/catalogs.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { Catalogs } from '../../constants/catalogs';

@Component({
  selector: 'vex-inactive-form',
  templateUrl: './inactive-form.component.html',
  styleUrls: ['./inactive-form.component.scss']
})
export class InactiveFormComponent implements OnInit {

  public form: FormGroup<PersonalHistoryForm> = null;
  public loading: boolean = false;
  public items: Catalog[] = [];

  constructor(
    private service: PersonalService,
    private catService: CatalogsService,
    private formService: FormService,
    public dialogRef: MatDialogRef<InactiveFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PersonalHistoryDialog,
    private alert: AlertService,
    private socket: RxStompService,
    private user: UserService
  ) { }

  ngOnInit(): void {
    this.form = this.formService.buildPersonalHistoryForm(this.data.personalId, this.data.email, 2, null, null);
    
    this.user.user$.subscribe(user => {
      if (!user) return;

      this.catService.getCatalog(Catalogs.REASON, user.officesRelated).subscribe(response => {
        this.items = response.data.filter(item => item.id !== 1);
      })

    })

  }

  onSubmit(): void {
    if (this.form.invalid) return;

    const data: PersonalHistory = {
      personalId: this.form.value.personalId,
      email: this.form.value.email,
      notes: this.form.value.notes,
      typeId: this.form.value.typeId,
      reasonId: this.form.value.reasonId.id,
      rehire: this.form.value.rehire,
      changeDate: this.form.value.changeDate,
    }

    this.loading = true;
    this.service.setStatus(data).subscribe({
      next: response => {
        this.loading = false;
        this.close();
        this.alert.showSuccess(response.message);
        this.socket.send(`/send/personal/changes/${this.data.personalId}`, response.data);
      },
      error: response => {
        this.loading = false;
        this.alert.showError(response.error.message);
      }
    })
    
  }

  close(): void {
    this.dialogRef.close();
  }

}
