<div class="p-2 px-3 relative">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="flex justify-between items-center mb-4">
            <app-btn-back (emitter)="close()"></app-btn-back>
        </div>
        <div mat-dialog-content>
            <div class="form-container">
                <div class="mt-3 mb-7">
                    <h1 class="text-lg font-semibold">Baja Personal: <span class="text-primary">{{ data.personal }}</span> </h1>
                    <p class="font-light text-gray-600">Por favor complete los siguientes campos para continuar.</p>
                </div>
                
                <app-input-date [form]="form" field="changeDate" label="Fecha de Baja"></app-input-date>
                <app-input-select [form]="form" field="reasonId" label="Motivo" [items]="items"></app-input-select>
                <app-input-boolean [form]="form" field="rehire" label="¿Es recontratable?"></app-input-boolean>
                <app-input-text-area [form]="form" field="notes" label="Notas"></app-input-text-area>
                <!-- <app-input-text-area [form]="form" field="reasonNotes" label="Motivo"></app-input-text-area> -->

            </div>
        </div>
        <div mat-dialog-actions align="end" class="px-3">
            <button mat-raised-button color="primary">Aceptar</button>
        </div>
    </form>
    <div [ngClass]="{'loading-float': loading}">
        <app-loading [loading]="loading"></app-loading>
    </div>
</div>