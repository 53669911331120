<div class="body-2 p-6 text-primary-contrast" style="width: 360px;">
  <p> Hoy </p>
  <div class="display-1">{{ dayName }}</div>
  <div class="display-1">{{ date }}</div>
</div>

<mat-divider></mat-divider>

<mat-nav-list>
  <h3 matSubheader>Eventos del mes</h3>

  <ng-container *ngFor="let event of currentEvents">

    <a href="{{event.conferenceLink}}" target="_blank" mat-list-item *ngIf="event.isConference">
      <div class="icon-container bg-primary"><mat-icon class="icon-sm">videocam</mat-icon></div>
      <h4 class="text-title" matLine> {{ event.title }} </h4>
      <p class="text-date" matLine> <mat-icon class="icon-xs">schedule</mat-icon> <span class="mx-1">{{ event.start | dateFormat }}</span> </p>
    </a>

    <a mat-list-item *ngIf="!event.isConference">
      <h4 class="text-title" matLine> {{ event.title }} </h4>
      <p class="text-date" matLine> <mat-icon class="icon-xs">schedule</mat-icon> <span class="mx-1">{{ event.start | dateFormat }}</span> </p>
    </a>
    
  </ng-container>
  
  <mat-divider></mat-divider>

  <!-- <h3 matSubheader>Próximos eventos</h3> -->

  <button class="show-events" (click)="toggleEvents(itemsContainer, icon)"> <span class="mr-1">Mostrar próximos eventos</span> <mat-icon class="event-icon icon-rotate" #icon>expand_more</mat-icon> </button>

  <div class="events-container events-hidden" #itemsContainer>

    <ng-container *ngFor="let event of events">

      <a href="{{event.conferenceLink}}" target="_blank" mat-list-item *ngIf="event.isConference">
        <div class="icon-container bg-primary"><mat-icon class="icon-sm">videocam</mat-icon></div>
        <h4 class="text-title" matLine> {{ event.title }} </h4>
        <p class="text-date" matLine> <mat-icon class="icon-xs">schedule</mat-icon> <span class="mx-1">{{ event.start | dateFormat }}</span> </p>
      </a>
  
      <a mat-list-item *ngIf="!event.isConference">
        <h4 class="text-title" matLine> {{ event.title }} </h4>
        <p class="text-date" matLine> <mat-icon class="icon-xs">schedule</mat-icon> <span class="mx-1">{{ event.start | dateFormat }}</span> </p>
      </a>
      
    </ng-container>

  </div>
  
  <!-- <mat-divider></mat-divider> -->
</mat-nav-list>
