import { Directive, HostListener } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[disableAutoSelect]'
})
export class DisableAutoSelectDirective {

  constructor(private matSelect: MatSelect) { }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    
    if (this.matSelect.panelOpen) {
      event.preventDefault();
    }
  }
}
