import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getRoot } from '../../util/helpers';

@Injectable({
  providedIn: 'root'
})
export class ModuleStateService {

  constructor(
    private router: Router
  ) { }
  
  private lastRoute: string = "";
  private currentRoute: string = "";

  public onRoutechange() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: any) => {
        this.handleRouteChange(event.url);
        this.handleTabChange(event.url);
        this.lastRoute = event.url;
      }
    )
  }

  private handleTabChange(url: string): void {
    const arr = url.split("/");
    this.currentRoute = arr.includes("edit") ? arr.splice(0, arr.length - 1).join("/") : arr.join("/");

    this.currentRoute = this.currentRoute.replace(/\/\d+$/, "");
    this.lastRoute = this.lastRoute.replace(/\/\d+$/, "");
    
    if (this.lastRoute.startsWith(this.currentRoute) && this.lastRoute !== this.currentRoute && this.currentRoute.length > 2 && this.lastRoute.length > 2) {
      this.saveTab(1, this.lastRoute);
    }
    
  }

  private handleRouteChange(url: string): void {
    const segments = url.split("/");
    const parentRoute = segments.slice(0, 3).join('/');
    const childRoute = "/" + segments.slice(3).join('/');

    this.currentRoute = parentRoute;
    
    if (childRoute && childRoute.length > 1) {
      this.saveRouteActive(childRoute);
    } else {

      

      if (this.lastRoute.startsWith(url)) {

        const arr = url.split("/");
        this.lastRoute = arr.includes("edit") ? arr.splice(0, arr.length - 1).join("/") : arr.join("/");

        this.saveRouteActive("");
      } else {
        this.redirect();
      }
    }
    
  }

  public getTab(): number {
    const item = this.buildItem();
    if (!item) return 0;
    return item.tab;
  }

  private saveRouteActive(routeActive: string, key: string = this.currentRoute) {
    const item = this.buildItem()
    if (item) {
      item.routeActive = routeActive;
      environment.storage.setItem(key, JSON.stringify( item )); 
    } else {
      environment.storage.setItem(key, JSON.stringify( { routeActive, tab: 0 } )); 
    }
  }

  public saveTab(tab: number, key: string = this.currentRoute) {
    const item = this.buildItem()
    if (item) {
      item.tab = tab;
      environment.storage.setItem(key, JSON.stringify( item )); 
    } else {
      environment.storage.setItem(key, JSON.stringify( { routeActive: "", tab: 0 } )); 
    }
  } 

  private redirect(key: string = this.currentRoute) {
    const item = this.buildItem()
    if (!item || !item?.routeActive || item.routeActive === "") {
      return;
    }

    this.router.navigate([key + item.routeActive]);
  }


  private buildItem(key: string = this.currentRoute) {
    const itemStorage = environment.storage.getItem(key);

    if (!itemStorage) { 
      return null;
    }

    return JSON.parse(itemStorage);
  }


}
