<div class="p-2 px-3 relative">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="flex justify-between items-center mb-4">
            <app-btn-back (emitter)="close()"></app-btn-back>
        </div>
        <div mat-dialog-content>
            <div class="form-container">
                <div class="mt-3 mb-7">
                    <h1 class="text-lg font-semibold">Ingrese los datos para subir el documento</h1>
                    <p class="font-light text-gray-600">Por favor complete los siguientes campos para continuar con el proceso.</p>
                </div>
                <app-input-select [form]="form" field="category" label="Categoría" [items]="categories"></app-input-select>
                <app-input-file [form]="form" field="file" type="pdf" (file)="setFile($event)"></app-input-file>
            </div>
        </div>
        <div mat-dialog-actions align="end" class="px-3">
            <button mat-raised-button color="primary">Aceptar</button>
        </div>
    </form>
    <div [ngClass]="{'loading-float': loading}">
        <app-loading [loading]="loading"></app-loading>
    </div>
</div>