import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Catalog } from 'src/app/shared/interfaces/catalogs.interface';
import { FileItem, initFile } from 'src/app/shared/interfaces/file.interface';
import { DialogInput, FileForm } from '../../interfaces/document-dialog.interface';
import { DriveService } from 'src/app/shared/services/gapis/drive/drive.service';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { PersonalService } from '../../services/personal/personal.service';
import { RxStompService } from 'src/app/shared/services/rx-stomp/rx-stomp.service';

@Component({
  selector: 'app-form-document',
  templateUrl: './form-document.component.html',
  styleUrls: ['./form-document.component.scss']
})
export class FormDocumentComponent implements OnInit, OnDestroy {

  form: FormGroup;
  categories: Catalog[] = [];
  types: Catalog[] = []; 
  isEditing: boolean = false;
  isNew = false;
  subscriptions$: Subscription[] = [];
  
  loading: boolean = false;
  isUploaded: boolean = false;
  currentDriveId = "";

  constructor(
    public dialogRef: MatDialogRef<FormDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInput,
    private fb: FormBuilder,
    private drive: DriveService,
    private alert: AlertService,
    private service: PersonalService,
    private socket: RxStompService
  ) { }
  
  ngOnDestroy(): void {
    this.subscriptions$.map(s => {
      s.unsubscribe();
      s.remove(s);
    })
  }

  ngOnInit(): void {
    this.categories = this.data.categories;
    this.types = this.data.types;
    this.isEditing = this.data.isEditing;
    this.isNew = !this.data.file;

    

    this.loadForm(this.isNew ? initFile : this.data.file);
    this.driveIsLoading();
  }

  private loadForm(file: FileItem) {
    this.form = this.fb.group({
      id: [file.id],
      type: [file.typeId, Validators.required],
      category: [file.categoryId, Validators.required],
      file: [null, Validators.required]
    });

    this.form.get("category").setValue(this.categories.find(c => c.id === file.categoryId));
    this.form.markAsUntouched();
  }

  close() {
    if (this.loading) return;

    if (this.isUploaded) {
      this.deleteUploadedFile(this.data.file.driveId, true);
    } else {
      this.data = null;
      this.dialogRef.close();
    }

    
  }

  setFile(uploadFile: File): void {

    const value: FileForm = this.form.value;

    const file: FileItem = {
      driveId: "",
      name: uploadFile.name,
      typeId: value.type,
      fileType: this.types.find(t => t.id === value.type)?.value,
      categoryId: value.category.id,
      category: value.category.value,
      personalId: this.isEditing ? this.data.personalId : 0,
    }

    if (!this.isNew) {
      this.currentDriveId = this.data.file.driveId;
      file.id = this.data.file.id;
    }

    if (this.isEditing) {
      this.data.file = file;
      
      this.uploadFile(uploadFile)
    } else {
      file.uploadFile = uploadFile;
      file.notUploaded = true;
      this.data.file = file;
    }
  }

  uploadFile(uploadFile: File): void {
    this.loading = true;
    const upload$ = this.drive.uploadFile(uploadFile).subscribe({
      next: data => {
        this.loading = false;
        this.isUploaded = true;
        this.data.file.driveId = data.id;
        
      },
      error: error => {
        this.loading = false;
        this.alert.showError("Error al subir la imágen");
      }
    })

    this.subscriptions$.push(upload$);
  }

  onSubmit(): void {
    if (this.loading) return;

    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    
    this.data.file = {
      ...this.data.file,
      category: this.form.value.category.value,
      categoryId: this.form.value.category.id,
    }

    if (this.isUploaded) {
      this.saveFile();
    } else {
      this.dialogRef.close(this.data);
    }

  }

  saveFile() {

    if (this.currentDriveId !== "") {
      this.deleteUploadedFile(this.currentDriveId);
    }

    const save$ = this.service.saveFile(this.data.file).subscribe({
      next: resp => {
        this.data.file = resp.data
        this.dialogRef.close(this.data);
        this.socket.send(`/send/save_documents/${this.data.personalId}`, {});
        this.alert.showSuccess(resp.message);
      },
      error: resp => {
        
        const message = resp.error.message;
        this.alert.showError(message ? message : "Error al guardar el archivo");
        this.deleteUploadedFile(this.data.file.driveId);

      }
    })

    this.subscriptions$.push(save$);
  }

  deleteUploadedFile(driveId: string, close: boolean = false) {
    this.loading = true;
    const delete$ = this.drive.deleteFile(driveId).subscribe({
      next: resp => {
        this.loading = false;
        if (close) {
          this.dialogRef.close();
        }
      },
      error: err => {
        this.loading = false;
      }
    })

    this.subscriptions$.push(delete$);
  }



  public driveIsLoading() {
    const loading$ = this.drive.isLoading.subscribe(loading => {
      this.loading = loading;
    });

    this.subscriptions$.push(loading$);
  }

}
