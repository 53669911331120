import { Component, Input, OnInit } from '@angular/core';
import { DIR_TEXT, IconAssistance, TypeAssitance } from 'src/app/main/training/interfaces/constants';

@Component({
  selector: 'icon-assistance',
  templateUrl: './icon-assistance.component.html',
  styleUrls: ['./icon-assistance.component.scss']
})
export class IconAssistanceComponent implements OnInit {

  @Input() value: number;
  @Input() dpc: boolean = true;
  _assistanceIcon = IconAssistance;
  _typeAssistance = TypeAssitance;

  constructor() { }

  ngOnInit(): void {
  }

  get dir(): string {
    return DIR_TEXT[this.value];
  }

}
