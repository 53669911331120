<div class="md:p-2 md:px-3 relative">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="flex justify-between items-center mb-4">
            <app-btn-back (emitter)="close()"></app-btn-back>
        </div>

        <div class="mt-3 mb-7" mat-dialog-title>
            <div class="flex items-center gap-2">
                <div><h1 class="text-lg font-semibold">Registro de actividad - <span class="text-primary"> {{ today | dateFormat : "d 'de' MMMM 'de' yyyy" : false}} </span> </h1></div>
                <div *ngIf="form?.enabled" @fadeInRight>
                    <app-calendar [selected]="selected" [toggled]="true"></app-calendar>
                </div>
            </div>
            <p class="font-light text-gray-600">Por favor complete los siguientes campos para continuar con el proceso.</p>
        </div>

        <div mat-dialog-content>
            <div class="form-container">
                
                <app-input-select [form]="form" field="type" label="Incidencia" [items]="catalogs.incidence"></app-input-select>
                <!-- <div class="flex gap-3" >
                    <div class="col w-1/2"> <app-input-date [form]="form" field="initDate" label="Fecha inicial"></app-input-date> </div>
                    <div class="col w-1/2"> <app-input-date [form]="form" field="endDate" label="Fecha final"></app-input-date> </div>
                </div> -->
                <!-- <app-input-date-range [form]="form" fieldStart="initDate" fieldEnd="endDate" label="Fecha" [dateClass]="dateClass()"
                    *ngIf="form.value?.type?.value === 'Vacaciones' || form.value?.type?.value === 'Incapacidad'">
                </app-input-date-range> -->

                <mat-form-field class="mb-3" *ngIf="form.value?.type?.value === 'Vacaciones' || form.value?.type?.value === 'Incapacidad'">
                    <mat-label> Fecha </mat-label>
                    <mat-date-range-input [formGroup]="form" [rangePicker]="dateRangePicker">
                        <input matStartDate placeholder="MM/DD/YYYY" formControlName="initDate">
                        <input matEndDate placeholder="MM/DD/YYYY" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #dateRangePicker [dateClass]="dateClass()"
                    (monthSelected)="change($event)" 
                    (selectedChange)="change($event)" >
                        <mat-date-range-picker-actions>
                          <button mat-button matDateRangePickerCancel>Cancelar</button>
                          <button mat-raised-button color="primary" matDateRangePickerApply>Aplicar</button>
                        </mat-date-range-picker-actions>
                      </mat-date-range-picker>
                    <mat-error> {{ getFieldError() }} </mat-error>
                </mat-form-field>



                <app-input-text-area [form]="form" field="notes" label="Notas" placeholder="Ingrese las notas" [rows]="4"></app-input-text-area>
                
            </div>
        </div>
        <div mat-dialog-actions align="end" class="px-3 mt-3">
            <app-form-btns [form]="form" [editFlag]="isEditing" (submit)="onSubmit()">
                <button mat-raised-button color="primary">Aceptar</button>
            </app-form-btns>
        </div>
    </form>
    <div [ngClass]="{'loading-float': loading}">
        <app-loading [loading]="loading"></app-loading>
    </div>
</div>