<mat-card *ngIf="!toggled" class="calendar-container">
    <button type="button" class="btn-info" [matMenuTriggerFor]="infoMenu"> <mat-icon>help</mat-icon> </button>
    <mat-calendar [dateClass]="dateClass()" (monthSelected)="buildCalendar($event)" [(selected)]="selected" (selectedChange)="change($event)"></mat-calendar>
</mat-card>

<div class="popup-container" *ngIf="toggled" id="calendar">
    <mat-card class="calendar-container">
        <button class="btn-info" [matMenuTriggerFor]="infoMenu"> <mat-icon>help</mat-icon> </button>
        <mat-calendar [dateClass]="dateClass()" (monthSelected)="buildCalendar($event)" [(selected)]="selected" (selectedChange)="change($event)"></mat-calendar>
    </mat-card>
    <button mat-raised-button class="btn-close-calendar" color="primary" type="button" (click)="toggle()">Cerrar</button>
</div>

<mat-icon *ngIf="toggled" class="btn-calendar" (click)="toggle()" color="primary">calendar_today</mat-icon>

<!-- <mat-menu #calendar="matMenu" xPosition="before" yPosition="below">
    <ng-template matMenuContent>
        
    </ng-template>
</mat-menu> -->
 
<!-- [matMenuTriggerFor]="calendar"  -->


<!-- <input matInput [matDatepicker]="picker" class="h-0 overflow-hidden" (dateChange)="dateChange($event)">
<mat-datepicker [dateClass]="dateClass()" (monthSelected)="change($event)" (viewChanged)="test($event)" #picker>
    <mat-datepicker-actions>
        <button mat-raised-button color="primary" matDatepickerApply>Aplicar</button>
        </mat-datepicker-actions>
</mat-datepicker>
<button *ngIf="toggled" mat-raised-button (click)="picker.open()">Open</button> -->


<mat-menu #infoMenu="matMenu" xPosition="before" yPosition="below" class="w-full">
    <ng-template matMenuContent>
        <mat-list class="list">
            <h3 mat-subheader>Descripción</h3>
            <mat-divider></mat-divider>
            <mat-list-item class="my-2">
                <div class="color color-valid" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Registro válido</span>
                        <span class="text-xs text-gray-400 text-wrap">Horas registradas completas</span>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="mb-2">
                <div class="color color-invalid" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Registro inválido</span>
                        <span class="text-xs text-gray-400 text-wrap">Horas registradas incompletas</span>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="mb-2">
                <div class="color color-overload" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Sobrecarga</span>
                        <span class="text-xs text-gray-400 text-wrap">Horas registradas mayores a las requeridas</span>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="mb-2">
                <div class="color color-incidence" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Incidencia</span>
                        <span class="text-xs text-gray-400 text-wrap">Incidencia registrada</span>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="mb-2">
                <div class="color color-incidence-valid" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Registros con incidencia</span>
                        <span class="text-xs text-gray-400 text-wrap">Horas registradas en un día con incidencia</span>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="mb-2">
                <div class="color color-incidence-overload" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Sobrecarga con incidencia</span>
                        <span class="text-xs text-gray-400 text-wrap">Horas registradas excedentes en un día con
                            incidencia</span>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="mb-2">
                <div class="color color-spent" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Gasto</span>
                        <span class="text-xs text-gray-400 text-wrap">Gasto registrado</span>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="mb-2">
                <div class="color color-reservation" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Reservación</span>
                        <span class="text-xs text-gray-400 text-wrap">Reservación registrada</span>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item class="mb-2">
                <div class="color color-selected" mat-list-avatar></div>
                <div matLine>
                    <div class="text-container">
                        <span class="text-sm font-semibold">Selección</span>
                        <span class="text-xs text-gray-400 text-wrap">Día seleccionado</span>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </ng-template>
</mat-menu>