import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { OauthToken } from '../../interfaces/google.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '../../interfaces/http-response.interface';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  storage = localStorage;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { 
    this.storage = this.storageService.storage;
  }

  public clear(): void {
    this.storage.clear();
  }

  public getIdToken(): string {
    return this.storage.getItem(environment.credential.idToken);
  }

  public getAccessToken(): string {
    return this.storage.getItem(environment.credential.accessToken);
  }

  public getDriveToken(): Observable<string> {
    const strToken = this.storage.getItem(environment.credential.oauthToken);

    if (environment.offline) return of ("");

    if (strToken) {
      const token: OauthToken = JSON.parse(strToken);
      if (this.isTokenValid(token)) {
        return of(token.tokenValue);
      }
    }

    return this.requestNewToken().pipe(
      map(response => {
        this.storage.setItem(environment.credential.oauthToken, JSON.stringify(response.data));
        return response.data.tokenValue;
      }),
      catchError(error => {
        console.error('Failed to obtain token:', error);
        return of(null);
      })
    );
  }


  private isTokenValid(token: OauthToken): boolean {
    const expirationTime = new Date(token.expirationTime).getTime();
    const currentTime = new Date().getTime();
    return expirationTime > currentTime;
  }

  private requestNewToken(): Observable<HttpResponse<OauthToken>> {
    return this.http.get<HttpResponse<OauthToken>>(`${environment.base_url}/auth/oauth/token`);
  }
}
